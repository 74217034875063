<template>
  <div class="reportWrapper">
    <van-tabs
      type="line"
      @click="onClick"
    >
      <van-tab title="居家维修">
        <HomeMaintenance :title="titles"></HomeMaintenance>
      </van-tab>
      <van-tab title="公区报事">
        <HomeMaintenance :title="titles"></HomeMaintenance>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import * as vant from "vant";
import HomeMaintenance from "./home-maintenance.vue";

import wx from "weixin-js-sdk";
export default {
  name: "report-repair",
  components: { HomeMaintenance },
  directives: {},
  data() {
    return {
      titles: "home",
    };
  },
  mounted() {},
  methods: {
    onClick(e) {
      if (e === 1) {
        this.titles = "sqbs";
      } else {
        this.titles = "home";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.reportWrapper {
  width: 100%;
  height: 88%;
  overflow: auto;
  padding: 0 20px;
}
/deep/ .van-ellipsis {
  // width: 68px;
  display: block;
  height: 44px;
  font-size: 17px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 44px !important;
}
/deep/ .van-tabs__nav {
  display: block;
  width: 100%;
}
/deep/ .van-tab {
  font-size: 17px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 17px;
  float: left;
  margin-right: 20px;
}

/deep/ .van-tab--active > .van-ellipsis {
  color: #29c985;
}
/deep/ .van-tabs__line {
  width: 32px;
  height: 3px;
  background: #29c985;
  border-radius: 3px;
}
/deep/ .van-hairline--top-bottom::after {
  border-width: 0 0;
}
/deep/ .van-field__body{
  height: 90%;
}
/deep/ .van-field__control{
  height: 100% !important;
}

html body /deep/ .van-field__word-limit{
  margin-bottom: 4px !important;
  margin-top: 0px  !important;
}
</style>