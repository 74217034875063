import * as $commons from "../../commons/index";
import { baseUrl } from "../../commons/index";

const url = $commons.SERVICE_BUSINESS_API;
const agent = $commons.agent;


/**
 * 
 * 正式版为"release"，体验版为"trial"，开发版为"develop"，仅在微信外打开时生效。默认release
 * **/
export async function getWxLink(id) {
    try {
        const data = await agent({
            url: baseUrl + $commons.API_TOOL_URL + `/wechat/getOpenLink?clientId=sqbj-app-small&path=pagesBusiness/pagePay/outPay&query=${id}&envVersion=${process.env.NODE_ENV === 'development' ? 'trial' : "release"}`,
            method: "GET",
            data: '',
        });
        return data;
    } catch (error) {
        throw error;
    }
}

// /group/finance/api/app/bill-app/prepaidOrder/{uuid} 预付单
export async function getInfo(uuid) {
    try {
        const data = await agent({
            url: baseUrl + $commons.GROUP_SERVICE_URL + `/app/bill-app/prepaidOrder/${uuid}`,
            method: "GET",
            data: '',
        });
        return data;
    } catch (error) {
        throw error;
    }
}


// /pay/api//payOrder-app/aliJsPay

export async function aliPays(datas) {
    try {
        const data = await agent({
            url: baseUrl + $commons.PAY_API + `/payOrder-app/aliJsPay`,
            method: "post",
            data: datas,

        });
        return data;
    } catch (error) {
        throw error;
    }
}

// /workorder/api/manager/detail/households/{householdsId}
export async function getManager(householdsId) {
    try {
        const data = await agent({
            url: baseUrl + $commons.API_WORK_URL + `/manager/detail/households/${householdsId}`,
            method: "get"

        });
        return data;
    } catch (error) {
        throw error;
    }
}

// /group/finance/api/app/bill-app/checkBillList 校验账单状态
export async function checkBillList(datas) {
    try {
        const data = await agent({
            url: baseUrl + $commons.GROUP_SERVICE_URL + `/app/bill-app/checkBillList `,
            method: "post",
            data: datas

        });
        return data;
    } catch (error) {
        throw error;
    }
}
