<template>
    <div v-if="!initLoading" class="sqbj-goods-list">
        <van-nav-bar v-show="false" left-arrow title="商品列表" @click-left="goClassifyPage" />
        <van-pull-refresh v-model="pullLoading" :disabled="moreLoading" @refresh="getList(0, true)" class="goods-list">
            <van-list :immediate-check="false" v-model="moreLoading" :finished="finished" @load="getList(index+1)">
                <div :key="item.id" v-for="item in items" @click="goGoodsPage(item.id)">
                    <van-row class="goods-item">
                        <van-col span="6">
                            <img :src="item.gooldsImgUrl">
                        </van-col>
                        <van-col span="18">
                            <van-row class="goods-item-title">
                                <van-col span="14" class="ellipsis">{{item.name}}</van-col>
                                <van-col span="10">
                                    <span>{{item.price}}</span>元/{{item.unit}}
                                </van-col>
                            </van-row>
                            <div class="gools-item-body"> {{item.desc}}</div>
                            <van-row class="goods-item-foot">
                                <van-col span="16" class="goods-merchant">
                                    <img :src="item.merchantLogoUrl">
                                    <span class="ellipsis" style="color:#595959;" @click.stop="goMerchantPage(item.merchantId)">
                                        {{item.merchantName}}
                                    </span>
                                </van-col>
                                <van-col span="8"> 人气：{{item.likeNum}}</van-col>
                            </van-row>
                        </van-col>
                    </van-row>
                    <div class="van-hairline--bottom"></div>
                </div>
                <div class="no-data" v-show="items.length>=count">已显示全部</div>
            </van-list>
        </van-pull-refresh>
    </div>
    <van-loading v-else type="spinner" color="#1989fa" class="sqbj-loading" />
</template>
<script>
import "./refs/style.less";
import * as vant from "vant";
import * as api from "./refs/api";
import * as commons from "../../commons/index";

const Toast = vant.Toast;
// const stroage = commons.storage;

export default {
    data() {
        return {
            index: 0,
            count: 0,
            items: [],
            initLoading: false,
            pullLoading: false,
            moreLoading: false,
            finished: false
        };
    },
    mounted() {
        const { classifyId } = this.$route.params;
        if (!classifyId) {
            return;
        }
        this.initLoading = true;
        this.getList(0, false);
        this.initLoading = false;
    },
    methods: {
        async getList(index, refresh) {
            try {
                if (this.items.length >= this.count && index > 0) {
                    this.finished = true;
                    return;
                }
                const { classifyId } = this.$route.params;
                const data = await api.getGoodsList(classifyId, index);
                this.index = index;
                this.count = data.count;
                if (refresh) {
                    this.finished = false;
                }
                this.items = refresh
                    ? data.items
                    : this.items.concat(data.items);
            } catch (e) {
                Toast(e.message || "获取数据失败！");
            } finally {
                this.moreLoading = false;
                setTimeout(() => {
                    this.pullLoading = false;
                }, 50);
            }
        },
        goGoodsPage(goodsId) {
            const { communityId } = this.$route.params;
            this.$router.push(`/business/goods/${goodsId}/${communityId}`);
        },
        goClassifyPage() {
            this.$store.state.fade = "fade-right";
            const { communityId } = this.$route.params;
            this.$router.push(`/business/classify/${communityId}`);
        },
        goMerchantPage(merchantId) {
            const { communityId } = this.$route.params;
            this.$router.push(`/business/merchant/${merchantId}/${communityId}`);
        }
    }
};
</script>

<style lang="less">
.sqbj-goods-list > .van-nav-bar {
    position: fixed;
    width: 100%;
}
.sqbj-goods-list .goods-list {
    min-height: 100%;
    padding: 0 20px;
}
.sqbj-goods-list .goods-list .goods-item {
    color: #999999;
    padding: 15px 0;
    font-size: 14px;
    > div > img {
        width: 70px;
        height: 70px;
        border-radius: 5px;
    }
    .goods-item-title {
        color: #333333;
        font-size: 16px;
        font-weight: bold;
        > :last-child {
            text-align: right;
        }
        span {
            color: #ff0000;
        }
    }
    .gools-item-body {
        height: 40px;
        line-height: 21px;
        margin-top: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 2;
    }
    .goods-item-foot {
        margin-top: 10px;
        > :first-child {
            display: flex;
            display: -webkit-flex;
        }
        > :last-child {
            font-size: 12px;
            text-align: right;
            line-height: 1.7;
        }
    }
}
.sqbj-goods-list .goods-list > .goods-item:last-child {
    border-bottom: 0px;
}
</style>


