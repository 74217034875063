/**
 获取特定格式时间
* @param {Object} date 时间
* @param {String} format 格式
* @return {String} 特定格式的时间
*
* 例如：
* var now = new Date(); // Mon Jan 16 2017 14:32:22 GMT+0800 (中国标准时间)
* formatDate(now, 'yyyy-MM-dd h:m:s'); // 2017-01-16 14:32:22
 * **/ 

export const formatDate = (date,format)=>{
    let time = {
      "M+":date.getMonth()+1,
      "d+":date.getDate(),
      "h+":date.getHours(),
      "m+":date.getMinutes(),
      "s+":date.getSeconds(),
      "q+":Math.floor((date.getMonth()+3)/3),
      "S+":date.getMilliseconds()
    }
    if(/(y+)/i.test(format)){
      format = format.replace(RegExp.$1,(date.getFullYear()+"").substr(4-RegExp.$1.length))
    }
  
    for(let i in time){
      if(new RegExp(`(${i})`).test(format)){
        format = format.replace(RegExp.$1,RegExp.$1.length === 1 ? time[i] :("00"+time[i]).substr((""+time[i]).length ))
      }
    }
    return format
  }