<template>
    <div v-if="!preLoading" class="result-content">
        <van-icon v-if="passed" name="checked" color="#52c41a" size="90px"/>
        <van-icon v-else name="clear" color="#f5222d" size="90px"/>
        <div class="desc">{{ passed ? "授权成功" : "授权失败" }}</div>
    </div>
    <van-loading v-else type="spinner" color="#1989fa" class="sqbj-loading" />
</template>

<script>
import * as vant from "vant";
import * as $commons from "../../commons/index";

const agent = $commons.agent;
const bpp_api = $commons.SERVICE_BPP_API;
const auth_api = $commons.SERVICE_AUTH_API;

export default {
    data() {
        return {
            preLoading: true,
            passed: true,
        }
    },
    async mounted() {
        const { record_id, app_auth_code } = this.$route.query;
        // console.log(record_id, app_auth_code);
        try {
            const encodeToken = $commons.utils.getEncodeToken("sqbj-support", "sqbj");
            const access_token = (await agent({
                url: `${auth_api}/oauth/token`,
                method: "POST",
                data: `grant_type=device_client_credentials&token=${encodeToken}`,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                }
            })).access_token;
            
            await agent({
                url: `${bpp_api}/payment/apply-finish/alipay/${record_id}?authCode=${app_auth_code}`,
                method: "PUT",
                headers: {
                    "current-app": "pbpp",
                    "project-model": "false",
                    "Authorization": "Bearer " + access_token,
                    "Content-Type": "application/json",
                }
            });
        } catch (e) {
            this.passed = false;
            vant.Toast(e.message || "操作异常");
        }
        this.preLoading = false;
    },
    methods: {

    },
    computed: {

    }
}
</script>

<style lang="less" scoped>
.result-content {
    text-align: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    .desc {
        color: rgba(0,0,0,0.85);
        font-size: 24px;
        line-height: 1.8;
        text-align: center;
    }
}
</style>