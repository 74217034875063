<template>
  <div :class="ratesList.satisfactionMode!== '' ? 'warnWrapper1' : 'warnWrapper'">
    <div
      class="ratesInfo"
      v-if="ratesList.satisfactionMode"
    >
      <van-rate
        style="margin-top:10px"
        v-model="ratesList.notes"
        :size="31"
        :readonly="true"
        color="#ffd21e"
        void-icon="star"
        void-color="#eee"
      />
      <div class="ratesTitle">{{api.likeArray.indexOf(ratesList.satisfactionMode) | filterRates}}</div>
    </div>
    <div class="warnAddress">
      <van-icon
        name="location-o"
        color="#29c985"
        size="15px"
      ></van-icon>
      <p class="infoAddress">{{orderBaseInfo.communityName + orderBaseInfo.address}}</p>
    </div>
    <div class="warnInfo">
      <div class="warnName">{{orderBaseInfo.notes}}</div>
      <div
        style="display:flex"
        v-show="orderBaseInfo.resourcesList.length>0"
      >
        <img
          v-for="item in orderBaseInfo.resourcesList"
          :key="item.url"
          @click="handlePreview"
          style="width:70px;height:70px;margin:19.5px 20px 20px 0"
          v-show="isHaveImg"
          :src="item.url"
          alt=""
        >
      </div>

    </div>
    <div class="warnStatus">
      <div
        class="statusInfo"
        style="display:flex;"
      >
        <div :class="orderInfo.title"></div>
        <div class="reportType">{{orderInfo.topTypeName}}</div>
      </div>
      <div class="statusTime">{{operationTime}}</div>
    </div>
    <!-- 保修进度 -->

    <div class="warnProgress">
      <div class="titleProgress">报修进度</div>
      <div>
        <van-steps
          direction="vertical"
          :active="active"
        >
          <van-step
            v-for="item in timeLineItems"
            :key="item.id"
          >
            <div class="stepTop">
              <h3>{{api.textMap.status[item.acceptStatus]}}</h3>

              <p>{{item.operationTime | formatDate}}</p><br>

            </div>
            <div class="stepContent">
              <p>{{item.operationNotes}}<br><span v-show="item.acceptStatus === 'Created'">工单单号:{{orderInfo.orderNumber}}</span></p>

              <p
                v-show="item.operation == 'SendOrder'"
                style="color:rgb(75, 122, 251)"
              >
                <span style="color:#666">{{item.operationUserName}} </span>派单至<span style="color:#666"> {{item.repairUserName}}({{item.repairUserPhone}})</span>
              </p>
              <p
                v-show="item.operation === 'ForwardOrder'"
                style="color:rgb(75, 122, 251)"
              >
                <span style="color:#666">{{item.operationUserName}}</span>转派工单至<span style="color:#666">{{item.repairUserName}}({{item.repairUserPhone}})</span>
              </p>
              <p
                v-show="item.operation === 'Reminder'"
                style="color:rgb(75, 122, 251)"
              ><span style="color:#666">{{item.operationUserName}}</span>催单</p>
              <p
                v-show="item.operation === 'StartWork'"
                style="color:rgb(75, 122, 251)"
              ><span style="color:#666">{{item.operationUserName}}</span>开始处理工单</p>
              <p
                v-show="item.operation === 'CompleteOrder'"
                style="color:rgb(75, 122, 251)"
              >
                <span style="color:#666">{{item.operationUserName}}</span>结单
                <!-- <span style="color:#666">{{item.operationUserName}}回访人:{{returnVisitBaseInfo.operationUserName}}({{returnVisitBaseInfo.operationPhone}})</span> <br> -->
                <img
                  v-show="item.operation && item.resourcesList.length>0"
                  style="width:80px;border:1px solid #d9d9d9;padding:8px;margin-top:10px"
                  v-for="i in item.resourcesList"
                  :key="i.url"
                  :src="i.url"
                  alt=""
                >
              </p>
              <p
                v-show="item.operation === 'ReturnVisited'"
                style="color:rgb(75, 122, 251)"
              >
                <span style="color:#666">{{item.operationUserName}}</span>评价<br>
                <b style="color:#666">备注：{{returnVisitBaseInfonotes}}</b>
              </p>

              <p
                v-show="item.operation === 'CloseOrder'"
                style="color:rgb(75, 122, 251)"
              >
                <span style="color:#666">{{item.operationUserName}}</span>关闭工单
              </p>

              <div
                v-show="item.operation === 'Signed'"
                style="color:rgb(75, 122, 251)"
              >
                <span style="color:#666">{{item.operationUserName}}</span><br>
                <img
                  style="width:80px;border:1px solid #d9d9d9;padding:8px;margin-top:10px"
                  v-for="i in item.resourcesList"
                  :key="i.url"
                  :src="i.url"
                  alt=""
                >
              </div>
            </div>
          </van-step>
          <!-- <van-step>
            <div class="stepTop">
              <h3>物业已受理</h3>
              <p>2016-07-10 09:30</p>
            </div>
            <div class="stepContent">已分配维修师傅刘大毛 13833721165</div>
          </van-step>
          <van-step>
            <div class="stepTop">
              <h3>报修单提交成功</h3>
              <p>2016-07-10 09:30</p>
            </div>
            <div class="stepContent">单号：2181922122011，请耐心等待物业确认</div>
          </van-step> -->
        </van-steps>
      </div>
    </div>

    <div
      class="submit"
      :style="api.textMap.cancelStatus[orderInfo.status] ? 'bottom:72.5px':''"
      @click="submitHandle"
      v-show="api.textMap.textSatus[orderInfo.status]"
    ><a
        style="display:inline-block;width:100%;height:100%;text-align:center;line-height:41px;color:#fff"
        :href="repairUserPhone ? `tel:${repairUserPhone}` : ''"
      >{{api.textMap.textSatus[orderInfo.status]}}</a></div>

    <div
      class="submit"
      :style="api.textMap.cancelStatus[orderInfo.status] ? 'bottom:72.5px':''"
      @click="submitHandle"
      v-show="(api.textMap.ratesSatus[orderInfo.status] && ratesList.satisfactionMode === '')"
    >{{api.textMap.ratesSatus[orderInfo.status]}}</div>
    <div
      class="cancel"
      @click="cancelHandle"
      v-show="api.textMap.cancelStatus[orderInfo.status]"
    >{{api.textMap.cancelStatus[orderInfo.status]}}</div>
    <!-- 评价 -->
    <van-popup
      v-model="raceControl"
      position="bottom"
      round
      :style="{ height: '400px',width:'100%',padding:'0px 20px' }"
    >
      <div class="raceTop">评价服务

        <img
          style="position:absolute;top:0;right:0;bottom:0;margin:auto;width:44px;height:44px"
          src="./image/close.png"
          alt=""
          @click="raceControl = false"
        >
      </div>
      <div
        class="dialogRates"
        style="width:100%;font-size:15px;color:#666;
      font-weight: 400;text-align:center;margin:25px 0 20px 0"
      >
        <p>请您此次服务进行评分！</p>

        <van-rate
          v-model="rateValue"
          :size="31"
          color="#ffd21e"
          void-icon="star"
          void-color="#eee"
        />
      </div>
      <p style="text-align:center;margin-top:3px">{{rateValue | filterRatesPup}}</p>

      <van-field
        v-model="ratesMessage"
        rows="4"
        autosize
        type="textarea"
        placeholder="请在此填写您对本次服务的评价吧~"
      />

      <div
        class="ratesSubmit"
        @click="ratesSunbmit"
      >提交</div>

    </van-popup>
  </div>
</template>

<script>
import { Toast, ImagePreview } from "vant";
import * as $common from "./config/api";
import * as $api from "./http/api";
import storage from "../../commons/storage";

import wx from "weixin-js-sdk";
import { formatDate } from "./utils/index";
export default {
  name: "warnStatus",
  data() {
    return {
      ratesList: {
        satisfactionMode: "",
        notes: "",
      },
      isHaveImg: false,
      returnVisitBaseInfonotes:"",
      raceControl: false,
      rateValue: 5,
      // 是否评价
      hasRates: true,
      ratesInfoValue: 5,
      active: 0,
      ratesMessage: "",
      // 模拟数据
      stepList: [],
      orderInfo: {
        title: "",
        status: "",
        notes: "",
        topTypeName: "",
      },
      orderBaseInfo: {
        address: "区域1-公共区域",
        businessOrderId: 756,
        chargeType: "Charged",
        communityName: "彩虹城5",
        complaintOrderId: 0,
        createSource: "WG_PC_PRO",
        createTime: 1563501678000,
        createUserName: "和鸿管理员",
        customerName: "武钊",
        isPublic: "NO",
        levelId: 1,
        levelName: "标准",
        levelRgbCode: "#29C985",
        notes: "",
        orderNumber: "20190719103510001",
        orderType: "GENERAL_ORDER",
        phone: "18500190562",
        pressUserTimes: 0,
        receptionModeId: 1,
        receptionModeName: "电话接待",
        reportUserName: [],
        resourcesList: [],
        servicePrice: 0.0,
        status: "",
        topTypeId: 6,
        topTypeName: "建议建言",
        typeId: 98,
        typeName: "建议建言-测试1",
        visitType: "",
      },
      operationTime: "",
      api: $common,
      timeLineItems: [],
      returnVisitBaseInfo: {
        notes: "",
      },
      repairUserPhone: "",
      phone: [],
      orderId: "",
      id: "",
    };
  },
  filters: {
    filterRates: (num) => {
      switch (num) {
        case 1:
        case 2:
          return "让您有这样不舒服的体验实在抱歉！";
        case 3:
          return "感谢您的评价，我们将努力做得更好！";
        default:
          return "感谢您的信任，很高兴为您服务！";
      }
    },
    filterRatesPup: (num) => {
      switch (num) {
        case 1:
          return "十分不满意";
        case 2:
          return "不满意";
        case 3:
          return "一般";
        case 4:
          return "满意";
        default:
          return "十分满意";
      }
    },
    formatDate: (v) => {
      return formatDate(new Date(v), "yyyy-MM-dd h:mm");
    },
  },
  created() {
    this.orderId = this.$route.fullPath.split("=")[2].split("&&")[0];
    console.log(
      this.$route.fullPath.split("=")[1].split("&&")[0],
      "---------========"
    );
    console.log(
      this.$route.fullPath.split("=")[2].split("&&")[0],
      "---------========"
    );
    storage.set(
      storage.keys.ACCESS_TOKEN,
      this.$route.fullPath.split("=")[1].split("&&")[0]
    );
  },
  mounted() {
    console.log(wx);
    this.isHaveImg = true;
    this.$nextTick(() => {
      this.getDetailInfo();
    });
    this.id = this.$route.fullPath.split("=")[2].split("&&")[0];
  },
  beforeDestroy() {
    // localStorage.removeItem(storage.keys.ACCESS_TOKEN)
  },
  methods: {
    handlePreview() {
      let imgList = [];

      this.orderBaseInfo.resourcesList.map((v) => {
        imgList.push(v.url);
      });
      ImagePreview({ images: imgList, closeable: true });
    },
    getDetailInfo() {
      // 通过路由获取id
      $api
        .customDetail(this.$route.fullPath.split("=")[2].split("&&")[0])
        .then((res) => {

          //
          this.orderInfo.title = res.data.acceptStatus;
          this.orderInfo.notes = res.data.orderBaseInfo.notes;
          this.orderInfo.topTypeName = res.data.orderBaseInfo.topTypeName;
          this.orderInfo.status = res.data.acceptStatus;
          this.orderInfo.orderNumber = res.data.orderBaseInfo.orderNumber;
          this.orderBaseInfo = res.data.orderBaseInfo;
          this.operationTime = formatDate(
            new Date(res.data.timeLineItems[0].operationTime),
            "yyyy-MM-dd hh:mm"
          );
          if( res.data.acceptStatus === 'ReturnVisited'){
            console.log(res.data.returnVisitBaseInfo.acceptedNotes,"res.data.acceptedBaseInfo.notes")
            this.returnVisitBaseInfonotes = res.data.returnVisitBaseInfo.notes
          }
          this.timeLineItems = res.data.timeLineItems;
          let forward = res.data.timeLineItems.find(
            (v) => (v.acceptStatus === "Sent" && v.operation === "ForwardOrder")
          );
          if (forward) {
            forward && (this.repairUserPhone = forward.repairUserPhone);
          } else {
            let find = res.data.timeLineItems.find(
              (v) => v.acceptStatus === "Sent" && v.operation === "SendOrder"
            );
            find && (this.repairUserPhone = find.repairUserPhone);
          }
          
          if (
            this.orderInfo.status === "Sent" ||
            this.orderInfo.status === "Aceepting"
          ) {
          }
          
          this.returnVisitBaseInfo = res.data.returnVisitBaseInfo
            ? res.data.returnVisitBaseInfo
            : { notes: "" };
          // this.returnVisitBaseInfo.notes = this.filterStar(
          //   this.returnVisitBaseInfo.notes[0]
          // );
          this.ratesList = res.data.returnVisitBaseInfo
            ? res.data.returnVisitBaseInfo
            : { satisfactionMode: "", notes: "" };
          this.ratesList.notes = res.data.returnVisitBaseInfo
            ? $common.likeArray.indexOf(
                res.data.returnVisitBaseInfo.satisfactionMode
              )
            : "";
          this.timeLineItems.map((v) => {
            v.acceptStatus === "Sent" && this.phone.push(v.repairUserPhone);
          });
        });

        

    },
    filterStar(num) {
      if (num === "一") {
        return 1;
      } else if (num === "二") {
        return 2;
      } else if (num === "三") {
        return 3;
      } else if (num === "四") {
        return 4;
      } else if (num === "五") {
        return 5;
      } else {
        return "";
      }
    },
    ratesSunbmit() {
      // 评分接口 $api.score()
      console.log({
        businessOrderId: this.id,
        satisfactionMode: $common.likeArray[this.rateValue],
        notes: this.ratesMessage,
        receptionModeId: 2,

        isResolve: true,
        signResource: {},
      });
      $api
        .score({
          businessOrderId: this.id,
          satisfactionMode: $common.likeArray[this.rateValue],
          notes: this.ratesMessage,
          receptionModeId: 2,

          isResolve: true,
        })
        .then((res) => {
          Toast("评价成功");

          this.raceControl = false;
          this.getDetailInfo(this.id);
        })
        .catch((data) => {
          Toast("评价失败");
        });
    },
    //   保修状态底钩子 等业务场景传值调不同接口
    submitHandle() {
      if (
        this.orderInfo.status === "Sent" ||
        this.orderInfo.status === "Aceepting"
      ) {
        // this.raceControl = true;
        // 联系处理人

        wx.makePhoneCall({
          phoneNumber: this.repairUserPhone, //仅为示例，并非真实的电话号码
        });
        // if (this.phone.length === 1) {
        //   //  直接打
        // }
      } else if (
        this.api.textMap.ratesSatus[this.orderInfo.status] &&
        this.ratesList.satisfactionMode === ""
      ) {
        console.log("去评价");
        /**
         * 静态 评价
         * **/
        this.raceControl = true;
      }
    },
    cancelHandle() {
      $api
        .cancelOrder({
          businessOrderId: this.orderId - 0,
          closeReason: "",
          resourcesList: [],
        })
        .then((res) => {
          if (res.success) {
            Toast("取消成功");
            this.getDetailInfo(this.orderId);
          } else {
            Toast("取消失败");
          }
        })
        .catch((data) => {
          console.log(data);
          Toast(data);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.warnWrapper1,
.warnWrapper {
  width: 100%;
  height: 86%;
  padding: 0 20.5px 0 21px;
  overflow-y: auto;
  .warnAddress {
    height: 60px;
    position: relative;
  }
  .warnAddress::after {
    content: "";
    width: 100%;
    height: 1px;
    display: block;
    transform: scaleY(0.5);
    background-color: #e8e8e8;
    position: absolute;
    bottom: 0px;
    left: 0px;
  }
  .infoAddress {
    width: 100%;
    height: 100%;
    line-height: 60px;
    margin-left: 17.5px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #969696;
  }
}
.warnWrapper1 {
  height: 95%;
}

.ratesInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  .ratesTitle {
    margin: 15px 0 0px 0;
    padding-bottom: 20px;
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #969696;
    line-height: 20px;
    position: relative;
    width: 100%;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: #e8e8e8;
      transform: scaleY(0.5);
      bottom: 0px;
      left: 0px;
    }
  }
}
.submit,
.cancel {
  width: 335px;
  height: 41px;
  background: #29c985;
  border-radius: 6px;
  text-align: center;
  line-height: 41px;
  color: #fff;
  position: fixed;
  bottom: 15.5px;
  z-index: 99;
}

.cancel {
  background: #f6f6f6;
  border: 1px solid #eff4f9;
  color: #29c985;
}

/deep/ .van-popup--bottom.van-popup--round {
  border-radius: 10px 10px 0 0;
}
.warnInfo {
  display: flex;
  flex-direction: column;
  position: relative;
  &::after {
    content: "";
    width: 100%;
    height: 1px;
    display: block;
    transform: scaleY(0.5);
    background-color: #e8e8e8;
    position: absolute;
    bottom: 0px;
    left: 0px;
  }
}
.warnName {
  // margin-top: 41px;
  min-height: 59px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 27px;
}
.warnStatus {
  height: 60px;
  position: relative;
  &::after {
    content: "";
    width: 100%;
    height: 1px;
    display: block;
    transform: scaleY(0.5);
    background-color: #e8e8e8;
    position: absolute;
    bottom: 0px;
    left: 0px;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
}
// 待派单
.Created {
  position: relative;
  &::after {
    content: "待派单";
    line-height: 14px;
    text-align: center;
    border: 0.5px solid #347aeb;
    color: #347aeb;
    font-size: 12px;
    padding: 2px 4px;
    border-radius: 4px;
  }
}

// 已派单
.Sent {
  position: relative;
  &::after {
    content: "已派单";
    line-height: 14px;
    text-align: center;
    border: 0.5px solid #29c985;
    color: #29c985;
    font-size: 12px;
    padding: 2px 4px;
    border-radius: 4px;
  }
}
// 处理中
.Accepting {
  position: relative;
  &::after {
    content: "处理中";
    line-height: 14px;
    text-align: center;
    border: 0.5px solid #29c985;
    color: #29c985;
    font-size: 12px;
    border-radius: 4px;
    padding: 2px 4px;
  }
}
// 已结单
.Accepted {
  position: relative;
  &::after {
    content: "已结单";
    line-height: 14px;
    text-align: center;
    border-radius: 4px;
    border: 0.5px solid #29c985;
    color: #29c985;
    font-size: 12px;
    padding: 2px 4px;
  }
}
.Finished {
  position: relative;
  &::after {
    content: "已完成";
    line-height: 14px;
    text-align: center;
    border-radius: 4px;
    border: 0.5px solid #29c985;
    color: #29c985;
    font-size: 12px;
    padding: 2px 4px;
  }
}
// 已回访
.ReturnVisited {
  position: relative;
  &::after {
    content: "已回访";
    line-height: 14px;
    border-radius: 4px;
    text-align: center;
    border: 0.5px solid #ffbb06;
    color: #ffbb06;
    font-size: 12px;
    padding: 2px 4px;
  }
}
// 已取消
.Closed {
  position: relative;
  &::after {
    content: "已取消";
    line-height: 14px;
    text-align: center;
    border-radius: 4px;
    border: 0.5px solid #666666;
    color: #666666;
    font-size: 12px;
    padding: 2px 4px;
  }
}
.reportType {
  float: left;
  margin-left: 10px;
  height: 14px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #969696;
  line-height: 14px;
  padding: 0px 4px;
}
.titleProgress {
  height: 24px;
  font-size: 17px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3c3c3c;
  line-height: 24px;
  margin: 20px 0;
}
.raceTop {
  height: 60px;
  text-align: center;
  line-height: 60px;
  position: relative;
  font-size: 19px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  &::after {
    position: absolute;
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #dcdcdc;
    transform: scaleY(0.5);
    bottom: 0;
    left: 0;
  }
}
.stepTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  h3 {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #595959;
    line-height: 20px;
  }
  p {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #969696;
    line-height: 20px;
  }
}
.stepContent {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #969696;
  line-height: 20px;
}

/deep/ .van-icon-location-o {
  color: rgb(41, 201, 133);
  font-size: 15px;
  height: 100%;
  float: left;
}
/deep/ .van-icon-location-o::before {
  bottom: 0;
  transform: translateY(-145%);
  position: absolute;
}
/deep/ .van-rate__item {
  padding-right: 0px;
  margin-right: 7.5px;
}
/deep/ .van-step {
  margin-bottom: 30px;
}
/deep/ .van-hairline:after {
  border: none;
}
.dialogRates {
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 21px;
  p {
    margin: 0px 0 20px 0;
  }
}
/deep/ .van-cell {
  background-color: #f6f6f6;
  border-radius: 5px;
  width: 335px;
  height: 120px;
  margin-top: 20px;
}
.ratesSubmit {
  width: 335px;
  height: 41px;
  background: #29c985;
  border-radius: 21px;
  color: #fff;
  line-height: 41px;
  text-align: center;
  position: absolute;
  bottom: 15px;
}
</style>
