<template>
    <div class="sqbj-amap-country">
        <el-amap ref="map" :mapStyle="mapStyle" :zoom="4" :features="[]" :events="events">
            <el-amap-circle-marker 
                v-bind:key="index" 
                v-for="(m, index) in _markers" 
                :center="m.center" 
                :fill-color="m.color"
                :stroke-color="m.color"
                :radius="marker && marker.id === m.id ? 6 : 3"
                :z-index="marker && marker.id === m.id ? 1001 : 1000">
            </el-amap-circle-marker>
            <el-amap-info-window v-if="!!marker" :bubble="true" :position="marker.center" :offset="[0,-7]">
                <div class="sqbj-info-tips">
                    <p>{{marker.name}}&emsp;
                        <span :style="{color: colorMap[marker.type]}">{{textMap[marker.type]}}</span>&emsp;
                        {{formatTime(marker.eventTime)}}
                    </p>
                    <p>{{marker.content}}</p>
                </div>
            </el-amap-info-window>
        </el-amap>
        <div class="sqbj-amap-window">
            <p class="navbar">
                <span v-bind:key="index" v-for="(a, index) in _acroutes" >
                    <a @click="goNav(a.adcode)"  >{{a.name}}</a>{{index === _acroutes.length - 1 ? '': '  /  ' }} 
                </span>
            </p>
            <!-- <select @change='selectVal'>
                    <option value ="satisfactionRate">满意度</option>
                    <option value ="createordernum">工单总数</option>
            </select> -->
        </div>
    </div>
</template>
<script>

import * as moment from "moment";
import * as $data from "./index";
import * as $antd from "ant-design-vue";
import * as $commons from '../../commons/index';
import storage from "../../commons/storage";
import {setaccessToken}from "../../commons/agent"
import "./style.less";
import { constants } from 'fs';
import { async } from 'q';

let map = null;
let windowInterval = null;
let eventInterval = null;
let tenantId = "";
let secretKey = "";
let districtExplorer = null;

const mybounds = new AMap.Bounds([120, 50], [90, 20]);
const excludeCitys = [500000,310000,120000,810000,820000,110000,710000];
// const accessToken = "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJkZXZpY2VUeXBlIjoiQlJPV1NFUiIsInN1cHBvcnRNb2RlbFBlcmlvZCI6e30sIm9hdXRoMlVzZXJJZCI6NzksInVzZXJPcGVuSWQiOiJGMzIyNEY0MUM0OEU1RjMyN0JCNjRCOUJDQUI3NjU2MiIsInByb2plY3RBZG1pbiI6ZmFsc2UsImNvbW11bml0eU1vZGVsUGVyaW9kIjp7fSwidXNlcl9uYW1lIjoiMTU4MTAwNjE0NjEiLCJncm91cFJlc291cmNlcyI6eyJnZm1wIjoiMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwZiIsImdzeXMiOiIwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwN2UwIiwiZ3RzcCI6IjAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMGYiLCJnZGF0YSI6IjAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMWYiLCJnYnBwIjoiMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAzZiJ9LCJhY2NvdW50VHlwZSI6IlBSSU1BUllfQUNDT1VOVCIsImNvbW11bml0eVJlc291cmNlcyI6e30sImdyb3VwTW9kZWxQZXJpb2QiOnsiZ3N5cy1kZWZhdWx0IzI4IjoxODg4NzMyMjAyMDAwLCJnYnBwLWRlZmF1bHQjMjUiOjE4ODg3MzIxNzgwMDAsImdkYXRhLWdkYXRhLWRlZmF1bHQjMzYiOjM2NjU2MTUxNTUwMDAsImd0c3AtZGVmYXVsdCMyNyI6MTg4ODczMjE5MzAwMCwiZ2ZtcC1kZWZhdWx0IzI2IjoxODg4NzMyMTg1MDAwfSwiZGV2aWNlSWQiOjEyNiwiYXV0aG9yaXRpZXMiOlsic21hcnQtYWxsIl0sImNsaWVudF9pZCI6InNxYmotc21hcnQiLCJwaG9uZSI6IjE1ODEwMDYxNDYxIiwic2NvcGUiOlsic21hcnQtYWxsIl0sInVzZXJBY2NvdW50SWQiOjc5LCJ0ZW5hbnRJZCI6IlgwMDAxIiwiZGV2aWNlVWlkIjoiNkVGODAwQ0UwRjQzM0EzRDA4ODMxNzYyMTZCMTk5NzAiLCJzdXBwb3J0UmVzb3VyY2VzIjp7fSwiZXhwIjoxNTg1MzMyNjU4LCJqdGkiOiI0MWJkZDliNy1hZmFiLTQ1YjYtOGExNy1lMWYzNWUwZjY3ZDMifQ.Jn00ATdBYTRiEQYFX6SLaRfXcyWN_Su0UwhM3hYaFgzV-VnPeqsIeKL6Xv9m4UYysTIyYPzDaa-Yn9xhFUe0JMm_eVhRXKq8B2-KeFOdXqaXo8zsw_wmYTUF45lidJn86LjxKjoyjW1RyX9JIKd4dO6khy-TpGp0O9SulfjVaGNMCDE3V_glpVvBG3zrjrkendGlvU8s0yVWxnbGTW4W58gv1ztyViuhahUcPnPD9y2cbqz24M1e0vA5tHJtElNZ_0QhO-w496EFYeUsz-wBcDubVQsBLHX_UpfxQotwHwqheRijSzir-ogU4NKpyAyjh04CeavIYQ6TaLnpX54k8A"

export default {
    data() {
        tenantId = this.$route.query.tenantId;
        secretKey = this.$route.query.secretKey;
        secretKey = encodeURIComponent(secretKey);
        return {
            mapStyle: $data.MAP_STYLE,
            textMap: $data.eventTextMap,
            colorMap: $data.eventColorMap,
            markers: [], // 小区点位数据
            marker: null, 
            currentAdcode: 100000,
            startDate: "2020-01-01 00:00:00",
            endDate: "",
            queryDateType:"YEAR",
            acroutes: [
                {
                    adcode: 100000,
                    name: "全国",
                    level: "country"
                }
            ],
            dataViewType: "satisfactionRate",
            events: {
                init:  () => {
                    var that = this;
                    map = this.$refs.map.$$getInstance();
                    this.initMap();
                }
            },
            countryItem: []
        }
    },
    mounted(){
        window.addEventListener("message", (event)=>{
                if(event && event.data.authorization){
                    let province = [];
                    let city = [];
                    storage.set(storage.keys.ACCESS_TOKEN, event.data.authorization)
                    // console.log(event.data);
                    this.startDate= event.data.dateStart,
                    this.endDate= event.data.dateEnd,
                    this.queryDateType= event.data.dateType,
                    setaccessToken(event.data.authorization)
                    const accessToken = storage.get(storage.keys.ACCESS_TOKEN);
                    clearInterval(eventInterval);
                    const fn = async () => {
                        const markers = await get_event_data();
                        markers.map(x => {
                            const color = $data.eventColorMap[x.type];
                            x.color = color;
                        });
                        this.markers = markers;
                        this.startWindowLoop();
                    }
                    fn();
                    eventInterval = setInterval(fn, 5 * 60 * 1000);
                    if(event.data.adCode){
                        let ad = event.data.adCode;
                        if( ad === "110100" ||  ad === '120100' || ad ===  '500100' || ad ===  '310100'){
                           ad = event.data.adCode.substr(0,3) + "000";
                        }
                        this.switchArea(Number(ad));
                    }
                }else{
                    return false
                }
        }, false);
    },
    methods: {
        selectVal(val){
            const that = this;
            that.dataViewType = val.target.value;
            this.initMap()
        },
        
       async initMap(adcode = 100000) {
            const that = this;
            let dataItem = [];
            AMapUI.load(['ui/geo/DistrictExplorer'], function(DistrictExplorer) {
                districtExplorer = new DistrictExplorer({
                    eventSupport: true, 
                    map: map
                });
                // 监听feature的hover事件
                districtExplorer.on('featureMouseout featureMouseover', function(e, feature) {
                    var props = feature.properties;
                    // if(props.level === 'district') {
                    //     return;
                    // }
                    var isHover = e.type === 'featureMouseover';
                    var polys = districtExplorer.findFeaturePolygonsByAdcode(props.adcode);
                    for (var i = 0, len = polys.length; i < len; i++) {
                        polys[i].setOptions({
                            fillOpacity: isHover ? 0.5 : 1
                        });
                    }
                    let _acroutes = that.acroutes.filter(x => !x.hover );
                    if(isHover) {
                        _acroutes.push({ adcode: props.adcode, name: props.name, level: props.level, hover: true });
                    }
                    that.acroutes = _acroutes;
                });
                // feature被点击
                districtExplorer.on('featureClick', function(e, feature) {
                    var props = feature.properties;
                    if(props.level === 'district') {
                        return;
                    }
                    that.switchArea(props.adcode);
                });
                that.switchArea(adcode);
            });
        },
        formatTime(t) {
            return !t ? '': moment(t).format("MM-DD HH:mm");
        },
        startWindowLoop() {
            this.marker = null;
            clearInterval(windowInterval);
            let index = 0;
            windowInterval = setInterval(() => {
                 if(index > this._markers.length - 1) {
                    index = 0;
                }
                this.marker = this._markers[index];
                index++ ;
            }, 3000);
        },
        goNav(adcode) {
            const _acroutes = [];
            for (let i = 0; i < this.acroutes.length; i++) {
                const ele = this.acroutes[i];
                _acroutes.push(ele);
                if(ele.adcode === adcode) {
                    break;
                }
            }
            this.acroutes = _acroutes;
            this.switchArea(adcode);
        },
        // 切换区域
        async switchArea(adcode = 100000) {
            this.startWindowLoop();
            const that = this;
            districtExplorer.loadAreaNode(adcode, function(error, areaNode) {
                districtExplorer.setAreaNodesForLocating([areaNode]);
                that.renderAreaNode(areaNode, adcode);
            })
        },
        // 绘制某个区域图层
        async renderAreaNode(areaNode, adcode) {
            const thisProps = areaNode.getParentFeature().properties;
            const level = thisProps.level;
             // 不显示区域地图
            if(level === "district") {
                return;
            }
            
            let dataList = [];
            const accessToken = storage.get(storage.keys.ACCESS_TOKEN);
            setaccessToken(accessToken)
            // if(accessToken === ""){
            //     console.log(1111111);
            //     return false
            // }
            if(level === 'country') {
                dataList = await get_Country(this.startDate,this.endDate, this.queryDateType);
            } else if(level === "province") {
                if(areaNode.adcode === 110000 || areaNode.adcode === 120000 || areaNode.adcode === 500000 || areaNode.adcode === 310000){
                    dataList = await get_City(this.startDate, this.endDate, this.queryDateType, String(areaNode.adcode).substr(0,3) + "100")
                }else{
                    dataList = await get_Province(this.startDate,this.endDate, this.queryDateType,areaNode.adcode);
                }
            } else if(level === 'city'){
                dataList = await get_City(this.startDate, this.endDate, this.queryDateType, areaNode.adcode)
            }
            const maxArea = Math.max(...dataList.map(d => d.area));

            this.currentAdcode = thisProps.adcode;
            
            const _acroutes = this.acroutes.filter(x => x.level !== level);
            _acroutes.push({ adcode: thisProps.adcode, name: thisProps.name, level });
            this.acroutes = _acroutes;

            // 更新地图视野
            if(areaNode.adcode === 100000){
                map.setBounds(mybounds);
            } else {
                map.setBounds(areaNode.getBounds());
            }
            const bgColor = "#1745B8";
            const colors = ['#C11000','#E05D4A','#F39D84','#FFFFCC']
            const featureProps = {
                cursor: 'default',
                bubble: true,
                strokeColor: '#98C8FF', 
                strokeOpacity: 1, 
                strokeWeight: 1,
                fillColor: bgColor
            }
            districtExplorer.setHoverFeature(null);
            districtExplorer.clearFeaturePolygons();
            // 不加载区域地图
            const that = this;
                districtExplorer.renderSubFeatures(areaNode, function(feature, i) {
                    const props = feature.properties;
                    let fillColor = bgColor;
                    let node = [];
                    // console.log(dataList);
                    // console.log(props.adcode);
                    node = dataList.find(x => Number(x.adcode) === props.adcode);
        if(node){
                  if(node.satisfactionRate >= 80) {
                        fillColor = bgColor;
                    }else if(node.satisfactionRate >= 60 &&  node.satisfactionRate < 80){
                        fillColor = colors[3];
                    }else if(node.satisfactionRate >= 40 && node.satisfactionRate < 60){
                        fillColor = colors[2]
                    }else if( node.satisfactionRate >= 20 &&  node.satisfactionRate < 40){
                        fillColor = colors[1];
                    }else if(node.satisfactionRate >= 0 && node.satisfactionRate < 20){
                        fillColor = colors[0];
                    }      

        }
                    
                        // if(that.dataViewType === "createordernum"){
                        //     if(node && node.createordernum >= 80) {
                        //         fillColor = colors[0];
                        //     }else if(node &&  60 <= node.createordernum < 80){
                        //         fillColor = colors[1];
                        //     }else if(node &&  40 <= node.createordernum < 60){
                        //         fillColor = colors[2];
                        //     }else if(node &&  20 <= node.createordernum < 40){
                        //         fillColor = colors[3];
                        //     }else if(node &&  0 <= node.createordernum < 20){
                        //         fillColor = colors[4];
                        //     }
                        // }
                    return {
                        ...featureProps,
                        fillColor: fillColor, //填充色
                    };
                });
            
            districtExplorer.renderParentFeature(areaNode, {...featureProps, fillColor: null });
        },
    },
    computed: {
       _markers: function() {
           let result = [];
            if(this.currentAdcode === 100000) {
                result = this.markers;
            } else {
                let adstr = String(this.currentAdcode).replace(/(0+)$/g,'');
                result = this.markers.filter(x => String(x.adcode).substr(0,adstr.length) === adstr);
            }
            return result;
       },
       _acroutes: function() {
           let result = this.acroutes;
           const hoverObj = this.acroutes.find(x => x.hover === true);
           if(hoverObj) {
               result = result.filter(x => x.level !== hoverObj.level );
               result.push(hoverObj);
           }
           return result;
       }
    },

}

/**
 * 获取工单事件列表
 */
async function get_event_data() {
    let result = [];
    let response = null;
    try {
        response = await $commons.agent({
            method: "GET",
            baseURL: $commons.SERVICE_BIGDATA_API,
            url: `area-map/events?areaId=0&pageSize=10&interval=86400`
        });
    } catch (e) {
        $antd.message.error(e.message || "获取数据失败");
    }
    if(response && response.data && response.data.length) {
        response.data.forEach(x => {
            const centers = (x.areaLocation).split(";");
            result.push({
                id: x.id,
                areaId: x.areaId,
                adcode: x.adCode,
                name: x.areaName,
                center: centers,
                content: x.content,
                type: x.type,
                finished: x.isDeal === "YES", // 已处理
                eventTime: x.eventTime, // 事件事件
                dealTime: x.dealTime, // 处理时间
            })
        })
    }
    return result;
}



/**
 * 获取全国满意度
 */
async function get_Country(startDate, endDate, queryDateType) {
    let result = [];
    let response = null;
    try {
        response = await $commons.agent({
            method: "POST",
            baseURL: $commons.SERVICE_BIGDATA_API,
            url: `/statistic/result/system`,
            data: {
                businessJobId:111,
                chartType:"TextChart",
                startDate,
                endDate,
                queryDateType,
            }
        });
    } catch (e) {
        $antd.message.error(e.message || "获取数据失败");
    }
    if(response && response.data) {
        response.data.data.forEach(x=>{
            result.push({
                adcode: Number(x[0].value),
                satisfactionRate: Number(x[1].value)*100,
                createordernum: Number(x[2].value),
            })
        })
    }
    return result;
}


/**
 * 获取省
 */
async function get_Province(startDate, endDate, queryDateType,regionCode) {
    let result = [];
    let response = null;
    try {
        response = await $commons.agent({
            method: "POST",
            baseURL: $commons.SERVICE_BIGDATA_API,
            url: `/statistic/result/system`,
            data: {
                businessJobId:112,
                chartType:"TextChart",
                startDate,
                endDate,
                queryDateType,
                regionCode,
            }
        });
    } catch (e) {
        $antd.message.error(e.message || "获取数据失败");
    }
    if(response && response.data) {
        response.data.data.forEach(x=>{
            result.push({
                adcode: Number(x[0].value),
                satisfactionRate: Number(x[2].value)*100,
                createordernum: Number(x[3].value),
            })
        })
    }
    return result;
}



/**
 * 获取市
 */
async function get_City(startDate, endDate, queryDateType,regionCode) {
    let result = [];
    let response = null;
    try {
        response = await $commons.agent({
            method: "POST",
            baseURL: $commons.SERVICE_BIGDATA_API,
            url: `/statistic/result/system`,
            data: {
                businessJobId:113,
                chartType:"TextChart",
                startDate,
                endDate,
                queryDateType,
                regionCode,
            }
        });
    } catch (e) {
        $antd.message.error(e.message || "获取数据失败");
    }
    if(response && response.data) {
        response.data.data.forEach(x=>{
            result.push({
                adcode: Number(x[1].value),
                satisfactionRate: Number(x[2].value)*100,
                createordernum: Number(x[3].value),
            })
        })
    }
    return result;
}

/**
 * 获取省市小区数据
 */
async function get_district_data(adcode = 100000) {
    let result = [];
    let response = null;
    try {
        response = await $commons.agent({
            method: "GET",
            baseURL: $commons.SERVICE_BIGDATA_API,
            url: `/area-map/area-info?adCode=${adcode}`
        });
    } catch (e) {
        $antd.message.error(e.message || "获取数据失败");
    }
    if(response && response.data && response.data.length) {
        response.data.forEach(x => {
            result.push({
                adcode: Number(x.adCode),
                name: x.adName,
                area: Number((x.area / 10000).toFixed(2)),
                count: x.communityNum,
            })
        });
    }
    return result;
}


</script>
<style lang="less">
.sqbj-amap-country .sqbj-amap-window {
    position: absolute;
    z-index: 1000;
    top: 0px;
    left: 0px;
    padding: 6px 10px;
    color: #C3E5F9;
    .navbar {
        a {
            cursor: pointer;
            &:hover {
                text-decoration: underline
            }
        }
    }
}
</style>



