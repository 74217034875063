<template>
    <div v-if="!initLoading" class="sqbj-classify">
        <van-row class="classify">
            <div class="classify_left">
                <van-sidebar :active-key="classifyIdx">
                    <van-sidebar-item :key="classify.id" v-for="classify in classifyList" :title="classify.name" @click="switchClassify" />
                </van-sidebar>
            </div>
            <div class="classify_right">
                <div v-if="currCfy.id" class="classify_content">
                    <div class="classify_content_header">{{currCfy.name}}</div>
                    <div class="van-hairline--bottom"></div>
                    <div class="classify_content_body">
                        <van-row v-if="currCfy.subList && currCfy.subList.length">
                            <van-col span="8" class="classify_item" :key="classify2.id" v-for="classify2 in currCfy.subList || []">
                                <img @click="goGoodsListPage(classify2.id)" :src="classify2.url">
                                <div>{{classify2.name}}</div>
                            </van-col>
                        </van-row>
                        <div v-else class="no-data">暂无数据</div>
                    </div>
                </div>
                <div v-else class="no-data">暂无数据</div>
            </div>
        </van-row>
    </div>
    <van-loading v-else type="spinner" color="#1989fa" class="sqbj-loading" />
</template>

<script>
import "./refs/style.less";
import * as vant from "vant";
import * as api from "./refs/api";
import * as commons from "../../commons/index";

const Toast = vant.Toast;
const stroage = commons.storage;
const sendAppUrl = commons.utils.sendAppUrl;

export default {
    data() {
        return {
            initLoading: false,
            classifyIdx: 0,
            classifyList: []
        };
    },
    async mounted() {
        try {
            this.initLoading = true;
            const { communityId } = this.$route.params;
            if (!communityId) {
                Toast("未选择社区");
                return;
            }
            stroage.set(stroage.keys.COMMUNITY_ID, communityId)
            const classifyList = await api.getCategoryList(communityId);
            if (classifyList && classifyList.length) {
                this.classifyList = classifyList;
            }
        } catch (e) {
            Toast(e.message || "获取数据失败！");
        } finally {
            this.initLoading = false;
        }
    },
    methods: {
        switchClassify(key) {
            this.classifyIdx = key;
        },
        goGoodsListPage(classifyId) {
            const { communityId } = this.$route.params;
            const url = `/business/goods-list/${classifyId}/${communityId}`;
            try {
                sendAppUrl(window.location.origin + url);
            } catch (error) { console.log(error) }
            // this.$router.push(url);
        }
    },
    computed: {
        currCfy: function() {
            if (this.classifyList && this.classifyList.length) {
                return this.classifyList[this.classifyIdx];
            }
            return {};
        }
    }
};
</script>

<style lang="less">
.sqbj-classify > .classify {
    color: #333333;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    > div {
        height: 100%;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }
    > .classify_left {
        float: left;
        background: #f8f8f8;
    }
    > .classify_right {
        padding: 10px;
        background: #fdfdfd;
    }
}

.sqbj-classify .classify_content {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid rgba(0, 0, 0, 0.02);
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.02);
}

.sqbj-classify .classify_content > .classify_content_header {
    font-size: 13px;
    padding: 4px 0px 8px 0px;
}
.sqbj-classify .classify_content > .classify_content_body .classify_item {
    margin-top: 10px;
    text-align: center;
    > img {
        width: 50px;
        height: 50px;
        border-radius: 5px;
    }
    > div {
        height: 36px;
        color: #595959;
        font-size: 13px;
        margin-top: 10px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 2;
    }
}

.sqbj-classify .van-sidebar .van-sidebar-item {
    position: relative;
    text-align: center;
    border-left: 0px;
    &--select,
    &--select:active {
        color: #29c985;
        font-weight: normal;
    }
    &--select::before {
        content: "";
        border-left: 3px solid #29c985;
        height: 36px;
        position: absolute;
        left: 0;
        top: 12px;
    }
}
</style>


