// import { Toast } from "vant";
import { Base64 } from "js-base64";
import { agent, baseUrl } from "../commons/index";
import { PAY_API } from "../commons/index";
var nu = navigator.userAgent;
var is_ios = /(iPhone|iPad|iPod|iOS)/i.test(nu);
var is_android = /(Android)/i.test(nu);
// var is_qq = /QQ/i.test(nu);
// var is_wx = /MicroMessenger/i.test(nu);

// iso固件版本
export var os_v = (function () {
    var reg = /OS ((\d+_?){2,3})\s/;
    if (
        navigator.userAgent.match(/iPad/i) ||
        navigator.platform.match(/iPad/i) ||
        navigator.userAgent.match(/iP(hone|od)/i) ||
        navigator.platform.match(/iP(hone|od)/i)
    ) {
        var osv = reg.exec(navigator.userAgent);
        if (osv.length > 0) {
            return osv[0]
                .replace('OS', '')
                .replace('os', '')
                .replace(/\s+/g, '')
                .replace(/_/g, '.');
        }
    }
    return '';
})();

// h5和ios交互
export function setupWebViewJavascriptBridge(callback) {
    if (window.WebViewJavascriptBridge) {
        // eslint-disable-next-line
        return callback(WebViewJavascriptBridge);
    }
    if (window.WVJBCallbacks) {
        return window.WVJBCallbacks.push(callback);
    }
    window.WVJBCallbacks = [callback];
    var WVJBIframe = document.createElement('iframe');
    WVJBIframe.style.display = 'none';
    WVJBIframe.src = 'wvjbscheme://__BRIDGE_LOADED__';
    document.documentElement.appendChild(WVJBIframe);
    setTimeout(function () {
        document.documentElement.removeChild(WVJBIframe);
    }, 0);
}

export function sendAppUrl(url) {
    if (is_ios) {
        // setupWebViewJavascriptBridge(function(bridge) {
        //     bridge.callHandler('iosNative', url);
        // });

        iosNative(url) // eslint-disable-line
    } else if (is_android) {
        window.WebViewJavascriptBridge.callHandler('androidNative', url);
    }
}

/**
 * 获取base64加密token
 * @param client 
 * @param secrect 
 */
export function getEncodeToken(client, secrect) {
    const code = `${client}:${secrect}:BROWSER::${new Date().getTime()}`;
    return Base64.encode(code);
}

export function getUrlParam(name) {
    const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    const res = window.location.search.substring(1).match(reg);
    if (res) {
        return res[2];
    } else {
        return null;
    }
}
/**
 * @description 需要确保当前网页域名在微信公众号中已获得授权，授权方式：微信公众平台官网中【设置与开发】-【功能设置】-【网页授权域名】的配置选项中修改授权回调域名
 * @param {*} appid 
 * @returns 
 */
export function _wxAuthorize(appid) {
    const redirectUrl = `${location.origin}${location.pathname}?uuid=${getUrlParam("uuid")}`;
    window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appid + '&redirect_uri=' + encodeURIComponent(redirectUrl) + '&response_type=code&scope=snsapi_base&state=#wechat_redirect'
}
/**
 * @description 获取用户公众号openid
 * @description ①需要后端给前端提供一个接口，以下称呼该接口为A
 * @description ②A内部实现调用外部接口B：https://api.weixin.qq.com/sns/oauth2/access_token?appid=APP_ID&secret=APP_SECRET&code=CODE&grant_type=authorization_code
 * @description ③A向前端返回接口B返回的数据，其中包括openid
 * @param {*} code 
 * @returns 40013:invalid appid 
 * @returns 40029:invalid code
 */
export async function _getWxOpenid({ code, appid }) {
    return new Promise((resolve, reject) => {
        agent({
            method: "get",
            url: `${baseUrl}/tool/api/wechat/getByWechatCode?appId=${appid}&code=${code}`,
        }).then(({ data }) => {
            if (data.openid) {
                resolve(data.openid);
            } else {
                data.message = data.errmsg || "获取openid失败";
                reject(data);
            }
        }).catch(reject);
    });
}
/**
 * @description 创建支付单
 * @param {*} param0 
 * @returns 
 */
export async function _h5Pay({ uuid, openid }) {
    return new Promise((resolve, reject) => {
        agent.post(
            `${baseUrl}${PAY_API}/payOrder-app/h5Pay`,
            {
                uuid,
                subOpenId: openid,
            },
        ).then(res => {
            if (res.data.pay_info) {
                try {
                    const data = JSON.parse(res.data.pay_info);
                    resolve(data);
                } catch (e) {
                    reject(e);
                }
            } else {
                res.data.message = res.data.err_msg;
                reject(res.data);
            }
        }).catch(reject);
    });
}
/**
 * @description 微信客户端内部调起微信支付
 * @param {*} payInfo 
 * @returns true：支付成功 false：用户取消
 */
export function _callWxPay(payInfo) {
    return new Promise((resolve, reject) => {
        if (typeof WeixinJSBridge === "undefined") {
            reject({ message: "请在微信客户端内访问页面" });
        } else {
            WeixinJSBridge.invoke(
                'getBrandWCPayRequest',
                {
                    appId: payInfo.appId, //动态获取初始化请求中返回的pay_info参数中appId值
                    timeStamp: payInfo.timeStamp, //动态获取初始化请求中返回的pay_info参数中timeStamp值
                    nonceStr: payInfo.nonceStr, //动态获取初始化请求中返回的pay_info参数中nonceStr值
                    package: payInfo.package,//动态获取初始化请求中返回的pay_info参数中package值
                    signType: payInfo.signType, //动态获取初始化请求中返回的pay_info参数中signType值
                    paySign: payInfo.paySign //动态获取初始化请求中返回的pay_info参数中paySign值
                },
                res => {
                    if (res.err_msg === "get_brand_wcpay_request:ok") {
                        resolve(true);
                    } else if (res.err_msg === "get_brand_wcpay_request:cancel") {
                        resolve(false);
                    } else {
                        reject({ message: res.err_msg || res.errMsg });
                    }
                });
        }
    })
}
/**
 * @description 通过uuid和code完成获取openid后创建支付单并调起微信支付等一系列动作完成支付
 * @param {*} param0 
 * @returns 
 */
export async function _wxPay({ uuid, code, appid }) {
    return new Promise((resolve, reject) => {
        _getWxOpenid({ appid, code }).then(openid => {
            _h5Pay({ uuid, openid }).then(payInfo => {
                _callWxPay(payInfo).then(resolve).catch(reject);
            }).catch(reject);
        }).catch(reject);
    });
}

// 加法
export function add(num1, num2) {
    num1 = num1 || "";
    num2 = num2 || "";
    let baseNum;
    let baseNum1;
    let baseNum2;
    try { baseNum1 = (num1.toString().split(".")[1] || "").length; } catch (e) { baseNum1 = 0; }
    try { baseNum2 = (num2.toString().split(".")[1] || "").length; } catch (e) { baseNum2 = 0; }
    baseNum = Math.pow(10, Math.max(baseNum1, baseNum2));
    return (mul(num1, baseNum) + mul(num2, baseNum)) / baseNum;
}
/**
 * @description 减法运算
 */
export function sub(num1, num2) {
    let baseNum;
    let baseNum1;
    let baseNum2;
    let precision;
    num1 = Number(num1) || 0;
    num2 = Number(num2) || 0;
    try { baseNum1 = (num1.toString().split(".")[1] || "").length; } catch (e) { baseNum1 = 0; }
    try { baseNum2 = (num2.toString().split(".")[1] || "").length; } catch (e) { baseNum2 = 0; }
    baseNum = Math.pow(10, Math.max(baseNum1, baseNum2));
    precision = (baseNum1 >= baseNum2) ? baseNum1 : baseNum2;
    return ((mul(num1, baseNum) - mul(num2, baseNum)) / baseNum).toFixed(precision);
}
/**
 * @description 乘法运算
 */
export function mul(num1, num2) {
    let baseNum = 0;
    num1 = num1 || "";
    num2 = num2 || "";
    try { baseNum += (num1.toString().split(".")[1] || "").length; } catch (e) { console.warn(e); }
    try { baseNum += (num2.toString().split(".")[1] || "").length; } catch (e) { console.warn(e); }
    return Number(num1.toString().replace(".", "")) * Number(num2.toString().replace(".", "")) / Math.pow(10, baseNum);
}
/**
 * @description 除法运算
 * @param num1 
 * @param num2 
 */
export function div(num1, num2) {
    let baseNum1 = 0;
    let baseNum2 = 0;
    let baseNum3;
    let baseNum4;
    num1 = num1 || "";
    num2 = num2 || "";
    try { baseNum1 = (num1.toString().split(".")[1] || "").length; } catch (e) { baseNum1 = 0; }
    try { baseNum2 = (num2.toString().split(".")[1] || "").length; } catch (e) { baseNum2 = 0; }
    baseNum3 = Number(num1.toString().replace(".", ""));
    baseNum4 = Number(num2.toString().replace(".", ""));
    return (baseNum3 / baseNum4) * Math.pow(10, baseNum2 - baseNum1);
}

