<template>
  <div class="reportWrapper">
    <div class="homeWrapper">
      <van-tabs type="line">
        <van-tab title="投诉">
          <van-form @submit="onSubmit">
            <van-field
              v-model="form.acceptContent"
              rows="2"
              autosize
              type="textarea"
              maxlength="200"
              placeholder="请在此填写您的投诉问题！"
              show-word-limit
            />
            <div
              class="updateImage"
              :max-count="3"
            >
              <van-uploader
                v-show="environment === 'ios'"
                :file-list="fileList"
                :after-read="afterRead"
                :before-delete="delteImg"
                :preview-full-image="true"
                v-model="fileList"
                multiple
                accept="image/*"
                :max-count="3"
              />

              <van-uploader
                v-show="environment === 'and'"
                :file-list="fileList"
                :after-read="afterRead"
                :before-delete="delteImg"
                :preview-full-image="true"
                v-model="fileList"
                accept="image/*"
                capture="camera"
                :max-count="3"
              />
            </div>
          </van-form>
        </van-tab>

      </van-tabs>

      <div
        class="submit"
        v-show="hideshow"
        @click="submitStatus"
      >马上提交</div>
      <van-popup
        v-model="show"
        :round="true"
        :style="{ height: '161.5px',width:'225px','text-align':'center',lineHeight:'161.5px' }"
        :close-on-click-overlay="false"
      >
        <div class="subMessage">
          <span style="color:#000;font-weight:bold">提交成功</span>
          <span>请耐心等待物业确认</span>
        </div>
        <div
          class="subSuccess"
          @click="resetComponentq"
        >关闭</div>
      </van-popup>
    </div>
  </div>
</template>

<script>

import wx from "weixin-js-sdk";
window.onunload = function () {
  wx.miniProgram.navigateBack({});
};
import * as api from "./http/api";
import * as vant from "vant";
import storage from "../../commons/storage";
export default {
  name: "home-maintenance",

  data() {
    return {
      show: false,
      fileList: [],
      form: {
        acceptContent: "",
        typeId: 1,
        levelid: 1,
        receptionModeId: 2,
        orderNumber: "",
        houseId: "",
        unitId: "",
        floorId: "",
        userName: "",
        isPublicArea: false,
        orderResources: [],
        communityExtId: "",
      },
      environment: "",
      active: null,
      docmHeight: document.documentElement.clientHeight, //默认屏幕高度
      showHeight: document.documentElement.clientHeight, //实时屏幕高度
      hideshow: true, //显示或者隐藏footer
    };
  },
  watch: {
    showHeight: function () {
      if (this.docmHeight > this.showHeight) {
        this.hideshow = false;
      } else {
        this.hideshow = true;
      }
    },
  },
  created() {
    storage.set(
      storage.keys.ACCESS_TOKEN,
      this.$route.fullPath.split("=")[1].split("&&")[0]
    );
    storage.set(
      storage.keys.CHOOSE_COMMUNITY,
      this.$route.fullPath.split("=")[2].split("&&")[0]
    );
  },
  mounted() {
    var u = navigator.userAgent;
    var num_sing1 = 0;
    var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1;
    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    console.log(isAndroid, isiOS);
    if (isiOS) {
      this.environment = "ios";
    } else {
      this.environment = "and";
    }

    window.onresize = () => {
      return (() => {
        this.showHeight = document.body.clientHeight;
      })();
    };

    console.log(this.$route.fullPath.split("=")[2].split("&&")[0]);
    this.form.communityExtId = this.$route.fullPath
      .split("=")[2]
      .split("&&")[0];

    this.getWorkOrderNumber();
    // 投诉随机拿一个房产
    this.getHouseRandom();
    this.getUserInfo(); // 获取用户信息
  },
  beforeDestroy() {
    localStorage.removeItem(storage.keys.ACCESS_TOKEN);
  },
  methods: {
    getUserInfo() {
      api.userInfo().then((res) => {
        console.log(res, "用户信息");
        this.form.userName = res.data.userAccountDetailsResponse.realName
          ? res.data.userAccountDetailsResponse.realName
          : res.data.userAccountDetailsResponse.nickName;
      });
    },
    getWorkOrderNumber() {
      api.getWorkorderNumber(this.form.communityExtId).then((res) => {
        this.form.orderNumber = res.data;
      });
    },
    getHouseRandom() {
      api.myhouse(this.form.communityExtId).then((res) => {
        console.log(res, "随机房产");
        this.form.houseId = res.data[0].houseId;
        this.form.floorId = res.data[0].floorId;
        this.form.manageAreaId = res.data[0].managerAreaId;
        this.form.unitId = res.data[0].unitId;
      });
    },
    onSubmit() {},

    resetComponentq() {
      this.show = false;
      this.form.acceptContent = "";
      this.active = null;
      this.form.orderResources = [];
      this.fileList = [];
    },
    resetComponent() {
      this.form.acceptContent = "";
      this.active = null;
      this.form.orderResources = [];
      this.fileList = [];
    },
    delteImg(event, detail) {
      console.log(
        detail,
        event,
        this.form.orderResources.splice(detail.index, 1)
      );
      return true;
    },
    afterRead(event) {
      console.log(event.file)
      this.fileList.map((v) => {
        if(!v.status){
          
        v.status = "uploading";
        v.message = "上传中";
        }
      });
      if (!Array.isArray(event)) {
        let dataSingle = new FormData();
        dataSingle.append("resource", event.file);
        api.uploadObject(dataSingle).then((res) => {
          if (res.success) {
            this.fileList.map((v) => {
              v.status = "done";
              v.message = "上传完成";
            });
            this.form.orderResources.push({
              url: res.data,
              thumbnails: res.data,
              type: "IMAGE",
              bizType: "COMMIT",
            });
          } else {
            this.fileList.map((v) => {
              if(v.status === 'uploading'){

              v.status = "failed";
              v.message = "上传失败";
              }
            });
          }
        });
      } else {
        event.forEach(async (v) => {
          let dataSom = new FormData();
          dataSom.append("resource", v.file);
          let data = await api.uploadObject(dataSom);
          if (data.success) {
            this.fileList.map((v) => {
              v.status = "done";
              v.message = "上传完成";
            });
            this.form.orderResources.push({
              url: data.data,
              thumbnails: data.data,
              type: "IMAGE",
              bizType: "COMMIT",
            });
          } else {
            this.fileList.map((v) => {
              if(v.status === 'uploading'){

              v.status = "failed";
              v.message = "上传失败";
              }
            });
          }
          // api.uploadObject(dataSom).then((res) => {
          //   if (res.success) {
          //     this.form.orderResources.push({
          //       url: res.data,
          //       thumbnails: res.data,
          //       type: "IMAGE",
          //       bizType: "COMMIT",
          //     });
          //   }
          // });
        });
      }

      // 当设置 mutiple 为 true 时, file 为数组格式，否则为对象格式

      // wx.uploadFile({
      //   url: "https://example.weixin.qq.com/upload", // 仅为示例，非真实的接口地址
      //   filePath: file.url,
      //   name: "file",
      //   formData: { user: "test" },
      //   success(res) {
      //     // 上传完成需要更新 fileList
      //     const { fileList = [] } = this.data;
      //     fileList.push({ ...file, url: res.data });
      //     this.setData({ fileList });
      //   },
      // });
    },
    submitStatus() {
      if (!this.form.acceptContent) {
        vant.Toast("内容不能为空");
        return;
      }
      api.createWork(this.form).then((res) => {
        this.form.orderNumber = "";

        this.getWorkOrderNumber();
        if (res.success) {
          this.show = true;

          //   Toast.fail("创建工单成功");
          this.resetComponent();
        } else {
          //   Toast.fail("创建工单失败");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.reportWrapper {
  width: 100%;
  height: 100%;
  padding: 0 20px;
  float: left;
}
/deep/ .van-tabs__nav {
  display: block;
  width: 100%;
}
/deep/ .van-tab {
  width: 64px;
  height: 17px;
  font-size: 17px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 17px;
  float: left;
  margin-right: 20px;
}

/deep/ .van-ellipsis {
  width: 60px;
  display: block;
  height: 44px;
  font-size: 17px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 44px;
}
/deep/ .van-tabs__line {
  background-color: #29c985;
}
/deep/.van-tab--active {
  /deep/ .van-ellipsis {
    color: #29c985;
  }
}

/deep/ .van-hairline--top-bottom::after {
  border-width: 0 0 !important;
}

.homeWrapper {
  margin: auto;
  height: 100%;
}
/deep/ .van-field {
  width: 335px;
  height: 140px;
  background: #f6f6f6;
  border-radius: 5px;
}
.groupPreset {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.groupPreset > div {
  padding: 4.5px 10px 4px;
  margin-right: 10px;
  margin-bottom: 15px;
  color: #999999;
  border: 0.5px solid #e3e3e3;
  border-radius: 13.5px;
}
.active {
  color: #29c985 !important;
  border-color: #29c985 !important;
  transition: 0.2s;
}
/deep/ .van-tabs__content {
  margin-top: 20px;
}
.updateImage {
  margin: 10px 0 24.5px 0;
}
.homeInfo {
  width: 100%;
}
.time,
.address {
  height: 49px;
  position: relative;
}
.time:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 1px;
  top: 0px;
  left: 0px;
  background: url(./image/Line.png) no-repeat;
}
.address::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 1px;
  top: 0px;
  left: 0px;
  background: url(./image/Line.png) no-repeat;
}
.address::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0px;
  left: 0px;
  background: url(./image/Line.png) no-repeat;
}
.label {
  height: 49px;
  line-height: 49px;
  float: left;
}
.submit {
  width: 335px;
  height: 41px;
  background: #29c985;
  border-radius: 20.5px;
  text-align: center;
  line-height: 41px;
  color: #fff;
  position: fixed;
  bottom: 15.5px;
}
.subSuccess {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background: #29c985;
  font-size: 18px;
  color: #fff;
}
.subMessage {
  width: 196px;
  height: 49px;
  margin: 45px auto 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  span {
    height: 20px;

    line-height: 20px;
  }
}
.timeValue {
  float: right;
  display: flex;
  align-items: center;
  height: 100%;
  img {
    width: 20px;
    height: 20px;
  }
}
/deep/ .van-field__body {
  height: 90%;
}
/deep/ .van-field__control {
  height: 100% !important;
}

html body /deep/ .van-field__word-limit {
  margin-bottom: 4px !important;
  margin-top: 0px !important;
}
</style>