<template>
    <div id="top">
        <img src="./images/header.png">
        <img style="width: 70%;margin-top:20%" src="./images/footer.png">
    </div>
</template>
<script>
    export default {
        data() {
            return {
            };
        },
    };
</script>
<style lang="less">
#top{
    text-align: center;
    img{
        background-size: cover;
        width:90%;
        margin: auto;
        height: auto;
    }
    
}
</style>
