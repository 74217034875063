const prefix = "LOCAL_STORAGE_";

function parse(data) {
    try {
        return JSON.parse(data);
    } catch (error) {
        return data;
    }
}

export default {
    keys: {
        COMMUNITY_ID: prefix + "COMMUNITY_ID",
        ACCESS_TOKEN: prefix + "ACCESS_TOKEN",
        REFRESH_TOEN: prefix + "REFRESH_TOEN",
        CHOOSE_COMMUNITY: "CHOOSE_COMMUNITY"
    },
    get: (key, str2empty = true) => {
        const data = window.localStorage.getItem(key);
        if(str2empty && data === "undefined") {
            return undefined;
        }
        if(str2empty && data === "null") {
            return null;
        }
        return parse(data);
    },
    set: (key, value) => {
        let data = value;
        if (typeof value === "object") {
            data = JSON.stringify(value);
        }
        window.localStorage.setItem(key, data);
    },
    clear: () => {
        window.localStorage.clear();
    }
}