<template>
    <div class="pages-pay-authorize">
        加载中...
    </div>
</template>

<script>
import { authorizeUrl } from "../../commons";
import { getUrlParam } from "../../commons/utils";

export default {
    name: "authorize",

    data() {
        return {};
    },

    mounted() {
        const sourceUrl = getUrlParam("sourceUrl");
        const code = getUrlParam("code");
        if (sourceUrl) { // 去认证，地址中需带着UUID
            const appId = getUrlParam("appId");
            window.localStorage.setItem("sourceUrl", sourceUrl);
            const redirectUrl = `${authorizeUrl}/pay/authorize`;
            window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appId + '&redirect_uri=' + encodeURIComponent(redirectUrl) + '&response_type=code&scope=snsapi_base&state=#wechat_redirect'
        } else if (code) { // 认证结束后跳转回原页面
            const url = window.localStorage.getItem("sourceUrl");
            window.localStorage.removeItem("sourceUrl");
            window.location.href = `${decodeURIComponent(url)}&code=${code}`;
        }
    }

};
</script>

<style lang="less" scoped>
.pages-pay-authorize {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}
</style>