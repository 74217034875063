<template>
    <div v-if="!initLoading" class="sqbj-merchant">
        <van-nav-bar v-show="false" left-arrow @click-left="goBack" />
        <div class="merchant">
            <img :src="form.imageUrl">
            <div class="merchant-title">
                <van-row class="merchant-title-desc">
                    <div>
                        <img :src="form.logoUrl">
                        <div>人气{{form.likeNum}}</div>
                    </div>
                    <div>
                        <div style="font-size: 20px;color: #333333;">
                            {{form.name}}
                        </div>
                        <div style="font-size: 14px;color: #999999;margin-top: 10px">
                            {{form.signText}}
                        </div>
                    </div>
                </van-row>
                <a :href="form.telphone ? 'tel:' + form.telphone : '#'">
                    <van-button class="goods-order" type="primary" :disabled="!form.telphone" @click="updateNum(form.id)">
                        <van-icon style="font-size:14px;margin-right:10px" name="phone" />立即预约
                    </van-button>
                </a>
            </div>
            <div class="merchant-divider"></div>
            <div class="merchart-tabs">
                <van-tabs v-model="tabkey">
                    <van-tab v-bind:key="index" v-for="index in 2" :title="index === 1 ? '服务项目' : '商家介绍' ">
                        <van-pull-refresh v-model="pullLoading" :disabled="moreLoading" @refresh="getList(0, true)" v-show="index === 1" class="merchart-goods">
                            <van-list :immediate-check="false" v-model="moreLoading" :finished="finished" @load="getList(index+1)">
                                <div :key="item.id" v-for="item in goodsList" @click="goGoodsPage(item.id)">
                                    <van-row class="goods-item">
                                        <van-col span="18">
                                            <van-row class="goods-item-title">
                                                <van-col span="14" class="ellipsis">{{item.name}}</van-col>
                                                <van-col span="10">
                                                    <span>{{item.price}}</span>元/{{item.unit}}
                                                </van-col>
                                            </van-row>
                                            <div class="gools-item-body">
                                                {{item.desc}}
                                            </div>
                                        </van-col>
                                        <van-col span="6">
                                            <img :src="item.gooldsImgUrl">
                                        </van-col>
                                    </van-row>
                                    <div class="van-hairline--bottom"></div>
                                </div>
                                <div class="no-data" v-show="goodsList.length>=count">已显示全部</div>
                            </van-list>
                        </van-pull-refresh>
                        <div v-show="index === 2" class="merchart-info">
                            <van-row class="merchart-info-cell">
                                店铺名称：
                                <span>{{form.name}}</span>
                            </van-row>
                            <div class="van-hairline--bottom"></div>
                            <van-row class="merchart-info-cell">
                                服务电话：
                                <span>{{form.telphone}}</span>
                            </van-row>
                            <div class="van-hairline--bottom"></div>
                            <van-row class="merchart-info-content rich-text" v-html="form.introduce"></van-row>
                        </div>
                    </van-tab>
                </van-tabs>
            </div>
        </div>
    </div>
    <van-loading v-else type="spinner" color="#1989fa" class="sqbj-loading" />
</template>

<script>
import "./refs/style.less";
import * as vant from "vant";
import * as api from "./refs/api";

const Toast = vant.Toast;
const { getGoodsListByMerchantId, getMerchant, updateMerchantConsultNum } = api;

export default {
    data() {
        return {
            tabkey: 0,
            form: {},
            index: 0,
            count: 0,
            goodsList: [],
            initLoading: false,
            pullLoading: false,
            moreLoading: false,
            finished: false
        };
    },
    async mounted() {
        try {
            const { id } = this.$route.params;
            if (!id) {
                return;
            }
            this.initLoading = true;
            this.form = await getMerchant(id);
            this.getList(0);
        } catch (e) {
            Toast(e.message || "获取数据失败！");
        } finally {
            this.initLoading = false;
        }
    },
    methods: {
        async getList(index, refresh) {
            
            try {
                if (this.goodsList.length >= this.count && index > 0) {
                    this.finished = true;
                    return;
                }
                const { id, communityId} = this.$route.params;
                const data = await getGoodsListByMerchantId(communityId, id, index);
                this.index = index;
                this.count = data.count;
                Toast.clear();
                if (refresh) {
                    this.finished = false;
                }
                this.goodsList = refresh
                    ? data.items
                    : this.goodsList.concat(data.items);
            } catch (e) {
                Toast(e.message || "获取数据失败！");
            } finally {
                this.moreLoading = false;
                setTimeout(() => {
                    this.pullLoading = false;
                }, 50);
            }
        },
        goBack() {
            this.$store.state.fade = "fade-right";
            this.$router.go(-1);
        },
        goGoodsPage(goodsId) {
            const { communityId} = this.$route.params;
            this.$router.push(`/business/goods/${goodsId}/${communityId}`);
        },
        updateNum(id) {
            try {
                updateMerchantConsultNum(id);
            } catch (e) {
                console.error(e);
            }
        }
    }
};
</script>

<style lang="less">
.sqbj-merchant > .van-nav-bar {
    position: fixed;
    .van-nav-bar__left {
        padding-right: 50px;
    }
}

.sqbj-merchant > .merchant > img {
    width: 100%;
    height: 165px;
}

.sqbj-merchant > .merchant > .merchant-title {
    padding: 0 20px;
    padding-bottom: 15px;
    > .merchant-title-desc {
        margin: 15px 0;
        position: relative;
    }
    > .merchant-title-desc > :first-child {
        position: absolute;
        top: -50px;
        > img {
            width: 75px;
            height: 75px;
            border-radius: 5px;
        }
        > div {
            padding: 2px 0;
            position: absolute;
            bottom: 3px;
            width: 100%;
            text-align: center;
            font-size: 11px;
            color: #ffffff;
            opacity: 0.53;
            background: #000000;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }
    > .merchant-title-desc > :last-child {
        margin-left: 85px;
        img {
            vertical-align: middle;
            margin-left: 5px;
            width: 18px;
            height: 18px;
        }
    }
}

.sqbj-merchant > .merchant > .merchant-divider {
    height: 8px;
    background: #f6f6f6;
}

.sqbj-merchant > .merchant > .merchart-tabs {
    .van-tab:active {
        background-color: #ffffff;
    }
    .van-tab > .van-ellipsis {
        color: #333333;
        font-size: 17px;
    }
    .van-tab--active > .van-ellipsis {
        color: #29c985;
    }
    .van-tabs__line::before,
    .van-tabs__line::after {
        content: "";
        border: 5px solid #ffffff;
        position: absolute;
        top: -3px;
        width: 65px;
    }
    .van-tabs__line::after {
        right: 0px;
    }
    .van-tabs__line {
        height: 3px;
        margin: 0 auto;
        bottom: 20px;
        background-color: #29c985;
    }
}

.sqbj-merchant > .merchant .merchart-info {
    padding: 0 20px;
    > .merchart-info-cell {
        padding: 15px 0;
        font-size: 15px;
        > span {
            color: #999999;
        }
    }
    > .merchart-info-content {
        color: #999999;
        padding: 15px 0;
        font-size: 15px;
    }
}

.sqbj-merchant > .merchant .merchart-goods {
    font-size: 14px;
    padding: 0 20px;
}

.sqbj-merchant > .merchant .merchart-goods .goods-item {
    color: #999999;
    padding: 15px 0;
    > :last-child {
        text-align: right;
    }
    > div > img {
        width: 70px;
        height: 70px;
        border-radius: 5px;
    }
    .goods-item-title {
        color: #333333;
        font-size: 16px;
        > :last-child {
            text-align: right;
        }
        span {
            color: #ff0000;
        }
    }
    .gools-item-body {
        line-height: 21px;
        margin-top: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 2;
    }
}

.sqbj-merchant .van-button.goods-order {
    height: 40px;
    font-size: 18px;
    width: 100%;
}
.sqbj-merchant .van-button--primary.goods-order:not(.van-button--disabled) {
    border: 0;
    background: #29c985;
}
</style>
