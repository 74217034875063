import * as $commons from "../../../commons/index";

const url = $commons.SERVICE_BUSINESS_API;
const agent = $commons.agent;
// typeId 居家/公区都是是2 信息咨询是5 建议建言6 问题投诉1
// 投诉 levelid是3 其他2
// receptionModeId: 2,

// 获取用户认证所有小区
export async function getAuthCommunity(id, serialNumber) {
    try {
        const data = await agent({
            url: $commons.API_USER_URL + `/community/bind?isPro=true`,
            method: "GET",
            data: '',
        });
        return data;
    } catch (error) {
        throw error;
    }
}

// 获取用户房产信息

export async function getHomeInfo() {
    try {
        const data = await agent({
            url: $commons.API_USER_URL + `/family/user/house`,
            method: "GET",
            data: '',
        });
        return data;
    } catch (error) {
        throw error;
    }
}


// 获取工单单号
export async function getWorkorderNumber(id) {
    try {
        const data = await agent({
            url: $commons.API_WORK_URL + `/business-order/getOrderNumber?communityId=${id}`,
            method: "GET",
            data: '',
        });
        return data;
    } catch (error) {
        throw error;
    }
}

// 创建工单

export async function createWork(form) {
    try {
        const data = await agent({
            url: $commons.API_WORK_URL + `/business-order/custom-create`,
            method: "POST",
            data: form,
        });
        return data;
    } catch (error) {
        throw error;
    }
}
// /timeout-settings/custom-detail 时间范围

export async function settings(communityExtId) {
    try {
        const data = await agent({
            url: $commons.API_WORK_URL + `/settings/custom-detail?communityExtId=${communityExtId}`,
            method: "GET"
        });
        return data;
    } catch (error) {
        throw error;
    }
}

/**
 * {params} pageIndex:0,
            pageSize:10,
            communityExtId:""
 * **/

export async function customList(params) {
    try {
        const data = await agent({
            url: $commons.API_WORK_URL + `/business-order/custom-list`,
            method: "GET",
            params
        });
        return data;
    } catch (error) {
        throw error;
    }
}

//  获取保修详情

export async function customDetail(id) {
    try {
        const data = await agent({
            url: $commons.API_WORK_URL + `/business-order/custom-detail/${id}`,
            method: "GET"
        });
        console.log(data,"-0-0")
        return data;
    } catch (error) {
        throw error;
    }
}


// 客户评分 (管家)
export async function scoreClient(datas) {
    try {
        const data = await agent({
            url: $commons.API_WORK_URL + `/manager/score`,
            method: "PUT",
            data:datas
        });
        return data;
    } catch (error) {
        throw error;
    }
}

// 结单后评价
export async function score(datas) {
    try {
        const data = await agent({
            url: $commons.API_WORK_URL + `/business-order/custom-return-visit`,
            method: "PUT",
            data:datas
        });
        return data;
    } catch (error) {
        throw error;
    }
}
// 微信签名配置
export async function wxConfigInfo(url) {
    try {
        const data = await agent({
            url: $commons.API_TOOL_URL + `/wechat/sign?url=${url}`,
            method: "get"
        });
        return data;
    } catch (error) {
        throw error;
    }
}
// /type-content-sample/sample?type=WORK_ORDER&topTypeId=1&communityExtId=41756 获取样例
export async function sample(id) {
    try {
        const data = await agent({
            url: $commons.API_WORK_URL + `/type-content-sample/sample?type=WORK_ORDER&topTypeId=2&communityExtId=${id}`,
            method: "get"
        });
        return data;
    } catch (error) {
        throw error;
    }
}
// 

export async function myhouse(id) {
    try {
        const data = await agent({
            url: $commons.API_COMMON_URL + `/myfamily/myhouse?communityExtId=${id}`,
            method: "get"
        });
        return data;
    } catch (error) {
        throw error;
    }
}
// 获取公区报事的公共区域
export async function customListPublic(id) {
    try {
        const data = await agent({
            url: $commons.API_COMMON_URL + `/projects/custom-list?communityExtId=${id}`,
            method: "get"
        });
        let result = filterArray(data.data)
        console.log(result,"----------------------")
        return result;
    } catch (error) {
        throw error;
    }
}

// 获取用户信息
export async function userInfo() {
    try {
        const data = await agent({
            url: $commons.AUTH_SERVICE_URL +`/user/detail?type=ALL`,
            method: "get",
        });
        return data;
    } catch (error) {
        throw error;
    }
}

// 上传图片
export async function uploadObject(datas) {
    try {
        const data = await agent({
            url: $commons.API_TOOL_URL + `/oss/uploadObject?objectType=PROPERTY&usageType=REPAIR`,
            method: "post",
            data:datas,
            headers:{
                "Content-Type": "multipart/form-data"
            }
        });
        console.log(data,"----------------------")
        return data;
    } catch (error) {
        throw error;
    }
}
// business-order/custom-close 取消工单

export async function cancelOrder(datas) {
    try {
        const data = await agent({
            url: $commons.API_WORK_URL + `/business-order/custom-close`,
            method: "put",
            data:datas,
        });
        return data;
    } catch (error) {
        throw error;
    }
}
const filterArray = (array=[],type)=>{
    let arr = []
     array.map(v=>{
         if(type=== 'floor'){
            
            
            v.value = v.manageAreaId;
            v.text = v.floorNo + v.floorName
         }else if(type == 'floorUnit'){
            v.value = v.id;
            v.text = v.unitNo+v.unitName
         }else{
            
        v.text = v.areaName;
        v.value = v.id;
         }
        if(v.floorInfoList && v.floorInfoList.length>0){
            v.children = filterArray(v.floorInfoList,'floor')

        }
        if(v.floorUnitInfoResList && v.floorUnitInfoResList){
            v.children = filterArray(v.floorUnitInfoResList,'floorUnit')
        }
        arr.push(v)
    })
    return arr
}
