import * as utils from "./utils";
export { utils };
export { default as agent } from "./agent";
export { default as storage } from "./storage";

export const SERVICE_BUSINESS_API = "/business/api";
export const SERVICE_AUTH_API = "/business-authentication/api";
export const SERVICE_BPP_API = "/bpp/api";
export const SERVICE_BIGDATA_API = "/bigdata/api";
export const API_USER_URL = "/user/api"
export const API_COMMON_URL = '/commonData/api'
export const API_WORK_URL = "/workorder/api";
export const API_TOOL_URL = "/tool/api";
export const AUTH_SERVICE_URL = "/authentication/api";
export const GROUP_SERVICE_URL = "/group/finance/api";
export const PAY_API = "/pay/api";
export const AMAP_STYLE = "amap://styles/f1a24f2a154e6038df0e0cdb677b8c22";

const source = window.location.origin.indexOf("xiaobeifuwu") > -1 ? "XB" : "SQBJ";
// const source = "XB";
function origin() {
    if (source === "XB") {
        return "https://portal.xiaobeifuwu.com";
    } else {
        if (process.env.NODE_ENV === "development") {
            return "http://dev.restful.api.sqbj.com";
        } else {
            return "https://restful.sqbj.com";
        }
    }
}

export const baseUrl = origin();
export const authorizeUrl = source === "XB" ? "https://portal.xiaobeifuwu.com/h5" : "https://html.sqbj.com";
export const wxAppId = source === "XB" ? "wxe3ae724adc2b2020" : "wxc85a510cbef7f60b";
export const aliAppId = source === "XB" ? "2021004105694126" : "2021003129693092";
console.log("环境信息：", source, baseUrl, wxAppId, aliAppId)