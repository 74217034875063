import Vue from "vue";
import Vant from "vant";
import App from "./App.vue";
import VueAMap from 'vue-amap';
import router from "./router";
import store from "./store";
import "lib-flexible/flexible";
import "vant/lib/index.css";
import 'ant-design-vue/dist/antd.css';
import "./commons/WebViewJavascriptBridge";
import Cube from 'cube-ui'
import wx from "weixin-js-sdk"

// 需要vconsole请自行安装，提交dev发布前卸载
// import vConsole from 'vconsole'
// Vue.prototype.$vConsole = new vConsole()


Vue.use(Cube)
Vue.use(Vant);
Vue.use(VueAMap);
Vue.config.productionTip = false;
Vue.prototype.$wx = wx;
Vue.config.ignoredElements = ['wx-open-launch-weapp']
VueAMap.initAMapApiLoader({
    key: '71880e680e9f1fcf4fe476f7b1378fa0',
    plugin: ['ControlBar', 'ToolBar', 'Scale', 'Geolocation', 'MapType', 'AMap.CircleEditor'],
    v: '1.4.15',
    uiVersion: '1.0.11' 
});

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount("#app");