<template>
    <div v-if="!initLoading" class="sqbj-goods">
        <van-nav-bar v-show="false" left-arrow title="商品详情" @click-left="goBack" />
        <div class="goods">
            <img :src="form.gooldsImgUrl">
            <div class="goods-title">
                <van-row class="goods-title-cell" style="padding: 11px 0px">
                    <van-col span="24" style="font-size: 20px;color: #333333;">{{form.name}}</van-col>
                </van-row>
                <div class="van-hairline--bottom"></div>
                <van-row class="goods-title-cell" style="padding: 12px 0px">
                    <van-col span="24" class="goods-merchant" style="padding-top: 3px;">
                        <img :src="form.merchantLogoUrl">
                        <span @click="goMerchantPage(form.merchantId)">{{form.merchantName}}</span>
                    </van-col>
                </van-row>
                <div class="van-hairline--bottom"></div>
                <van-row class="goods-title-cell">
                    <van-col span="14" style="font-size: 16px;color: #333333;">
                        <span style="color: #FF0000 ">{{form.price}}</span>元/{{form.unit}}
                    </van-col>
                    <van-col span="10" style="text-align: right;font-size: 12px; color: #999999;line-height: 1.7;">
                        人气： {{form.likeNum}}
                    </van-col>
                </van-row>
                <div class="van-hairline--bottom"></div>
                <van-row class="goods-title-cell">服务时间：{{form.service}}</van-row>
                <div class="van-hairline--bottom"></div>
            </div>
            <div class="goods-content rich-text" v-html="form.desc"></div>
            <a :href="form.telphone ? 'tel:' + form.telphone : '#'">
                <van-button class="goods-order" type="primary" :disabled="!form.telphone" @click="updateNum(form.id)">立即预约</van-button>
            </a>
        </div>
    </div>
    <van-loading v-else type="spinner" color="#1989fa" class="sqbj-loading" />
</template>

<script>
import "./refs/style.less";
import * as vant from "vant";
import * as api from "./refs/api";

const Toast = vant.Toast;

export default {
    data() {
        return {
            initLoading: false,
            form: {}
        };
    },
    async mounted() {
        try {
            const { id } = this.$route.params;
            if (!id) {
                return;
            }
            this.initLoading = true;
            this.form = await api.getGoods(id);
        } catch (e) {
            Toast(e.message || "获取数据失败！");
        } finally {
            this.initLoading = false;
        }
    },
    methods: {
        goBack(key) {
            this.$store.state.fade = "fade-right";
            this.$router.go(-1);
        },
        goMerchantPage(merchantId) {
            const { communityId } = this.$route.params;
            this.$router.push(`/business/merchant/${merchantId}/${communityId}`);
        },
        updateNum(id) {
            try {
                api.updateGoodsConsultNum(id);
            } catch (e) {
                console.error(e);
            }
        }
    }
};
</script>

<style lang="less">
.sqbj-goods > .van-nav-bar {
    position: fixed;
    width: 100%;
}
.sqbj-goods .goods {
    color: #595959;
    font-size: 15px;
    // padding: 50px 20px 65px 20px;
    padding: 0 20px 65px 20px;
    > img {
        width: 100%;
        height: 150px;
        margin-top: 10px;
        border-radius: 5px;
    }
    > .goods-title > .goods-title-cell {
        padding: 15px 0;
    }
    > .goods-content {
        margin-top: 10px;
    }
}
.sqbj-goods .van-button.goods-order {
    height: 40px;
    font-size: 18px;
    width: calc(~"100% - 40px");
    position: fixed;
    bottom: 20px;
    left: 20px;
}
.sqbj-goods .van-button--primary.goods-order:not(.van-button--disabled) {
    border: 0;
    background: #29c985;
}
</style>
