<template>
    <div class="sqbj-amap-country">
        <el-amap ref="map" mapStyle="amap://styles/a2ce5ffae680aa080b96faee69775d0e"
            :zoom="4"
            :events="events">
            <el-amap-marker 
                v-bind:key="index" 
                v-for="(m, index) in markers" 
                :events="m.events" 
                :position="m.center" 
                :icon="marker && marker.id === m.id ? m.selectedIcon: m.icon" 
                :offset="marker && marker.id === m.id ? m.selectedOffset: m.offset" ></el-amap-marker>
            <el-amap-info-window v-if="!!marker" :bubble="true" :position="marker.center" :offset="[0,0]">
                <div class="sqbj-info-tips">
                    <p><span>小区名称：</span>{{marker.name}}</p>
                    <p><span>事件内容：</span>{{marker.content}}</p>
                    <p><span>事件类型：</span>{{textMap[marker.type]}}</p>
                    <p><span>事件时间：</span>{{getTime(marker.eventTime)}}</p>
                    <p><span>是否处理：</span>{{marker.finished ? "已处理": "未处理"}}</p>
                </div>
            </el-amap-info-window>
        </el-amap>
    </div>
</template>
<script>

import * as $commons from '../../commons/index';
import * as moment from "moment";

let map = null;
let interval = null;
let markerSelected = false;
let datasource = [
    {
        id: 1,
        name: "和泓·彩虹城",
        center: [116.415465, 39.839702],
        content: "马婷婷提交建议",
        type: "SUGGEST",
        finished: false, 
        eventTime: 1576051419000,
        dealTime: null, 
    },
    {
        id: 2,
        name: "和泓·金和温州城",
        center: [124.339385, 40.013959],
        content: "王小红提交建议",
        type: "SUGGEST",
        finished: false, 
        eventTime: 1576051370000,
        dealTime: null, 
    },
    {
        id: 3,
        name: "和泓·山水阳光",
        center: [127.445607, 45.745072],
        content: "邓红提报了一个工单",
        type: "REPAIR",
        finished: false, 
        eventTime: 1576051419000,
        dealTime: null, 
    },
    {
        id: 4,
        name: "和泓·国际花园",
        center: [123.398789, 41.735854],
        content: "李欣提报了一个工单",
        type: "REPAIR",
        finished: false, 
        eventTime: 1576051419000,
        dealTime: null, 
    },
        {
        id: 5,
        name: "和泓·天海青城",
        center: [106.680006, 26.551984],
        content: "白凌雯提报了一个工单",
        type: "REPAIR",
        finished: false, 
        eventTime: 1576051419000,
        dealTime: null, 
    },
    {
        id: 6,
        name: "和泓·梅溪四季",
        center: [112.883241, 28.189832],
        content: "赵海滨提报了一个工单",
        type: "REPAIR",
        finished: false, 
        eventTime: 1576051419000,
        dealTime: null, 
    },
    {
        id: 7,
        name: "和泓·假日阳光",
        center: [109.616149, 18.312179],
        content: "厉欣提报了一个工单",
        type: "REPAIR",
        finished: false, 
        eventTime: 1576051419000,
        dealTime: null, 
    },{
        id: 8,
        name: "和泓·东二八",
        center: [104.130739, 30.67615],
        content: "业主丁女士家漏水",
        type: "ALARM",
        finished: false, 
        eventTime: 1576051419000,
        dealTime: null, 
    },{
        id: 9,
        name: "和泓·北岸嘉园",
        center: [118.212555, 39.690753],
        content: "业主张玲被盗",
        type: "ALARM",
        finished: false, 
        eventTime: 1576051419000,
        dealTime: null, 
    },{
        id: 10,
        name: "和泓阳光（重庆）",
        center: [106.547263, 29.513795],
        content: "马平提交告警",
        type: "ALARM",
        finished: false, 
        eventTime: 1576051419000,
        dealTime: null, 
    }
]

const areaId = 10385;
const tenantId = "X0001";
const secretKey = "kDrYFMCLYkShRW1syWHnVDGxah1jOafx5bZ4SsIcQ8o6FVd0RgW4smTNwhp7BKhZ96lkWo9ya6xVUYaOTiIhAyFKjLkg7LIJ0IADwthd6xhZxfqLumYKZ2j4ujjG2VsWEjU6DooOVma0Gr9tifDHshvIEDtQgQhsCd/USQV9A7g=";

const textMap = {
    ALARM: "告警",
    REPAIR: "报修",
    COMPLAIN: "投诉",
    SUGGEST: "建议",
    CONSULTATION: "咨询", 
}

export default {
    data() {
        return {
            textMap: textMap,
            markers: [],
            marker: null,
            events: {
                init: async () => {
                    const that = this;
                    map = this.$refs.map.$$getInstance();
                    map.setFeatures(['bg', 'road']);
                    datasource.map(x => {
                        const path = require(`./imgs/event-${x.type.toLowerCase()}.png`);
                        x.icon = createIcon(path, 20);
                        x.selectedIcon = createIcon(path, 30);
                        x.offset = [-10,-10];
                        x.selectedOffset = [-15,-15];
                        x.events = {
                            mouseover: function() {
                                markerSelected = true;
                                that.marker = x;
                            },
                            mouseout: function() {
                                markerSelected = false;
                                that.marker = null;
                            }
                        }
                    })
                    // this.markers = datasource;
                    // this.marker = datasource[6];
                    // return;
                    const fn = () => {
                        clearInterval(interval);
                        this.marker = null;
                        const count = parseInt(Math.random() * 4, 10) + 5;
                        const markers = getRandomArrayElements(datasource, count);
                        this.markers = markers;
                        let index = 0;
                        interval = setInterval(() => {
                            if(markerSelected) {
                                return;
                            }
                            if(index > count - 1) {
                                index = 0;
                            }
                            this.marker = markers[index];
                            index++ ;
                        }, 3000);
                    }
                    setTimeout(() => {
                        fn();
                        setInterval(fn, 60 * 1000);
                    }, 0);
                }
            },
        }
    },
    methods: {
        getTime(t) {
            return !t ? '': moment(t).format("YYYY-MM-DD HH:mm");
        },
    },
    computed: {
       _marker_events: function() {
           return function(marker) {
               return {

               }
           }
       }
    },
    // watch: {
    //     "marker.id": {
    //         handler(n, o) {
    //             const marker = this.markers.find(x => x.id === n);
                
    //         },
    //         deep: true,
    //         immediate: true
    //     }
    // }

}

function createIcon(path, radius) {
    return new AMap.Icon({
        size: new AMap.Size(radius, radius),
        image: path,
        imageSize: new AMap.Size(radius, radius),
    });
}

function getRandomArrayElements(arr, count) {
    var shuffled = arr.slice(0), i = arr.length, min = i - count, temp, index;
    while (i-- > min) {
        index = Math.floor((i + 1) * Math.random());
        temp = shuffled[index];
        shuffled[index] = shuffled[i];
        shuffled[i] = temp;
    }
    return shuffled.slice(min);
}

/**
 * 获取图层数据
 */
async function _data(areaId, tenantId, secretKey) {
    let result = [];
    // const response = await $commons.agent({
    //     method: "GET",
    //     baseURL: $commons.SERVICE_BIGDATA_API,
    //     url: `area-map/events?areaId=${areaId}&tenantId=${tenantId}&secretKey=${secretKey}&pageSize=10&eventTypes`
    // });
    // if(response.data && response.data.length) {
    //     response.data.forEach(x => {
    //         const centers = x.areaLocation.split(";");
    //         result.push({
    //             id: x.id,
    //             name: x.areaName,
    //             center: centers,
    //             content: x.content,
    //             type: x.type,
    //             finished: x.isDeal === "YES", // 已处理
    //             eventTime: x.eventTime, // 事件事件
    //             dealTime: x.dealTime, // 处理时间
    //         })
    //     })
    // }
    return datasource;
}

</script>
<style lang="less">
.sqbj-amap-country {
    width: 100%;
    height: 100%;
}
// .sqbj-amap-country .bottom-center {
//     .amap-info-content {
//         border-radius: 5px;
//         padding: 5px 12px;
//         font-size: 12px;
//         color: #C3E5F9;
//         border: 1px solid #009DFF;
//         background-color: rgba(1,22,81, 0.4);
//         .amap-info-close {
//             display: none;
//         }
//         .sqbj-info-tips > p {
//             margin: 5px 0;
//         }
//     }
//     >.amap-info-sharp {
//         border-top: 8px solid #009DFF;
//     }
// }

</style>