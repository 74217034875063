<template>
	<div v-if="state === 'loading'" class="page-pay-communityQrcode">
		<div class="tip">正在加载</div>
	</div>
	<div v-else class="page-pay-communityQrcode">
		<div class="icon">!</div>
		<div class="tips">无支付信息<br />您的账单未生成或已支付！</div>
	</div>
</template>
<script>
import agent from "../../commons/agent";
import { baseUrl } from "../../commons/index";
export default {
	data() {
		return {
			state: "loading",
		};
	},
	mounted() {
		const uuid = this.$route.query.uuid;
		// 查找最新的预付单
		agent.get(`${baseUrl}/group/finance/api/qrcode/last/${uuid}`).then(res => {
			if (res.data) {
				agent.get(`${baseUrl}/group/finance/api/qrcode/query/${res.data}`).then(res => res.data).then(({ orderStatus }) => {
					if (orderStatus === "PAYING") {
						window.location.href = window.location.href.split("?")[0].replace("shequbanjing", "sqbj").replace("communityQrcode", "payWay") + `?uuid=${res.data}`;
					} else {
						console.warn(4, "订单不在等待支付状态");
						this.state = "fail";
					}
				}).catch(e => {
					console.warn(3, e.message);
					this.state = "fail";
				})
			} else {
				console.warn(2, "uuid为空");
				this.state = "fail";
			}
		}).catch(e => {
			console.warn(1, e.message);
			this.state = "fail";
		});
	},
};
</script>
<style lang="less">
.page-pay-communityQrcode {
	background: #f6f6f6;
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	row-gap: 30px;

	.icon {
		background: red;
		width: 100px;
		height: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		color: #fff;
		font-size: 50px;
		font-weight: bolder;
	}

	.tips {
		font-size: 18px;
		line-height: 1.5em;
		text-align: center;
	}
}
</style>