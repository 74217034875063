<template>
  <div class="wrapper">
    <div v-show="!loading" class="head_url">
      <img style="border:0" :src="results.avatar" alt="">
    </div>
    <div v-show="!loading" class="head_nickName">{{ results.nickName }}</div>
    <!-- <div>{{params}}</div>
    <div>ip-{{ip}}</div>
    <div>message-{{messages}}</div>
    <div>errorCahe-{{errorCahe}}</div>
    <div>results-{{results}}</div> -->
    <van-loading v-show="loading" style="position:absolute;top:0;bottom:0;left:0;right:0;margin:auto" color="#1989fa" />
  </div>
</template>
<style lang="less" scoped>
.wrapper {
  width: 100%;
  height: 100%;
  background: rgba(12, 23, 56, 0.77);

  .head_url {
    width: 100%;
    height: 80px;
    position: relative;
    margin-bottom: 10px;

    img {
      width: 60px;
      height: 60px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  .head_nickName {
    font-size: 16px;
    color: #a6a8b7;
    width: 100%;
    text-align: center;
    font-family: PingFang SC;
    font-weight: 600;

  }


}

/deep/ .van-loading__spinner {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  margin: auto !important;
}

html body .wrapper {
  /deep/ .van-loading {
    color: #ddd;

    /deep/ .van-loading__spinner {
      position: absolute !important;
      top: 0 !important;
      right: 0 !important;
      left: 0 !important;
      bottom: 0 !important;
      margin: auto !important;
    }
  }
}
</style>
<script src="http://pv.sohu.com/cityjson?ie=utf-8"></script>
<script>
import { aliPays } from "./api";
import axios from "axios";
export default {
  data() {
    return {
      params: {
        auth_code: "",
        uuid: undefined,
      },
      loading:true,
      ip: undefined,
      errorCahe: "",
      results: {},
      messages:""
    };
  },
   created(){


    this.params = this.$route.query;
    // this.getIp("https://pv.sohu.com/cityjson?ie=utf-8");
    const onScript = document.createElement("script");
    onScript.type = "text/javascript";
    onScript.src =
      "https://gw.alipayobjects.com/as/g/h5-lib/alipayjsapi/3.1.1/alipayjsapi.min.js";
    document.body.appendChild(onScript);
  },
  mounted() {
    this.aliPay()
  },
 
  methods: {
    async aliPay() {
      try {
        // if (!this.ip) {
        //   this.ip("https://pv.sohu.com/cityjson?ie=utf-8");
        // }
        this.messages = JSON.stringify({
          alipayAuthCode: this.params.auth_code,
          mchCreateIp: this.ip,
          uuid: this.params.uuid,
        })
        // console.log()
        let result = await aliPays({
          alipayAuthCode: this.params.auth_code,
          mchCreateIp: this.ip,
          uuid: this.params.uuid,
        });
        this.results = result.data;
        this.loading = false;
        AlipayJSBridge.call("tradePay", { tradeNO: result.data.tradeNO },(resultCode)=>{
          if( resultCode !== 8000){
            // window.location.href = "alipays://platformapi/startapp?appId=20000003"
            AlipayJSBridge.call('closeWebview');
            window.open("alipays://platformapi/startapp?appId=20000003","_self")
          }
        });
      } catch (error) {
        this.errorCahe = JSON.stringify(error);
      }
    },

    // async getIp(src) {
    //   try {
    //     let script = document.createElement("script");
    //     script.setAttribute("type", "text/javascript");
    //     script.src = src;
    //     document.body.appendChild(script);
    //     setTimeout(()=>{
    //     console.log(returnCitySN.cip);
    //     this.ip = returnCitySN.cip;

    //   this.aliPay()
    //     },500)
    //   } catch (error) {
    //     this.errorCahe = JSON.stringify(error);
    //   }
    // },
  },
};
</script>