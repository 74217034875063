import * as $commons from "../../../commons/index";

const url = $commons.SERVICE_BUSINESS_API;
const agent = $commons.agent;

/**
 * @desc 获取商品分类
 * @param {小区Id}} communityId 
 */
function getCategoryList(communityId) {
    return agent.get(`${url}/goods/query/homepage-tag/${communityId}`)
        .then(r => {
            const items = [];
            const hotList = r.data.hotTagList || [];
            const classifyList = r.data.topTagList || [];
            if (hotList && hotList.length) {
                items.push({
                    id: -1,
                    name: "热门",
                    subList: hotList.map(o => ({
                        id: o.id,
                        name: o.tagName,
                        url: o.iconUrl
                    })),
                })
            }
            if (classifyList && classifyList.length) {
                classifyList.map(o => {
                    items.push({
                        id: o.id,
                        name: o.tagName,
                        subList: (o.secondGoodsTagList || []).map(o => ({
                            id: o.id,
                            name: o.tagName,
                            url: o.iconUrl
                        })),
                    })
                })
            }
            return items;
        });
}

/**
 * 按分类获取商品列表
 * @param {分类Id} classifyId 
 * @param {页码} index 
 * @param {每页条数}}} size 
 */
function getGoodsList(classifyId, index, size) {
    const params = {
        pageIndex: index || 0,
        pageSize: size || 20,
    }
    return agent.get(`${url}/goods/query/tagId/${classifyId}`, {
        params
    }).then(r => ({
        count: r.totalCount,
        items: (r.listData || []).map(o => ({
            id: o.id,
            name: o.goodsName,
            gooldsImgUrl: o.goodsImage,
            price: o.price,
            unit: o.priceUnit,
            desc: o.goodsAbstract,
            merchantId: o.merchantId,
            merchantLogoUrl: o.merchantLogo,
            merchantName: o.merchantName,
            likeNum: o.popularityNumber,
        }))
    }));
}

/**
 * 根据Id获取商品
 * @param {商品Id} id 
 */
function getGoods(id) {
    return agent.get(`${url}/goods/query/detail/${id}`).then(r => r.data && ({
        id: r.data.id,
        name: r.data.goodsName,
        gooldsImgUrl: r.data.goodsHead,
        price: r.data.price,
        unit: r.data.priceUnit,
        service: `${r.data.dayStart || ""}至${r.data.dayEnd|| ""} ${r.data.openAt|| ""}-${r.data.closeAt|| ""}${r.data.isDeliver === "YES" ? "（可上门)" : ""}`,
        telphone: r.data.merchantPhone,
        desc: r.data.goodsDesc,
        merchantId: r.data.merchantId,
        merchantLogoUrl: r.data.merchantLogo,
        merchantName: r.data.merchantName,
        likeNum: r.data.popularityNumber,
    }));
}

/**
 * 获取商家信息
 * @param {商家Id} id 
 */
function getMerchant(id) {
    return agent.get(`${url}/merchant/query/detail/${id}`).then(r => r.data && ({
        id: r.data.id,
        name: r.data.name,
        likeNum: r.data.popularityNumber,
        signText: r.data.slogan,
        imageUrl: r.data.merchantBanner,
        logoUrl: r.data.merchantHead,
        telphone: r.data.serviceTel,
        phoneText: r.data.serviceTel + (r.data.serviceTel2 ? "/" + r.data.serviceTel2 : ""),
        introduce: r.data.description
    }));
}

/**
 * 根据商家Id获取商品列表
 * @param {商家Id} merchantId 
 * @param {页码} index 
 * @param {每天条数} size 
 */
function getGoodsListByMerchantId(communityId, merchantId, index, size) {
    const params = {
        merchantId,
        communityId,
        pageIndex: index || 0,
        pageSize: size || 20,
    }
    return agent.get(`${url}/goods/query/list`, {
        params
    }).then(r => ({
        count: r.totalCount,
        items: (r.listData || []).map(o => ({
            id: o.id,
            name: o.goodsName,
            gooldsImgUrl: o.goodsImage,
            price: o.price,
            unit: o.priceUnit,
            desc: o.goodsAbstract,
        }))
    }));
}

/**
 * 更新商家咨询量
 * @param {商家Id} merchantId 
 */
function updateMerchantConsultNum(merchantId) {
    return agent.put(`${url}/merchant/update/consult-number/${merchantId}`);
}

/**
 * 更新商品咨询量
 * @param {商品Id} goodsId 
 */
function updateGoodsConsultNum(goodsId) {
    return agent.put(`${url}/goods/update/consulting-number/${goodsId}`);
}

export {
    getCategoryList,
    getGoodsList,
    getGoods,
    getMerchant,
    getGoodsListByMerchantId,
    updateMerchantConsultNum,
    updateGoodsConsultNum,
};