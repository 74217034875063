<template>
  <div class="homeWrapper">
    <van-form @submit="onSubmit">
      <van-field
        v-model="acceptContent"
        rows="2"
        @focus="focuseInput"
        autosize
        type="textarea"
        maxlength="200"
        :placeholder="title === 'home' ? '请在此填写您遇到的问题，也可选择下方预置 内容，快速提报！' : '请在此填写您遇到的问题!'"
        show-word-limit
      />

      <div
        class="groupPreset"
        v-show="title === 'home'"
      >
        <div
          v-for="(item,index) in systemPreset"
          :class="activeGroup.indexOf(item)!== -1? 'active' : ''"
          :key="index"
          @click="tapPreset($event,item)"
        >
          {{item}}
        </div>
      </div>

      <div
        class="updateImage"
        :after-read="afterRead"
        :max-count="3"
      >
        <!-- 如果有上传的图片了 -->
        <!-- <div
          class="imgUp"
          v-for="(item) in form.orderResources"
          :key="item.url"
        >
          <div class="shellImg">
            <img
              :src="item.url"
              alt=""
            >
            <div
              class="closeImg"
              @click="handleCloseImage"
            ></div>
          </div>

        </div> -->
        <!-- <div
          class="upLoad"
          @click="wxUpload"
        >
          上传图片
        </div> -->
        <van-uploader
          v-show="environment === 'ios'"
          :file-list="fileList"
          :after-read="afterRead"
          :before-delete="delteImg"
          :preview-full-image="true"
          v-model="fileList"
          multiple
          accept="image/*"
          :max-count="3"
        />

        <van-uploader
          v-show="environment === 'and'"
          :file-list="fileList"
          :after-read="afterRead"
          :before-delete="delteImg"
          :preview-full-image="true"
          v-model="fileList"
          accept="image/*"
          capture="camera"
          :max-count="3"
        />

        <!-- <input name="photo" id='photo' accept="image/*" type='file' capture="camera" /> -->
      </div>

      <div
        v-if="loading"
        class="homeInfo"
      >
        <div class="time">
          <div class="label">期望上门时间段</div>
          <div
            class="timeValue"
            @click="showTimePicker"
          >
            <div class="control">
              {{formText.selectedText}}
            </div>
            <img
              src="./image/cells_indicator.png"
              alt=""
            >
          </div>
        </div>
        <div class="address">
          <div class="label">{{title === 'home'? '地址房号' : '公共区域'}}</div>
          <div
            class="timeValue"
            @click="showSetDataPicker()"
          >

            <div class="control">
              {{formText.addressNo}}
            </div>

            <img
              src="./image/cells_indicator.png"
              alt=""
            >
          </div>
        </div>
      </div>
    </van-form>

    <div
      v-if="loading && hideshow"
      class="submit"
      @click="submitStatus"
    >马上提交</div>
    <van-popup
      v-model="show"
      :round="true"
      :style="{ height: '161.5px',width:'225px','text-align':'center',lineHeight:'161.5px' }"
      :close-on-click-overlay="false"
    >
      <div class="subMessage">
        <span style="color:#000;font-weight:bold">提交成功</span>
        <span>请耐心等待物业确认</span>

      </div>
      <div
        class="subSuccess"
        @click="resetComponent"
      >关闭</div>
    </van-popup>
    <!-- 地址房号 -->
    <!-- <van-popup
      v-model="addressPup"
      round
      position="bottom"
    >
      <van-picker
        show-toolbar
        :columns="columns"
        @cancel="showPicker = false"
        @confirm="onConfirm"
        :default-index="0"
      />
    </van-popup> -->
    <!-- 公共区域 -->
  </div>
</template>

<script>

import wx from "weixin-js-sdk";
window.onunload = function () {
  wx.miniProgram.navigateBack({});
};
/**
 * params: title:home 居家维修 gqbs 公区报事
 * **/

import * as vant from "vant";
import * as api from "./http/api";
import * as commons from "../../commons/index";
import storage from "../../commons/storage";
import { formatDate } from "./utils/index";
// var wx = require("weixin-js-sdk");
export default {
  name: "home-maintenance",
  props: {
    title: {
      type: String,
    },
  },
  watch: {
    title: function (next) {
      // 如果消费了这个工单号 会滞空 然后切换时重新获取工单号
      // if (this.form.orderNumber) {
      //   this.getWorkOrderNumber();
      // }
      this.$forceUpdate();
      console.log("next", next);
      if (next === "home") {
        this.resetComponent();
      } else {
        this.$forceUpdate();
        this.resetCommon();
      }
    },
    showHeight: function () {
      if (this.docmHeight > this.showHeight) {
        this.hideshow = false;
      } else {
        this.hideshow = true;
      }
    },
  },
  data() {
    return {
      // 跳转需要重置
      time: new Date().valueOf() * 60 * 60 * 1000,
      // time :new Date().valueOf() + 1 * 60 * 60 * 1000,
      selectedTime: null,
      show: false,
      showTime: false,
      formText: {
        addressNo: "请选择公共区域",
        selectedText: "尽快上门",
      },
      placeHolder: "请在此填写您遇到的问题，也可选择下方预置 内容，快速提报！",
      fileList: [],
      acceptContent: "",
      form: {
        // wepy
        orderNumber: "", // 获取的工单单号
        manageAreaId: "", //范围
        isPublicArea: false, // 默认
        typeId: 2,
        levelId: 1,
        houseId: "",
        unitId: "",
        floorId: "",
        acceptContent: "", // 输入框
        visitType: "Now",
        receptionModeId: 2,
        userName: "",
        // phone: "18500190562",
        orderResources: [],
        communityExtId: "",
      },
      // 当前获取的地址
      currentAddress: {},
      // 工区报事
      formPublic: {
        message: "",
        img: [],
        time: "",
        area: "",
      },
      loading: false,
      activeGroup: [], //预设选择组
      imgUp: false,
      currentDate: new Date(),
      addressPup: false,
      addressPub: "",
      active: null,
      cascadePicker: null,
      timeArea: {
        startTime: "",
        endTime: "",
      },
      environment: "",
      //   模拟数据
      columnsCommon: [
        {
          value: "一区",
          text: "一区",
          children: [
            {
              value: "一号楼",
              text: "一号楼",
              children: [
                { value: "一单元", text: "一单元" },
                { value: "二单元", text: "二单元" },
              ],
            },
            {
              text: "二号楼",
              children: [{ text: "一单元" }, { text: "二单元" }],
            },
          ],
        },
        {
          text: "二区",
          children: [
            {
              text: "一号楼",
              children: [{ text: "一单元" }, { text: "二单元" }],
            },
            {
              text: "二号楼",
              children: [{ text: "三单元" }, { value: 3, text: "四单元" }],
            },
          ],
        },
      ],
      findPub: "",
      systemPreset: [],
      columns: [
        "彩虹城一区5-4-102",
        "彩虹城一区5-4-103",
        "彩虹城一区5-2-102",
        "彩虹城一区1-4-102",
        "彩虹城一区3-4-102",
        "彩虹城一区4-4-102",
        "彩虹城一区5-4-402",
        "彩虹城一区2-4-102",
      ],
      columns1: [],
      checkCommunityId: "",
      //   微信配置
      weixinConfig: {
        debug: false, // 调试时可以打开，能看到每一步的返回结果
        appId: "wx2354c2483a700f41", // 后台会返回
        timestamp: 1616215385, // 后台会返回
        nonceStr: "7957ac72f0519e3fb08a8520a62e2439", // 后台会返回
        signature: "e85da653337632dffb26272f093bba903a3873f6", // 后台会返回
        jsApiList: ["ready", "chooseImage", "onMenuShareTimeline"], // 要包含所有你要用到的方法
      },
      addressListPub: [], //公区报事的地址
      // 处理安卓手机键盘弹出顶fixed
      docmHeight: document.documentElement.clientHeight, //默认屏幕高度
      showHeight: document.documentElement.clientHeight, //实时屏幕高度
      hideshow: true, //显示或者隐藏footer
    };
  },
  created() {
    // this.getWxInfo();
    // console.log(this.$route.fullPath.split("=")[1].split("&&")[0],this.$route.fullPath.split("=")[2].split("&&")[0],"------------------")
  },
  mounted() {
    var u = navigator.userAgent;
    console.log(u, "机型");
    var num_sing1 = 0;
    var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1;
    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    console.log(isAndroid, isiOS);
    if (isiOS) {
      this.environment = "ios";
    } else {
      this.environment = "and";
    }
    // window.onresize监听页面高度的变化
    window.onresize = () => {
      return (() => {
        this.showHeight = document.body.clientHeight;
      })();
    };
    wx.config(this.weixinConfig);
    wx.error(function (res) {
      // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      console.log(res, "errorConfig");
    });

    // storage.set(storage.keys.ACCESS_TOKEN, this.$route.query.access_token);
    // storage.set(
    //   storage.keys.CHOOSE_COMMUNITY,
    //   this.$route.query.checkCommunityId
    // );
    storage.set(
      storage.keys.ACCESS_TOKEN,
      this.$route.fullPath.split("=")[1].split("&&")[0]
    );
    storage.set(
      storage.keys.CHOOSE_COMMUNITY,
      this.$route.fullPath.split("=")[2].split("&&")[0]
    );
    this.checkCommunityId = this.$route.fullPath.split("=")[2].split("&&")[0];
    this.form.communityExtId = this.$route.fullPath
      .split("=")[2]
      .split("&&")[0];
    this.getRealEstateInformation(); //获取房产信息
    this.getTimeArea(); //获取时间限制
    this.getSamble(); // 获取样例信息
    this.getAddress(); // 获取地址
    this.getUserInfo(); // 获取用户信息
    this.timeTitle =
      new Date().getFullYear() + "/" + (new Date().getMonth() / 1 + 1);

    setTimeout(() => {
      this.loading = true;
    }, 100);
  },
  methods: {
    focuseInput() {
      console.log("--==--");
    },
    getUserInfo() {
      api.userInfo().then((res) => {
        console.log(res, "用户信息");
        this.form.userName = res.data.userAccountDetailsResponse.realName
          ? res.data.userAccountDetailsResponse.realName
          : res.data.userAccountDetailsResponse.nickName;
      });
    },
    getWxInfo() {
      api
        .wxConfigInfo(encodeURI(location.href.split("#")[0]))
        .then((res) => {});
    },
    getAddress() {
      api.myhouse(this.checkCommunityId).then((res) => {
        if (res.data && res.data.length > 0) {
          res.data.map((v) => {
            v.text = v.houseName;
            v.value = v.houseId;
          });
        }
        this.columns = res.data;
        this.title === "home"
          ? ((this.formText.addressNo = this.columns[0].houseName),
            (this.form.manageAreaId = this.columns[0].managerAreaId),
            (this.form.floorId = this.columns[0].floorId),
            (this.form.houseId = this.columns[0].houseId),
            (this.form.unitId = this.columns[0].unitId))
          : (this.formText.addressNo = "请选择公共区域");
      });
    },
    getSamble() {
      api.sample(this.checkCommunityId).then((res) => {
        this.systemPreset = res.data;
      });
    },
    getTimeArea() {
      // 41756 this.checkCommunityId
      api.settings(this.checkCommunityId).then((res) => {
        console.log(res, "time");
        if (res.data) {
          this.timeArea.startTime = res.data.startTime
            ? res.data.startTime
            : "00:00";
          this.timeArea.endTime = res.data.endTime ? res.data.endTime : "24:00";
        } else {
          this.timeArea.startTime = "00:00";
          this.timeArea.endTime = "24:00";
        }
      });
    },
    getRealEstateInformation() {
      // api.getHomeInfo().then((response) => {});

      this.getWorkOrderNumber();
    },

    // 获取工单单号
    getWorkOrderNumber() {
      api.getWorkorderNumber(this.checkCommunityId).then((response) => {
        console.log(response.data, "order");
        this.form.orderNumber = response.data;
      });
    },
    handleCloseImage() {
      console.log("关闭");
    },
    wxUpload() {
      console.log(wx);

      wx.chooseImage({
        count: 1,
        sizeType: ["original", "compressed"],
        sourceType: ["album", "camera"],
        success(res) {
          console.log(res, "choose");
          // tempFilePath可以作为img标签的src属性显示图片
          const tempFilePaths = res.tempFilePaths;
        },
        error(e) {
          console.log(e);
        },
      });
    },
    showSetDataPicker() {
      if (this.title === "home" && this.columns.length > 0) {
        this.addressPup = true;
        this.setDataPicker = this.$createPicker({
          onSelect: this.selectHandle,
          onCancel: this.cancelHandle,
          data: [this.columns],
        });
        // this.setDataPicker.setData(this.columns);

        this.setDataPicker.show();
      }
      if (this.title !== "home") {
        this.getAddressPub();
      }
    },
    selectHandlePub(name, selectedIndex, selectedText) {
      console.log(name, "find");
      this.form.manageAreaId = name[0];
      let find = this.findObj(this.addressListPub, name[2]);
      console.log(this.findPub, "find1");
      this.form.floorId = this.findPub.floorId;
      this.form.unitId = this.findPub.id; //this.findPub.unitNo //
      this.formText.addressNo = selectedText.join("-");
    },
    findObj(arr, id) {
      let a = "";
      arr.forEach((v) => {
        if (v.id === id) {
          this.findPub = v;

          console.log(a, "a----");
          return a;
        }
        if (v.floorInfoList && v.floorInfoList.length > 0) {
          this.findObj(v.floorInfoList, id);
        }
        if (v.floorUnitInfoResList && v.floorUnitInfoResList.length > 0) {
          this.findObj(v.floorUnitInfoResList, id);
        }
      });
    },
    cancelHandlePub() {},
    selectHandle(name) {
      console.log(name);
      this.formText.addressNo = "";
      let obj = this.columns.find((v) => v.houseId === name[0]);
      this.currentAddress = obj;
      this.formText.addressNo = obj.text;
      this.form.manageAreaId = obj.managerAreaId;
      this.form.floorId = obj.floorId;
      this.form.houseId = obj.houseId;
      this.form.unitId = obj.unitId;
      console.log(obj);
    },
    cancelHandle() {},
    onConfirm(name) {
      this.formText.addressNo = name;
      this.addressPup = false;
    },
    onConfirmpub() {},
    showTimePicker() {
      const timePicker = this.$createTimePicker({
        format: "YYYY-MM-DD hh:mm:ss",
        showNow: { showNow: true, text: "尽快上门" },
        minuteStep: 30,
        title: `预约时间范围（${this.timeArea.startTime}-${this.timeArea.endTime}）`,
        delay: 30,
        day: {
          len: 30,
          format: "M月D日",
        },
        onSelect: (selectedTime, selectedText, formatedTime) => {
          let houer = formatedTime.split(" ")[1].split(":")[0];
          let mintues = formatedTime.split(" ")[1].split(":")[1];
          console.log(houer, mintues);
          if (
            houer / 1 > this.timeArea.endTime.split(":")[0] / 1 ||
            houer / 1 < this.timeArea.startTime.split(":")[0] / 1
          ) {
            this.$createDialog({
              type: "warn",
              content: `请在预约范围内选择`,
              icon: "cubeic-alert",
            }).show();

            return;
          } else if (
            houer / 1 == this.timeArea.endTime.split(":")[0] / 1 
          ) {
            if (
              mintues / 1 > this.timeArea.endTime.split(":")[1] / 1
            ) {
              this.$createDialog({
                type: "warn",
                content: `请在预约范围内选择`,
                icon: "cubeic-alert",
              }).show();
              return;
            }
          }else if(houer / 1 == this.timeArea.startTime.split(":")[0] / 1){
            if (
              mintues < this.timeArea.startTime.split(":")[1] / 1
            ) {
              this.$createDialog({
                type: "warn",
                content: `请在预约范围内选择`,
                icon: "cubeic-alert",
              }).show();
              return;
            }
          }
          this.formText.selectedText = selectedText;
          this.selectedTime = formatedTime;
        },
        onCancel: () => {
          //   this.$createToast({
          //     type: "correct",
          //     txt: "Picker canceled",
          //     time: 1000,
          //   }).show();
        },
      });

      timePicker.setTime(this.time);
      timePicker.show();
    },
    getAddressPub() {
      api.customListPublic(this.checkCommunityId).then((res) => {
        this.addressListPub = res;
        // this.addressPub = this.createSegmentPicker({

        //   data: res,
        // })
        this.addressPub = this.$createCascadePicker({
          title: "选择公区",
          data: res,
          onSelect: this.selectHandlePub,
          onCancel: this.cancelHandlePub,
        });
        this.addressPub.show();
        // console.log(this.addressListPub, "addressNet");
      });
    },
    onSubmit() {},
    tapPreset(e, item) {
      // if (this.activeGroup.length == 0) {
      //   this.activeGroup.push(item);
      // } else {
      //   this.activeGroup.forEach((v, i) => {
      //     if (item.id === v.id) {
      //       this.$forceUpdate();
      //       console.log("删除", this.activeGroup);
      //       this.activeGroup.splice(i, 1);
      //     } else {
      //       this.activeGroup.push(item);

      //       this.activeGroup = [...new Set(this.activeGroup)];
      //     }
      //   });
      // }
      let find = this.activeGroup.find((v) => v === item);
      if (!find) {
        this.activeGroup.push(item);
      } else {
        let i = this.activeGroup.indexOf(find);
        this.activeGroup.splice(i, 1);
      }
    },
    afterRead(event) {
      this.fileList.map((v) => {
        if(!v.status){
          
        v.status = "uploading";
        v.message = "上传中";
        }
      });
      if (!Array.isArray(event)) {
        let dataSingle = new FormData();
        dataSingle.append("resource", event.file);
        console.log(dataSingle,"上传图片")
        api.uploadObject(dataSingle).then((res) => {
          if (res.success) {
            this.form.orderResources.push({
              url: res.data,
              thumbnails: res.data,
              type: "IMAGE",
              bizType: "COMMIT",
            });
            this.fileList.map((v) => {
              v.status = "done";
              v.message = "上传完成";
            });
          } else {
            this.fileList.map((v) => {
              if(v.status === 'uploading'){

              v.status = "failed";
              v.message = "上传失败";
              }
            });
          }
        });
      } else {
        event.forEach(async (v) => {
          let dataSom = new FormData();
          dataSom.append("resource", v.file);
          let data = await api.uploadObject(dataSom);
          if (data.success) {
            this.fileList.map((v) => {
              v.status = "done";
              v.message = "上传完成";
            });
            this.form.orderResources.push({
              url: data.data,
              thumbnails: data.data,
              type: "IMAGE",
              bizType: "COMMIT",
            });
          } else {
            this.fileList.map((v) => {
              if(v.status === 'uploading'){

              v.status = "failed";
              v.message = "上传失败";
              }
            });
          }
          // api.uploadObject(dataSom).then((res) => {
          //   if (res.success) {
          //     this.form.orderResources.push({
          //       url: res.data,
          //       thumbnails: res.data,
          //       type: "IMAGE",
          //       bizType: "COMMIT",
          //     });
          //   }
          // });
        });
      }
    },
    resetComponent() {
      this.show = false;

      this.form.acceptContent = "";
      this.acceptContent = "";
      this.formText.addressNo = this.columns[0].houseName;
      this.formText.selectedText = "尽快上门";
      this.form.visitType = "Now";
      this.form.manageAreaId = "";
      this.form.houseId = "";
      this.form.unitId = "";
      this.fileList = [];
      this.form.floorId = "";
      this.active = null;
      this.activeGroup = [];
      this.form.orderResources = [];
      this.fileList = [];
      this.getAddress(); // 获取地址
    },
    delteImg(event, detail) {
      this.form.orderResources.splice(detail.index, 1);
      // console.log(detail,event,this.form.orderResources.splice(detail.index,1))
      return true;
    },
    resetCommon() {
      this.show = false;
      this.fileList = [];
      this.form.orderResources = [];
      this.form.manageAreaId = "";
      this.form.houseId = "";
      this.form.unitId = "";
      this.form.floorId = "";
      this.form.message = "";
      this.formText.addressNo = "请选择公共区域";
      this.formText.selectedText = "尽快上门";
      this.active = null;
    },
    /**
     * 判断至少输入原因或者选择了预设
     * **/
    submitStatus() {
      // home
      if (this.title === "home") {
        if (this.acceptContent || this.activeGroup.length > 0) {
          if (this.formText.selectedText === "尽快上门") {
            console.log("尽快上门", this.activeGroup);
            this.selectedTime = formatDate(new Date(), "yyyy-MM-dd h:m:ss");
          } else {
            this.form.visitType = "Visit";
            this.form.visitTime = this.selectedTime;
          }
          console.log(this.activeGroup);
          this.form.acceptContent = this.acceptContent;
          this.activeGroup.map((v, i) => {
            this.form.acceptContent
              ? (this.form.acceptContent += `,${v}`)
              : (this.form.acceptContent += `${i === 0 ? "" : ","}${v}`);
          });
          this.form.isPublicArea = false;
          // 创建工单
          api.createWork(this.form).then((res) => {
            this.form.orderNumber = "";

            this.getWorkOrderNumber();
            if (res.success) {
              this.show = true;

              Toast.fail("创建工单成功");
              this.resetComponent();
            } else {
              Toast.fail("创建工单失败");
            }
          });
        } else {
          vant.Toast.loading({
            message: "抱歉 原因和预置至少选一个",
            forbidClick: true,
          });
        }
      } else {
        this.form.isPublicArea = true;
        if (this.formText.selectedText === "尽快上门") {
          console.log("尽快上门", this.activeGroup);
          this.selectedTime = formatDate(new Date(), "yyyy-MM-dd h:m:ss");
        } else {
          this.form.visitType = "Visit";
          this.form.visitTime = this.selectedTime;
        }

        console.log(this.form);
        if (!this.form.manageAreaId) {
          vant.Toast("请选择公共区域");
          return;
        }
        if (!this.acceptContent) {
          vant.Toast("请填写内容");
          return;
        }
        // 创建工单
        console.log(this.form, "创建工单");
        this.form.acceptContent = this.acceptContent;
        api.createWork(this.form).then((res) => {
          this.form.orderNumber = "";

          this.getWorkOrderNumber();
          if (res.success) {
            this.show = true;

            Toast.fail("创建工单成功");
            this.resetComponent();
          } else {
            Toast.fail("创建工单失败");
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.homeWrapper {
  padding: 20px 0 0 0;
  margin: auto;
}
/deep/ .van-field {
  width: 335px;
  height: 140px;
  background: #f6f6f6;
  border-radius: 5px;
}
.groupPreset {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.groupPreset > div {
  padding: 6.5px 12px 8px;

  margin-right: 10px;
  margin-bottom: 15px;
  color: #999999;
  border: 0.5px solid #e3e3e3;
  border-radius: 13.5px;
}

.active {
  color: #29c985 !important;
  border-color: #29c985 !important;
  transition: 0.2s;
}
.updateImage {
  margin: 10px 0 24.5px 0;
  display: flex;
  justify-content: flex-start;
}
.homeInfo {
  width: 100%;
}
.control {
  color: #333333;
  font-size: 15px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  margin-right: 10px;
}
.time,
.address {
  height: 49px;
  position: relative;
}
.time:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 1px;
  top: 0px;
  left: 0px;
  background: url(./image/Line.png) no-repeat;
}
.address::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 1px;
  top: 0px;
  left: 0px;
  background: url(./image/Line.png) no-repeat;
}
.address::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0px;
  left: 0px;
  background: url(./image/Line.png) no-repeat;
}
.label {
  height: 49px;
  line-height: 49px;
  float: left;
}
.submit {
  width: 335px;
  height: 41px;
  background: #29c985;
  border-radius: 6px;
  text-align: center;
  line-height: 41px;
  color: #fff;
  position: fixed;
  z-index: 4;
  bottom: 15.5px;
}
/deep/ .van-picker__toolbar {
  position: relative;
  &::after {
    position: absolute;
    bottom: 0px;
    left: 0px;
    // backgrou
  }
}
/deep/ .van-popup--bottom.van-popup--round {
  border-radius: 0px;
}
.subSuccess {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background: #29c985;
  font-size: 18px;
  color: #fff;
}
.subMessage {
  width: 196px;
  height: 49px;
  margin: 45px auto 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  span {
    height: 20px;

    line-height: 20px;
  }
}
.upLoad {
  width: 70px;
  height: 70px;
  background: url("./image/upLoad.png") no-repeat center 15px;
  background-size: 26px 26px;
  text-align: center;
  line-height: 110px;
  color: #d4d4d4;
  font-size: 12px;
  position: relative;
  margin-left: 15px;
}
.upLoad::after {
  content: "  ";
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 200%;
  height: 200%;
  border: 1px solid #d4d4d4;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: scale(0.5, 0.5);
  transform: scale(0.5, 0.5);
  border-radius: 10px;
}
.timeValue {
  float: right;
  display: flex;
  align-items: center;
  height: 100%;
  img {
    width: 20px;
    height: 20px;
  }
}
/deep/ .van-picker__cancel,
/deep/ .van-picker__confirm {
  color: #29c985 !important;
}

.imgUp {
  display: flex !important;
  position: relative;
  img {
    width: 70px;
    height: 70px;
  }
  //   关闭按钮
  .closeImg {
    position: absolute;
    width: 20px;
    height: 20px;
    background: url("./image/cells_repair_button_close.png") no-repeat
      center/cover;
    position: absolute;
    top: -10px;
    right: -10px;
    border-radius: 50%;
  }
}
</style>
<style lang="less">
.van-picker-column {
  .van-picker-column__wrapper {
    li {
      width: 100% !important;
    }
  }
}
.cube-picker-cancel,
.cube-picker-confirm {
  color: #29c985 !important;
}
.cube-picker-title {
  font-size: 15px !important;
}

html body /deep/ .van-field__word-limit {
  margin-bottom: 4px !important;
  margin-top: 0px !important;
}
</style>