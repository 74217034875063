<template>
    <div id="app">
        <!-- <transition :name="this.$store.state.fade"> -->
        <transition>
            <router-view />
        </transition>
    </div>
</template>

<script>
export default {
    name: "app",
    data() {
        return {};
    },
    mounted() { },
    watch: {
        $route(to, from) {
            setTimeout(() => {
                this.$store.state.fade = "fade-left";
            }, 20);
        }
    }
};
</script>
 // 
<style lang="less">
::-webkit-scrollbar {
    display: none !important;
    width: 0px !important;
    height: 0px !important;
}

html,
body,
#app {
    width: 100%;
    height: 100%;
    font-family: -apple-system, BlinkMacSystemFont, "PingFang SC",
        "Helvetica Neue", STHeiti, "Microsoft Yahei", Tahoma, Simsun, sans-serif;
}

.sqbj-loading {
    top: 30%;
    text-align: center;
}


/* 开始过渡阶段,动画出去阶段 */
.fade-left-leave-active,
.fade-right-leave-active {
    transition: all 0.3s ease-out;
}

/* 进入开始 */
.fade-left-enter,
.fade-right-leave-active {
    -webkit-transform: translateX(80%);
    transform: translateX(80%);
    opacity: 0;
}

/* 出去终点 */
.fade-left-leave-active,
.fade-right-enter {
    -webkit-transform: translateX(-80%);
    transform: translateX(-80%);
    opacity: 0;
}

.pages-HairyCrab-index-vanDialog {
    .header {
        background: #29c985;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
        line-height: 40px;
        text-align: center;
    }

    .content {
        padding: 15px 18px;

        .tip {
            margin-bottom: 7px;
            font-size: 16px;
            font-weight: 400;
            color: #969696;
            line-height: 23px;
            letter-spacing: 0.01px;
        }

        .info {
            display: flex;
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 25px;
            letter-spacing: 0.01px;

            .label {
                width: 100px;
                text-align: right;
            }

            .text {
                flex: 1;
            }
        }
    }
}
</style>

