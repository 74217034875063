export const MAP_STYLE = "amap://styles/f1a24f2a154e6038df0e0cdb677b8c22";
export const LAYER_TYPE_BUILDING = "BUILDING"; // 楼宇
export const LAYER_TYPE_UNIT = "UNIT"; // 单元
export const LAYER_TYPE_EQUIPMENT_ROOM = "EQUIPMENT_ROOM"; // 设备间
export const LAYER_TYPE_PUBLIC_AREA = "PUBLIC_AREA"; // 公区
export const LAYER_TYPE_DEVICE_POSITION = "DEVICE_POSITION"; // 设备点

export const DEVICE_TYPE_ENTRANCE_FACILITY = "ENTRANCE_FACILITY"; // 门岗
export const DEVICE_TYPE_MONITOR_DOMAIN = "MONITOR_DOMAIN"; // 监控域
export const DEVICE_TYPE_PARK_ENTRANCE = "PARK_ENTRANCE"; // 车场出入口

export const EVENT_TYPE_ALARM = "ALARM"; // 告警
export const EVENT_TYPE_REPAIR = "REPAIR"; // 报修
export const EVENT_TYPE_COMPLAIN = "COMPLAIN"; // 投诉
export const EVENT_TYPE_SUGGEST = "SUGGEST"; // 建议
export const EVENT_TYPE_CONSULTATION = "CONSULTATION"; // 咨询

export const layerTextMap = {
    [LAYER_TYPE_BUILDING]: "楼宇",
    [LAYER_TYPE_UNIT]: "单元",
    [LAYER_TYPE_EQUIPMENT_ROOM]: "设备间",
    [LAYER_TYPE_PUBLIC_AREA]: "公区",
    [LAYER_TYPE_DEVICE_POSITION]: "设备点",
}

export const eventTextMap = {
    [EVENT_TYPE_ALARM]: "告警",
    [EVENT_TYPE_REPAIR]: "报修",
    [EVENT_TYPE_COMPLAIN]: "投诉",
    [EVENT_TYPE_SUGGEST]: "建议",
    [EVENT_TYPE_CONSULTATION]: "咨询"
}

export const eventColorMap = {
    [EVENT_TYPE_ALARM]: "#FF3131",
    [EVENT_TYPE_REPAIR]: "#FFDC43",
    [EVENT_TYPE_COMPLAIN]: "#ED5401",
    [EVENT_TYPE_SUGGEST]: "#55D470",
    [EVENT_TYPE_CONSULTATION]: "#22878B",
}

export const datasource = [
    {
        id: 1,
        areaId: 10385,
        adcode: 110106,
        name: "京桥热电家属楼",
        center: [116.415465, 39.839702],
        content: "马婷婷提交建议",
        type: "SUGGEST",
        eventTime: 1576051419000,
    },
    {
        id: 2,
        areaId: 10385,
        adcode: 110106,
        name: "京桥热电家属楼",
        center: [116.415465, 39.839702],
        content: "王小红提交建议",
        type: "CONSULTATION",
        eventTime: 1576051370000,
    },
    {
        id: 3,
        areaId: 10470,
        adcode: 410102,
        name: "京桥商业楼",
        center: [113.5387, 34.799417],
        content: "邓红提报了一个工单",
        type: "REPAIR",
        eventTime: 1576051419000,
    },
    {
        id: 4,
        areaId: 10470,
        adcode: 410102,
        name: "京桥商业楼",
        center: [113.5387, 34.799417],
        content: "李欣提报了一个工单",
        type: "COMPLAIN",
        eventTime: 1576051419000,
    },
        {
        id: 5,
        areaId: 10386,
        adcode: 220323,
        name: "和泓·天海青城",
        center: [124.370785, 43.170344],
        content: "白凌雯提报了一个工单",
        type: "COMPLAIN",
        eventTime: 1576051419000,
    },
    {
        id: 6,
        areaId: 10386,
        adcode: 220323,
        name: "和泓·天海青城",
        center: [124.370785, 43.170344],
        content: "赵海滨提报了一个工单",
        type: "REPAIR",
        eventTime: 1576051419000,
    },
    {
        id: 7,
        areaId: 10472,
        adcode: 110114,
        name: "和泓·假日阳光",
        center: [116.318086, 40.064369],
        content: "厉欣提报了一个工单",
        type: "REPAIR",
        eventTime: 1576051419000,
    },{
        id: 8,
        areaId: 10472,
        adcode: 110114,
        name: "和泓·假日阳光",
        center: [116.318086, 40.064369],
        content: "业主丁女士家漏水",
        type: "ALARM",
        eventTime: 1576051419000,
    },{
        id: 9,
        areaId: 10395,
        adcode: 110118,
        name: "和泓阳光",
        center: [116.811845, 40.363622],
        content: "业主张玲被盗",
        type: "CONSULTATION",
        eventTime: 1576051419000,
    },{
        id: 10,
        areaId: 10395,
        adcode: 110118,
        name: "和泓阳光",
        center: [116.811845, 40.363622],
        content: "马平提交告警",
        type: "ALARM",
        eventTime: 1576051419000,
    }
]