// 提报状态
export const WORKORDER_STATUS_CLOSED = 'Closed'
export const WORKORDER_STATUS_SENT = 'Sent'
export const WORKORDER_STATUS_RETURN_VISITED = 'ReturnVisited'
export const WORKORDER_STATUS_ACCETTING = 'Accepted'
export const WORKORDER_STATUS_ACCEPTING = 'Accepting'
export const WORKORDER_STATUS_CREATED = 'Created'
export const WORKORDER_STATUS_FINISHED = 'Finished'
export const WORKORDER_STATUS_SIGNED = 'Signed'
// 时间线状态
export const WORKORDER_TIMELINESTATUS_CLOSED = 'Closed'
export const WORKORDER_TIMELINESTATUS_EVALUATE = 'Evaluate'
export const WORKORDER_TIMELINESTATUS_FINISHED = 'Finished'
export const WORKORDER_TIMELINESTATUS_TRANSFER = 'Transfer'
export const WORKORDER_TIMELINESTATUS_BEGIN = 'Begin'
export const WORKORDER_TIMELINESTATUS_ACCEPTED = 'Accepted'
export const WORKORDER_TIMELINESTATUS_COMMIT = 'Commit'
// 处理中or缴费等提交
export const WORKORDER_TEXTSTATUS_INPROCESS = 'Accepting';
export const WORKORDER_TEXTSTATUS_SENT = 'Sent'
export const WORKORDER_TEXTSTATUS_NONE = 'None'
export const WORKORDER_TEXTSTATUS_SETTLEDBILL = 'Settledbill'

/**  评价 
 * 
 * 
 * GreatSatisfaction(5, "非常满意"),
    RatherSatisfaction(4, "满意"),
    Satisfaction(3, "一般"),
    NotSatisfaction(2, "不满意"),
    VeryNotSatisfaction(1, "非常不满意"),
    NotScore(0, "未评分"),;
 * **/
export const WORKORDER_LIKE_NOT_SCORE = "NotScore";
export const WORKORDER_LIKE_VERY_SATISFACTION = "VeryNotSatisfaction";
export const WORKORDER_LIKE_NOT_SATISFACTION = "NotSatisfaction";
export const WORKORDER_LIKE_SATISFACTION = "Satisfaction";
export const WORKORDER_LIKE_RATHER_SATISFACTION = "RatherSatisfaction";
export const WORKORDER_LIKE_GREAT_SATISFACTION = "GreatSatisfaction";

export const likeArray = [
    WORKORDER_LIKE_NOT_SCORE,
    WORKORDER_LIKE_VERY_SATISFACTION,
    WORKORDER_LIKE_NOT_SATISFACTION,
    WORKORDER_LIKE_SATISFACTION,
    WORKORDER_LIKE_RATHER_SATISFACTION,
    WORKORDER_LIKE_GREAT_SATISFACTION
]

export const textMap = {
    status: {
        [WORKORDER_STATUS_CLOSED]: '已取消',
        [WORKORDER_STATUS_SENT]: '已派单',
        [WORKORDER_STATUS_RETURN_VISITED]: '已回访',
        [WORKORDER_STATUS_ACCETTING]: '已结单',
        [WORKORDER_STATUS_CREATED]: "待派单",
        [WORKORDER_STATUS_FINISHED]: "已完成",
        [WORKORDER_STATUS_ACCEPTING]: '处理中',
        [WORKORDER_STATUS_SIGNED]: '签名'
    },
    timeLineStatus: {
        [WORKORDER_TIMELINESTATUS_CLOSED]: "任务已关闭",
        [WORKORDER_TIMELINESTATUS_EVALUATE]: '任务已评价',
        [WORKORDER_TIMELINESTATUS_FINISHED]: '任务已完成',
        [WORKORDER_TIMELINESTATUS_TRANSFER]: '任务已转派',
        [WORKORDER_TIMELINESTATUS_BEGIN]: '开始处理任务',
        [WORKORDER_TIMELINESTATUS_ACCEPTED]: '物业已受理',
        [WORKORDER_TIMELINESTATUS_COMMIT]: '报修单提交成功'
    },
    textSatus: {
        [WORKORDER_TEXTSTATUS_INPROCESS]: '联系处理人',
        [WORKORDER_TEXTSTATUS_SENT]: '联系处理人',
        [WORKORDER_TEXTSTATUS_NONE]: "",
        // [WORKORDER_TEXTSTATUS_SETTLEDBILL]: "联系处理人",
        [WORKORDER_STATUS_ACCETTING]: "联系处理人",
        [WORKORDER_STATUS_RETURN_VISITED] :""
    },
    ratesSatus: {
        [WORKORDER_STATUS_ACCETTING]: "去评价",
        [WORKORDER_STATUS_ACCEPTING]: "",
        [WORKORDER_STATUS_CLOSED]: '',
        [WORKORDER_STATUS_SENT]: '',
        [WORKORDER_STATUS_RETURN_VISITED]: '',
        [WORKORDER_STATUS_CREATED]: "",
        [WORKORDER_STATUS_FINISHED]: "",
    },
    cancelStatus: {
        [WORKORDER_STATUS_ACCEPTING]: "取消提报",
        [WORKORDER_STATUS_CLOSED]: '',
        [WORKORDER_STATUS_SENT]: '取消提报',
        [WORKORDER_STATUS_RETURN_VISITED]: '',
        [WORKORDER_STATUS_ACCETTING]: '',
        [WORKORDER_STATUS_CREATED]: "取消提报",
        [WORKORDER_STATUS_FINISHED]: "",
    },
    like: {
        [WORKORDER_LIKE_VERY_SATISFACTION]: "不满意",
        [WORKORDER_LIKE_NOT_SATISFACTION]: "不满意",
        [WORKORDER_LIKE_NOT_SCORE]: "未评分",
        [WORKORDER_LIKE_SATISFACTION]: '一般',
        [WORKORDER_LIKE_RATHER_SATISFACTION]: "满意",
        [WORKORDER_LIKE_GREAT_SATISFACTION]: "满意"
    }
}