import axios from "axios";
import storage from "./storage";

const instance = axios.create({});
let accessToken = "";

export function setaccessToken(token) {
    accessToken = token;

}

instance.defaults.responseType = "json";
instance.defaults.headers.common["API-Client-Type"] = "H5";
instance.defaults.headers.common["current-app"] = "gdata";
instance.defaults.headers.common["project-model"] = false;
instance.interceptors.request.use((request) => {
    // instance.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
    accessToken = accessToken || storage.get(storage.keys.ACCESS_TOKEN);
    if (accessToken && accessToken.indexOf("null") === -1) {
        request.headers["Authorization"] = "Bearer " + accessToken;
    }

    return request;
})

instance.interceptors.response.use((response) => {
    const { success, code, errorMsg } = response.data;
    if (success === false) {
        response.code = code;
        response.message = errorMsg;
        return Promise.reject(response);
    }
    return response.data;
});

export default instance;