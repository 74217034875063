<template>
    <div class="pages-pay-success">
        <div class="logo">
            <div class="check"></div>
        </div>
        <div class="title">支付成功</div>
        <div class="description">您已完成支付<br />感谢您对我们的支持！</div>
    </div>
</template>

<script>

export default {
    name: "success",

    data() {
        return {};
    },
    mounted() {
        document.title = "支付成功";
    }
};
</script>

<style lang="less" scoped>
.pages-pay-success {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 20px;

    .logo {
        width: 80px;
        height: 80px;
        border: 5px solid #1be3ce;
        border-radius: 50%;
        display: flex;
        justify-content: center;
    }

    .check {
        width: 50px;
        height: 30px;
        border: 5px solid transparent;
        border-left-color: #1be3ce;
        border-bottom-color: #1be3ce;
        transform: rotate(-45deg);
        margin-top: 10px;
    }

    .title {
        font-size: 18px;
        color: #3ab0c0;
        font-weight: bold;
    }

    .description {
        font-size: 14px;
        color: #979797;
        text-align: center;
        line-height: 1.2em;
    }
}
</style>