<template>
    <div class="fts-charge">
        <template v-if="showNavigator" >
            <div class="navigator" v-on:click="navigatorClickHandler" ><img src="./images/nav.png" /></div>
        </template>
        <template v-if="preLoading">
            <div class="loading">
                <div v-for="dot in preLoadingDots" :key="dot" v-bind:class="'dot-wrapper' + (preLoadingIndex === dot ? ' active' : '')" >
                    <div class="dot" />
                </div>
            </div>
        </template>
        <template v-else>
            <div class="card">
                <div class="order-number">
                    <span class="label" >缴费单号：</span>
                    <span class="content" >{{orderNumber}}</span>
                </div>
                <div class="bills">
                    <div class="bill" v-for="bill in bills" :key="bill.id" >
                        <div>
                            <div class="fee">{{bill.fee}}</div>
                            <div class="date">{{bill.startDate}}-{{bill.endDate}}</div>
                            <div class="data" v-if="!!bill.countlast || !!bill.count" >
                                <span v-if="!!bill.countlast" >表底：{{bill.countLast}}/{{bill.countLatest}}</span>
                                <span v-if="!!bill.count" >用量：{{bill.count}}</span>
                            </div>
                        </div>
                        <div>
                            <div class="amount">￥{{bill.amount.toFixed(2)}}</div>
                            <div class="extra" v-if="!!bill.overdueFine" >滞纳金：{{bill.overdueFine.toFixed(2)}}</div>
                        </div>
                    </div>
                </div>
                <div class="charge">
                    <div class="total">合计：<span>￥{{total.toFixed(2)}}</span></div>
                    <div class="address">{{address}}</div>
                    <div class="customer">{{customer}}</div>
                    <div class="qrcode-expired" v-if="status === 'expired'" >
                        <div class="qrcode-wrapper" ><img src="./images/expired.png" /></div>
                        <div class="message1">二维码已过期</div>
                        <div class="message2">可登录社区半径APP，进入首页，点击物业缴费模块完成缴费。<template v-if="!!butlerName" >或联系您的专属管家-{{butlerName}}。</template></div>
                        <div class="dial" v-if="!!butlerPhone" >
                            <img src="./images/icon-phone.png" />
                            <a v-bind:href="'tel:' + butlerPhone" >拨打管家电话</a>
                        </div>
                    </div>
                    <div class="qrcode-normal" v-else-if="status === 'normal'">
                        <div class="qrcode-wrapper" ><img v-bind:src="'data:image/jpeg;base64,' + qrcodeSrc" /></div>
                        <div class="message2"><span>此二维码有效期至：{{qrcodeExpireDateStr}}</span></div>
                        <div class="message1">
                            请扫码完成支付<template v-if="payWay === 1" >，不支持长按识别二维码支付</template>
                            <br/>
                            <template v-if="!!butlerName" >或联系您的专属管家-{{butlerName}}</template>
                        </div>
                        <div class="dial" v-if="!!butlerPhone" >
                            <img src="./images/icon-phone.png" />
                            <a v-bind:href="'tel:' + butlerPhone" >拨打管家电话</a>
                        </div>
                    </div>
                    <div class="qrcode-success" v-else-if="status === 'success'" >
                        <img src="./images/success.png" />
                        <div class="message1" >缴费成功</div>
                    </div>
                </div>
            </div>
            <div class="foot-note">
                <div class="quickly-open">
                    <div class="logo"><img src="./images/logo.png" /></div>
                    <div class="message">如二维码已过期，可登录社区半径APP完成缴费</div>
                    <div class="btn" v-on:click="callApp"><span>立即打开</span></div>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import moment from "moment";
import * as $commons from "../../commons/index";
export default {
    data() {
        return {
            preLoading: true,
            uuid: null,
            isWeiXin: true,
            isAndroid: false,
            isIOS: false,
            showNavigator: false,
            payWay: 1, // 1：微信 2：支付宝
            status: "normal", // (normal: 正常)|(expired: 已过期)|(success: 成功)
            preLoadingDots: [1, 2, 3, 4, 5, 6],
            preLoadingIndex: 1,
            orderNumber: "",
            bills: [],
            total: 0,
            address: "",
            customer: "",
            butlerName: "",
            butlerPhone: "",
            qrcodeExpireDate: null,
            qrcodeExpireDateStr: "",
            qrcodeSrc: "",
        };
    },
    async mounted() {
        window.document.title = "和泓服务";
        this.checkAgent();
        this.updateLoading();
        const params = window.location.search.substr(1).split("&");
        const uuidParam = params.find(str => str.indexOf("uuid") === 0) || "";
        this.uuid = uuidParam.split("=")[1];
        if (!this.uuid) {
            return console.warn("参数[uuid]缺失");
        }
        this.poll();
    },
    methods: {
        // 接口轮询更新支付状态
        async poll() {
            if (this.status === "normal") {
                try {
                    let houseId = null;
                    const { data } = await $commons.agent({
                        method: "get",
                        baseURL: $commons.SERVICE_BPP_API,
                        url: `/pay/business-query-order?uuid=${this.uuid}`
                    });
                    houseId = data.houseId;
                    this.orderNumber = data.orderNo;
                    this.bills = [];
                    if (data.item && data.item.length > 0) {
                        data.item.forEach(o => this.bills.push({
                            id: o.id,
                            fee: o.objname,
                            startDate: o.startime && moment(o.startime).format("YYYY/MM/DD"),
                            endDate: o.endtime && moment(o.endtime).format("YYYY/MM/DD"),
                            amount: Number(o.curpay) || 0,
                            countLast: o.lread,
                            countlatest: o.nread,
                            count: o.count,
                            overdueFine: Number(o.fine) || 0,
                        }));
                    }
                    this.total = Number(data.orderAmount) || 0;
                    this.address = data.houseAddress;
                    this.customer = data.name;
                    this.qrcodeExpireDate = data.qrCodeInvalidTime;
                    this.qrcodeExpireDateStr = this.qrcodeExpireDate ? moment(this.qrcodeExpireDate).format("YYYY年MM月DD日 HH:mm:ss") : "";
                    this.qrcodeSrc = data.qrCode;
                    if (data.payway === "wechatpay") {
                        this.payWay = 1;
                    } else {
                        this.payWay = 2;
                    }
                    try {
                        const { data: data1 } = await $commons.agent({
                            method: "get",
                            baseURL: $commons.API_WORK_URL,
                            url: `/manager/detail/house/${houseId}`,
                        });
                        if (data1) {
                            this.butlerName = data1.name;
                            this.butlerPhone = data1.phone;
                        }
                    } catch (e) {
                        console.warn(e);
                    }
                    if (data.status === 3) {
                        this.status = "success";
                    } else if (data.status === 2) {
                        this.status = "expired";
                    } else {
                        this.status = "normal";
                        setTimeout(this.poll, 3000);
                    }
                } catch (e) {
                    console.warn("轮询失败");
                    setTimeout(this.poll, 3000);
                }
                this.preLoading = false;
            }
        },
        navigatorClickHandler() {
            this.showNavigator = false;
        },
        // 调起社区半径APP
        callApp() {
            if (this.isWeiXin) {
                this.showNavigator = true;
            } else {
                window.location.href = "sqbj://go";
                setTimeout(() => {
                    const hidden = window.document.hidden || window.document.mozHidden || window.document.msHidden ||window.document.webkitHidden;
                    if(typeof hidden === "undefined" || hidden === false){
                        if (this.isIOS) {
                            if (window.location.protocol.indexOf("https") > -1) {
                                window.location.href = "https://itunes.apple.com/app/id981074858";
                            } else {
                                window.location.href = "http://itunes.apple.com/app/id981074858";
                            }
                        } else if (this.isAndroid) {
                            window.location.href = "market://details?id=cn.lejiayuan";
                            // window.location.href = "appmarket://details?id=cn.lejiayuan"; // 华为市场
                            // window.location.href = "mimarket://details?id=cn.lejiayuan" // 小米商店
                            // window.location.href = "tmast://appdetails?pname=cn.lejiayuan" // 应用宝
                        }
                        
                    }
                }, 3000);
            }
        },
        // 等待初始数据加载成功
        updateLoading() {
            this.preLoadingIndex = this.preLoadingIndex < 6 ? this.preLoadingIndex + 1 : 1;
            if (this.preLoading) {
                setTimeout(this.updateLoading, 400);
            }
        },
        // 检查是在微信中打开还是手机浏览器
        checkAgent() {
            const agent = window.navigator.userAgent;
            this.isWeiXin = agent.toLowerCase().indexOf('micromessenger') > -1; // 微信
            this.isAndroid = agent.indexOf('Android') > -1 || agent.indexOf('Linux') > -1; //android终端
            this.isIOS = !!agent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        }
    },
};
</script>
<style lang="less">
.fts-charge {
    background: linear-gradient(122deg, #347aeb 11%, #639fff 95%);
    position: relative;
    height: 100%;
    padding-top: 15px;
    color: #333;
    .navigator {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: rgba(0,0,0, .7);
        z-index: 99;
        img {
            position: absolute;
            top: 0;
            right: 10px;
            height: 150px;
        }
    }
    .loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: table;
        .dot {
            width: 4px;
            height: 4px;
            background: #fff;
            margin-right: 4px;
            border-radius: 50%;
            &.active {
                width: 6px;
                height: 6px;
            }
        }
        .dot-wrapper {
            display: table-cell;
            width: 6px;
            height: 6px;
            vertical-align: middle;
            &.active .dot {
                width: 6px;
                height: 6px;
            }
        }
    }
    .card {
        background: #fff;
        height: calc(~"100% - 65px");
        overflow: auto;
        margin: 0px 11px;
        padding: 0px 13px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        .order-number {
            display: flex;
            padding-top: 16px;
            font-size: 18px;
            font-weight: 500;
            .label {
                white-space: nowrap;
            }
            .content {
                word-break: break-all;
            }
        }
        .bills {
            position: relative;
            padding-top: 20px;
            width: calc(100% + 26px);
            overflow: hidden;
            margin: 0px -13px;
            padding: 20px 13px 0px;
            .bill {
                display: table;
                width: 100%;
                border-bottom: 1px solid rgba(198, 203, 212, .5);
                padding-bottom: 8px;
                margin-bottom: 8px;
                & > div {
                    display: table-cell;
                    vertical-align: middle;
                }
                .fee {
                    font-size: 16px;
                    color: #666;
                    line-height: 1.5em;
                }
                .date {
                    font-size: 14px;
                    color: #999;
                    line-height: 1.5em;
                }
                .amount {
                    font-size: 16px;
                    font-weight: bold;
                    color: #ed5b53;
                    text-align: right;
                    line-height: 1.5em;
                }
                .extra {
                    font-size: 14px;
                    color: #ed5b53;
                    text-align: right;
                    line-height: 1.5em;
                }
            }
            &::before {
                display: inline-block;
                content: " ";
                background: #3f83f0;
                position: absolute;
                left: -8px;
                bottom: 0px;
                width: 16px;
                height: 16px;
                border-radius: 50%;
            }
            &::after {
                display: inline-block;
                content: " ";
                background: #5696fa;
                position: absolute;
                right: -8px;
                bottom: 0px;
                width: 16px;
                height: 16px;
                border-radius: 50%;
            }
        }
        .charge {
            position: relative;
            padding: 20px 0px;
            text-align: center;
            .total {
                font-size: 16px;
                line-height: 1.5em;
                font-weight: bold;
                span {
                    color: #ed5b53;
                }
            }
            .address {
                margin-top: 6px;
                font-size: 16px;
                line-height: 1.5em;
            }
            .customer {
                margin-top: 6px;
                font-size: 16px;
                line-height: 1.5em;
            }
            .qrcode-normal {
                margin-top: 20px;
                text-align: center;
                .qrcode-wrapper {
                    background: #fff;
                    display: inline-block;
                    width: 180px;
                    height: 180px;
                    padding: 10px;
                    border: 7px solid #c6cbd4;
                    border-radius: 5px;
                    img {
                        width: 100%;
                    }
                }
                .message1 {
                    margin-top: 20px;
                    font-size: 16px;
                    color: #999;
                    line-height: 21px;
                }
                .message2 {
                    margin-top: 5px;
                    color: #ed5b53;
                    line-height: 21px;
                    font-size: 14px;
                }
            }
            .qrcode-expired {
                margin-top: 20px;
                img {
                    width: 120px;
                }
                .message1 {
                    margin-top: 10px;
                    font-size: 16px;
                    color: #347aeb;
                }
                .message2 {
                    margin-top: 15px;
                    font-size: 14px;
                    color: #666;
                    text-align: left;
                    line-height: 18px;
                }
            }
            .qrcode-success {
                margin-top: 50px;
                img {
                    width: 120px;
                }
                .message1 {
                    margin-top: 20px;
                    font-size: 16px;
                    color: #347aeb;
                }
            }
            .dial {
                background: #347aeb;
                margin-top: 10px;
                border-radius: 2px;
                a {
                    line-height: 35px;
                    font-size: 16px;
                    color: #fff;
                    font-weight: bold;
                    letter-spacing: 1px;
                }
                img {
                    width: 16px;
                    margin-right: 10px;
                    display: inline-block;
                    margin-top: -4px;
                }
            }
        }
    }
    .foot-note {
        background: #347aeb;
        position: relative;
        height: 65px;
        padding: 12px;
        .quickly-open {
            display: flex;
            align-items: center;
            .logo img {
                width: 40px;
                height: 40px;
            }
            .message {
                flex: 1;
                color: #fff;
                font-size: 14px;
                line-height: 17px;
                padding: 0px 10px;
            }
            .btn {
                background: #fff;
                display: inline-block;
                width: 90px;
                color: #347aeb;
                font-weight: bold;
                font-size: 14px;
                line-height: 30px;
                text-align: center;
                border-radius: 5px;
            }
        }
        &::before {
            display: inline-block;
            content: " ";
            border: 8px solid transparent;
            position: absolute;
            top: -8px;
            left: 3px;
            height: 1px;
            width: 1px;
            border-left-color: #2863c3;
            transform: rotate(45deg);
        }
        &::after {
            display: inline-block;
            content: " ";
            border: 8px solid transparent;
            position: absolute;
            top: -8px;
            right: 3px;
            height: 1px;
            width: 1px;
            border-right-color: #2863c3;
            transform: rotate(-45deg);
        }
    }
}
</style>


