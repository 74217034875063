<template>
  <keep-alive>
    <div style="height:100%">

      <van-pull-refresh
        v-show="list.length!==0"
        class="reportList"
        v-model="isLoading"
        @refresh="onRefresh"
        style="overflow:auto"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          :offset="300"
          :immediate-check="false"
          style="margin-top:10px"
        >
          <van-cell
            v-for="(item) in list"
            :key="item.businessOrderId ? item.businessOrderId : item"
            @click="handleRouter(item.businessOrderId)"
          >
            <div class="reportTitle">{{item.notes}}</div>
            <div
              class="adrress"
              style="
  margin-bottom: 10px;"
            >{{item.communityName + item.address}}</div>
            <div class="reportInfo">
              <div class="reportLeft">
                <div :class="item.status"></div>
                <div class="reportType">{{item.topTypeName}}</div>
              </div>
              <div class="reportRight">{{item.createTime | formatDate}}</div>
            </div>
          </van-cell>
        </van-list>

      </van-pull-refresh>
      <div
        class="empty"
        v-if="isNoLength"
      ></div>
    </div>
  </keep-alive>

</template>

<script>

import wx from "weixin-js-sdk";
window.onunload = function () {
  wx.miniProgram.navigateBack({});
};

import * as $config from "./config/api";
import * as $api from "./http/api";
import { formatDate } from "./utils/index";

import storage from "../../commons/storage";
import { Toast } from "vant";
export default {
  name: "myReport",
  data() {
    return {
      list: [],
      loading: false,
      isLoading: false,
      finished: false,
      ACCESS_TOKEN: "",
      isNoLength: false,
      query: {
        pageIndex: 0,
        pageSize: 10,
        communityExtId: "",
      },
    };
  },
  watch: {
    $route: () => {
      console.log("---------------");
    },
  },
  filters: {
    formatDate: (v) => {
      return formatDate(new Date(v), "yyyy-MM-dd h:mm");
    },
  },
  created() {

    //   var ua = navigator.userAgent.toLowerCase();
    // if(ua.match(/MicroMessenger/i)=="micromessenger") {
      
    //   console.log(ua,"微信")
    //     //ios的ua中无miniProgram，但都有MicroMessenger（表示是微信浏览器）
    //     wx.miniProgram.getEnv((res)=>{
    //        if (res.miniprogram) {
    //            alert("在小程序里");
    //        } else {
    //            alert("不在小程序里");
    //        }
    //     })
    // }else{
    //     alert('不在微信里');
    // }
    // console.log(this.$route.fullPath.split("=")[1].split("&&")[0]);
    storage.set(
      storage.keys.ACCESS_TOKEN,
      this.$route.fullPath.split("=")[1].split("&&")[0]
    );
    this.ACCESS_TOKEN = this.$route.fullPath.split("=")[1].split("&&")[0];
    this.query.communityExtId = this.$route.fullPath
      .split("=")[2]
      .split("&&")[0];
    console.log(this.$route);
  },
  mounted() {
    this.$nextTick(() => {
      this.getList();
    });
    // for (let i = 0; i < 50; i++) {
    //   this.list.push({
    //     id: i + 1,
    //     title: i / 2 ? "水龙头坏了" : "管家联系不上,请帮忙维修！",
    //     status: this.filterStatus(parseInt(Math.random() * 10)),
    //     type: i / 2 ? 0 : 1,
    //     sj: "2021.02.26 13:36",
    //   });
    // }
  },
  beforeDestroy() {
    // localStorage.removeItem(storage.keys.ACCESS_TOKEN);
  },
  methods: {
    getList() {
      Toast.loading({duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      $api
        .customList(this.query)
        .then((res) => {
          Toast.clear();
          if (res.listData.length === 0) {
            this.finished = true;

            this.loading = false;
          } else {
            this.list = this.list.concat(res.listData);

            this.loading = false;
            console.log(this.loading);
          }

          if (this.list.length === 0) {
            this.isNoLength = true;
          } else {
            this.isNoLength = false;
          }
        })
        .catch(() => {
          Toast.clear();
          this.isNoLength = true;
        });
    },
    onLoad() {
      console.log("加载");
      this.loading = true;
      this.query.pageIndex = this.query.pageIndex + 1;
      this.getList();
    },
    onRefresh() {
      this.query.pageIndex = 0;
      $api.customList(this.query).then((res) => {
        this.list = res.listData;
        this.loading = false;

        Toast("刷新成功");
        this.isLoading = false;
      });
    },
    filterStatus(status) {
      if (status === 0) {
        return "Closed";
      } else if (status === 1) {
        return "Sent";
      } else if (status === 2) {
        return "ReturnVisited";
      } else if (status === 3) {
        return "Accetting";
      } else if (status > 4 && status < 7) {
        return "Created";
      } else {
        return "Finished";
      }
    },
    // 跳转详情钩子
    handleRouter(id) {
      // wx.miniProgram.navigateTo({url: '../webview/webview?weburl=https://www.hxkj.vip/#/' + modelName});
      this.$router.push(
        `/wx/warn-status/#/access_token=${this.ACCESS_TOKEN}&&id=${id}`
      );
    },
  },
};
</script>

<style lang="less" scoped>
html body /deep/ .van-pull-refresh {
  overflow: auto !important;
}
.reportList {
  width: 100%;
  height: 100%;
  padding: 0px 20.5px 0 21px;
}
.reportTitle {
  width: 100%;
  min-height: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  font-size: 16px;
  margin-bottom: 10px;
}
.adrress {
  color: #969696;
  font-size: 12px;
  min-height: 20px;
  // line-height: 20px;
  position: relative;
  padding-left: 24px;
  &::before {
    content: "";
    position: absolute;
    background: url("./image/gps_seletct.png") no-repeat center center;
    background-size: contain;
    width: 14px;
    height: 14px;
    top: 50%;
    transform: translateY(-50%);
    left: 0px;
  }
}
.reportInfo {
  width: 100%;
  height: 18px;
  line-height: 18px;
  .reportLeft {
    float: left;
    & > div:nth-child(1) {
      float: left;
    }
  }
  .reportRight {
    float: right;
    height: 14px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #969696;
    line-height: 14px;
    padding: 2px 4px;
  }
}
.reportType {
  float: left;
  margin-left: 10px;
  height: 14px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #969696;
  line-height: 14px;
  padding: 2px 4px;
}
// 待派单
.Created {
  position: relative;
  &::after {
    content: "待派单";
    line-height: 14px;
    text-align: center;
    border: 0.5px solid #347aeb;
    color: #347aeb;
    font-size: 12px;
    padding: 2px 4px;
    border-radius: 4px;
  }
}

// 已派单
.Sent {
  position: relative;
  &::after {
    content: "已派单";
    line-height: 14px;
    text-align: center;
    border: 0.5px solid #29c985;
    color: #29c985;
    font-size: 12px;
    padding: 2px 4px;
    border-radius: 4px;
  }
}
// 处理中
.Accepting {
  position: relative;
  &::after {
    content: "处理中";
    line-height: 14px;
    text-align: center;
    border: 0.5px solid #29c985;
    color: #29c985;
    font-size: 12px;
    border-radius: 4px;
    padding: 2px 4px;
  }
}
// 已结单
.Accepted {
  position: relative;
  &::after {
    content: "已结单";
    line-height: 14px;
    text-align: center;
    border-radius: 4px;
    border: 0.5px solid #29c985;
    color: #29c985;
    font-size: 12px;
    padding: 2px 4px;
  }
}
.Finished {
  position: relative;
  &::after {
    content: "已完成";
    line-height: 14px;
    text-align: center;
    border-radius: 4px;
    border: 0.5px solid #29c985;
    color: #29c985;
    font-size: 12px;
    padding: 2px 4px;
  }
}
// 已回访
.ReturnVisited {
  position: relative;
  &::after {
    content: "已回访";
    line-height: 14px;
    border-radius: 4px;
    text-align: center;
    border: 0.5px solid #ffbb06;
    color: #ffbb06;
    font-size: 12px;
    padding: 2px 4px;
  }
}
// 已取消
.Closed {
  position: relative;
  &::after {
    content: "已取消";
    line-height: 14px;
    text-align: center;
    border-radius: 4px;
    border: 0.5px solid #666666;
    color: #666666;
    font-size: 12px;
    padding: 2px 4px;
  }
}
/deep/ .van-cell {
  overflow: initial;
  margin-top: 10px;
}
/deep/ .van-cell__value--alone {
  margin-bottom: 5px;
}
/deep/ .van-cell__value {
  overflow: initial;
}
.empty {
  height: 100%;
  background: url(https://public.img.oss.shequbanjing.com/property/application/61d9a467-51f9-4703-96bb-6d5806695a82.png)
    no-repeat center center;
  background-color: #fff;
  background-size: 151px 143px;

  &::after {
    content: "";
    position: absolute;
    display: block;
    top: 60%;
    left: 0;
    right: 0;
    padding-left: 18px;
    text-align: center;
    color: #999;
    margin: auto;
  }
}
</style>