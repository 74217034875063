<template>
    <div class="sqbj-amap-project">
        <el-amap ref="map" viewMode="3D" :zoom="17" :mapStyle="mapStyle" :expandZoomRange="true" :events="mapEvents">
            <el-amap-marker v-bind:key="`${m.id}-${i}`" v-for="(m, i) in _markers" 
                :position="m.center"
                :icon="m.icon"
                :offset="[-12.5, -12.5]">
            </el-amap-marker>
            <el-amap-circle v-bind:key="`${c.id}-${j}`" v-for="(c, j) in _circles"
                :fillOpacity="0.3" 
                :strokeOpacity="0.5" 
                :strokeWeight="1"
                :center="c.center" 
                :radius="c.radius" 
                :fillColor="c.color" 
                :strokeColor="c.color">
            </el-amap-circle>
            <el-amap-info-window v-if="!!currentEvent" :bubble="true" :position="currentEvent.center" :offset="[0,-12.5]">
                <div class="sqbj-info-tips">
                    <p>{{currentEvent.content}}</p>
                    <p>
                        <span :style="{color: colorMap[currentEvent.type]}">{{typeMap[currentEvent.type]}}</span>
                        {{formatTime(currentEvent.eventTime)}}&emsp;
                    </p>
                </div>
            </el-amap-info-window>
        </el-amap>
        <div class="sqbj-amap-window">
            <a-tag 
                v-bind:key="k" 
                v-for="k in layerTypes"  
                :color="checkedLayers.includes(k) ? '#4B7AFB': ''"
                @click="onLayer(k)">
                {{layerMap[k]}}
            </a-tag>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import * as moment from "moment";
import * as $antd from "ant-design-vue";
import * as $data from "./index";
import storage from "../../commons/storage";
import {setaccessToken}from "../../commons/agent"

import * as $commons from "../../commons/index";
import "./style.less";

Vue.use($antd.Tag);

let map = null; 
let areaId = 0;
let windowInterval = null;
let eventInterval = null;
let tenantId = "";
let secretKey = "";

export default {
    data() {
        // areaId = this.$route.query.areaId;
        // tenantId = this.$route.query.tenantId;
        // secretKey = this.$route.query.secretKey;
        // secretKey = encodeURIComponent(secretKey);
        return {
            mapStyle: $data.MAP_STYLE,
            layerTypes: [
                $data.LAYER_TYPE_BUILDING,
                $data.LAYER_TYPE_UNIT,
                $data.LAYER_TYPE_EQUIPMENT_ROOM,
                $data.LAYER_TYPE_PUBLIC_AREA,
                $data.LAYER_TYPE_DEVICE_POSITION,
            ],
            checkedLayers: [
                $data.LAYER_TYPE_BUILDING,
                $data.LAYER_TYPE_UNIT,
                $data.LAYER_TYPE_EQUIPMENT_ROOM,
                $data.LAYER_TYPE_PUBLIC_AREA,
                $data.LAYER_TYPE_DEVICE_POSITION,
            ],
            layerMap: $data.layerTextMap,
            typeMap: $data.eventTextMap,
            colorMap: $data.eventColorMap,
            points: [],
            events: [],
            currentEvent: null,
            mapEvents: {
                
                init: async () => {
                    window.addEventListener("message", (event)=>{
                    if(event && event.data.authorization){
                        storage.set(storage.keys.ACCESS_TOKEN, event.data.authorization)
                        areaId =  event.data.areaId
                        setaccessToken(event.data.authorization)
                        
                        clearInterval(eventInterval);
                        var that = this;
                        const fn = async () => {
                            const info = await get_areainfo()
                            map = this.$refs.map.$$getInstance();
                            map.plugin(["AMap.ControlBar"], function() {
                                var controlBar = new AMap.ControlBar();
                                map.addControl(controlBar);
                            });
                    if(info) {
                        map.setZooms(info.zooms);
                        map.setPitch(info.pitch || 25);
                        map.setRotation(info.rotation);
                        map.setZoomAndCenter(info.zooms[0] || 17, info.center);
                    }
                            that.points = await get_point_data();
                            that.events = await get_event_data();
                            that.startWindowLoop();
                        }
                        fn();
                        this.startWindowLoop();
                        eventInterval = setInterval(fn, 5 * 60 * 1000);
                    }else{
                        return false
                    }
        }, false);
                    
                }
            }
        }
    },
//  mounted() {
        
//     },
    methods: {
        startWindowLoop() {
            this.currentEvent = null;
            clearInterval(windowInterval);
            let index = 0;
            windowInterval = setInterval(() => {
                 if(index > this.events.length - 1) {
                    index = 0;
                }
                this.currentEvent = this.events[index];
                index++ ;
            }, 3000);
        },
        onLayer(layer) {
            const checked = this.checkedLayers.includes(layer);
            let layers = [...this.checkedLayers];
            if(checked) { // 取消
                layers = layers.filter(t => t !== layer);
            } else { // 勾选
                layers.push(layer);
            }
            this.checkedLayers = layers;
        },
        formatTime(t) {
            return !t ? '': moment(t).format("MM-DD HH:mm");
        },
    },
    computed: {
        _markers: function() {
            return this.points.filter(p => this.checkedLayers.indexOf(p.type) > -1).filter(x => x.type !== "PUBLIC_AREA");
        },
        _circles: function() {
            return this.points.filter(p => this.checkedLayers.indexOf(p.type) > -1).filter(x => x.type === "PUBLIC_AREA");
        }
    }
}

/**
 * 获取小区地图设置
 */
async function get_areainfo() {
    let result = null;
    let response = null;
    try {
        response = await $commons.agent({
            method: "GET",
            baseURL: $commons.SERVICE_BIGDATA_API,
            url: `/area-map/detail?areaId=${areaId}`
        });
    } catch (e) {
         $antd.message.error(e.message || "获取数据失败");
    }
    if(response && response.data) {
        const x = response.data;
        const centers = (x.central || "").split(";");
        result = {
            areaId: x.areaId,
            center: centers,
            zooms: [x.minZoom, x.maxZoom],
            pitch: x.pitch || 25,
            rotation: x.rotation || 0,
        }
    }
    return result;
}
/**
 * 获取所有图层信息
 */
async function get_point_data() {
    let result = [];
    let response = null;
    try {
        response = await $commons.agent({
            method: "GET",
            baseURL: $commons.SERVICE_BIGDATA_API,
            url: `/area-map/layers?areaId=${areaId}`
        });
    } catch (e) {
        $antd.message.error(e.message || "获取数据失败");
    }
    if(response && response.data && response.data.length) {
        response.data.forEach(p => {
            const points = p.positionList || [];
            points.forEach(x => {
                const centers = x.location.split(";");
                result.push({
                    type: x.extendType,
                    id: x.extentId,
                    name: x.name,
                    address: x.parentAddressName,
                    center: centers,
                    radius: x.radius,
                    color: x.colorValue,
                    icon: x.extendType === $data.LAYER_TYPE_PUBLIC_AREA ? null : new AMap.Icon({
                        size: new AMap.Size(25, 25),
                        image: require(`./imgs/layer-${x.extendType.toLowerCase().replace("_", "-")}.png`),
                        imageSize: new AMap.Size(25, 25),
                    }),
                })
            });
        });
    }
    return result;
}

/**
 * 获取工单事件列表
 */
async function get_event_data() {
    let result = [];
    let response = null;
    try {
        response = await $commons.agent({
            method: "GET",
            baseURL: $commons.SERVICE_BIGDATA_API,
            url: `area-map/events?areaId=${areaId}&pageSize=10&interval=86400`
        });
    } catch (e) {
        $antd.message.error(e.message || "获取数据失败");
    }
    if(response && response.data && response.data.length) {
        response.data.forEach(x => {
            const centers = x.positionLocation.split(";");
            result.push({
                id: x.id,
                name: x.areaName,
                center: centers,
                content: x.content,
                type: x.type,
                finished: x.isDeal === "YES", // 已处理
                eventTime: x.eventTime, // 事件事件
                dealTime: x.dealTime, // 处理时间
            })
        })
    }
    return result;
}
</script>
<style lang="less">
.sqbj-amap-project .sqbj-amap-window {
    position: absolute;
    z-index: 1000;
    top: 0px;
    left: 0px;
    padding: 6px 10px;
    color: #C3E5F9;
}
</style>
