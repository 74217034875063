<template>
    <div class="sqbj-home">
        <div>社区半径-Home</div>
        <img src="../assets/logo.png">
    </div>
</template>
<script>
    export default {
        data() {
            return { };
        },
    };
</script>
<style lang="less">
.sqbj-home {
    padding: 20px;
    text-align: center;
    > div {
        font-size: 20px;
        padding: 20px;
    }
}
</style>
