<template>
  <div class="pay_wrapper" style="min-height:100%">
    <div class="tips_wrapper">
      <div class="border_wrapper">

        <div class="little_gift"></div>
        <div class="content_wrapper">
          <div class="tips_title">尊敬的业主：</div>

          <div class="tips_content">
            您好！感谢您长期以来对物业工作的理解与支持！在此我们向您衷心地表示感谢！为保证我们能更好地为您提供优质、高效的服务，保障您的物业升值与保值，请您在百忙中缴纳如下费用，十分感谢！
          </div>
        </div>
      </div>
      <div class="little_bottom"></div>
    </div>

    <div class="payInfo_wrapper">
      <div class="payInfo_title">
        <div class="title_item">
          <div class="title_label">业主信息：</div>
          <div class="title_value">{{ payInfos.showAddress }}&nbsp; &nbsp; {{ payInfos.householdsName }}
          </div>
        </div>
        <div class="title_item">
          <div class="title_label">订单号：</div>
          <div class="title_value">{{ payInfos.orderNo || payInfos.prepaidOrderNo }}</div>
        </div>
      </div>
      <div class="driver"></div>

      <div class="payInfo_list">

        <div :key="index" v-for="(value, key, index)  in payInfos.feeList" class="pay_items">
          <div class="pay_items_left">
            <div class="pay_items_title">{{ value.groupFeeItemName }}</div>
            <div class="pay_items_times">{{ getTime(value.list, "fee") }}</div>
            <div class="pay_items_extra">{{ getDiff(value.list) }}</div>
          </div>
          <div class="pay_items_right">
            <div class="pay_items_amount">¥ {{ value.feeTotal }}</div>
            <div class="pay_items_subAmount" v-show="value.penaltyTotal > 0">违约金：¥ {{ value.penaltyTotal }}
            </div>
          </div>
          <!-- <div class="pay_items_content">
            <div class="pay_items_title">{{ value.groupFeeItemName }}</div>
            <div class="pay_items_amount">¥ {{ value.feeTotal }}</div>
          </div>
          <div class="pay_items_times">{{ getTime(value.list, "fee") }}</div> -->
        </div>

      </div>

      <div class="driver"></div>

      <div class="pay_total">
        <div class="label">合计：</div>
        <div class="value">¥ {{ total }}</div>
      </div>
    </div>

    <div class="payMode_wrapper" v-if="(!payInfos.isPay && payInfos.isValid)">
      <div class="payMode_title">请选择支付方式</div>
      <div class="payMode_content">

        <cube-radio-group>
          <cube-radio v-for="(option, index) in options4" :key="index" :option="option" position="right"
            :hollow-style="true" v-model="select">

            <img class="imgs" :src="option.src" />
            <span>{{ option.label }}</span>
          </cube-radio>
        </cube-radio-group>
      </div>
    </div>

    <div v-if="(payInfos.isPay || !payInfos.isValid)" class="payMode_wrapper pay_tips">
      <img src="https://public.img.oss.shequbanjing.com/property/application/a9ed02ad-e28c-49a4-8be6-684eeac28d15.png"
        alt="">
      <p v-show="payInfos.isPay">该账单已支付，无需重复支付</p>
      <p v-show="(!payInfos.isPay && !payInfos.isValid)">账单已失效，无法支付，请联系物业客服中心</p>
    </div>

    <div>
      <a v-if="manager.id" :href="'tel:' + manager.phone" class="tips_little">
        <div class="title">如有疑问，可联系您的专属管家-{{ manager.name }}</div>
        <img src="https://public.img.oss.shequbanjing.com/property/application/a874e28e-fe08-4ef1-8c75-a24727087c2a.png"
          alt="" @click="tellCall(manager.phone)">
      </a>
    </div>
    <div v-if="!payInfos.isPay && payInfos.isValid" :class="'opatype' + (disabled ? ' disabled' : '')"
      @click="handlePay">去支付</div>
    <div class="bg_color
    "></div>
  </div>
</template>

<script>
import { getWxLink, getInfo, getManager, checkBillList } from "./api";
import { getUrlParam, _wxAuthorize, _wxPay, add, sub, _callWxPay, _h5Pay } from "../../commons/utils";
import wx from "weixin-js-sdk";
import * as vant from "vant";
import $moment from "moment";
import { _getWxOpenid } from "../../commons/utils";
import { wxAppId, aliAppId, authorizeUrl } from "../../commons/index";

export default {
  name: "SqbjH5IndexPay",

  data() {
    return {
      client: null, // 客户端 vx:微信 alipay:支付宝
      select: "vx",
      options4: [
        {
          label: "微信支付",
          value: "vx",
          src: "https://public.img.oss.shequbanjing.com/property/application/9a64809c-2cb8-4c36-ab16-3f8446d3cefd.png",
        },
        {
          label: "支付宝",
          value: "alipay",
          src: "https://public.img.oss.shequbanjing.com/property/application/065f84c2-c59d-4d26-ac32-aaacf8e66b64.png",
        },
      ],
      wxLink: "",
      weixinConfig: {
        debug: true, // 调试时可以打开，能看到每一步的返回结果
        appId: "wx2354c2483a700f41", // 后台会返回
        timestamp: 1616215385, // 后台会返回
        nonceStr: "7957ac72f0519e3fb08a8520a62e2439", // 后台会返回
        signature: "e85da653337632dffb26272f093bba903a3873f6", // 后台会返回
        jsApiList: ["wx-open-launch-weapp"], // 必填，需要使用的JS接口列表
        openTagList: ["wx-open-launch-weapp"],
      },
      payInfos: {},
      total: 0,
      env: false,
      manager: {},
      disabled: false,
    };
  },

  mounted() {
    if (process.env.NODE_ENV === 'production' && window.location.origin.indexOf("shequbanjing") > -1) {
      return window.location.href = window.location.href.replace("shequbanjing", "sqbj");
    }
    console.log(process.env)
    wx.error(function (res) {
      // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      console.log(res, "errorConfig");
    });
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("micromessenger") > -1) { // 微信内
      this.client = "vx";
      this.select = "vx";
      this.options4 = [].concat(this.options4[0]);
      const code = getUrlParam("code");
      if (!code) {
        window.localStorage.removeItem("openid");
        return window.location.href = `${authorizeUrl}/pay/authorize?appId=${wxAppId}&sourceUrl=${encodeURIComponent(location.href)}`;
      }
    } else if (ua.indexOf("alipayclient") > -1) { // 支付宝内
      wx.config(this.weixinConfig);
      this.client = "alipay";
      this.select = "alipay";
      this.options4 = [].concat(this.options4[1]);
    } else { // 浏览器内
      this.select = "alipay";
      this.options4 = [].concat(this.options4[1]);
    }

    this.getInfo(this.$route.query.uuid);

    document.addEventListener("visibilitychange", () => {
      if (document.hidden) {
        console.log("页面被挂起");
      } else {
        this.getInfo(this.$route.query.uuid);
      }
    });
  },

  methods: {
    tellCall() { },
    handlePay() {
      if (this.disabled) {
        return;
      }
      this.disabled = true;
      if (!this.checkBill()) {
        this.disabled = false;
        return;
      }
      if (this.select === "vx") {
        this.wxPay();
      } else {
        this.aliPay();
      }
    },
    async getInfo() {
      let result = await getInfo(this.$route.query.uuid);
      this.payInfos = result.data;
      this.total = result.data.feeList.reduce((a, b) => add(a, add(b.feeTotal, b.penaltyTotal)), 0);
      this.getManagerInfo();
    },
    async getManagerInfo() {
      let result = await getManager(this.payInfos.householdsId);
      this.manager = result.data;
    },
    async checkBill() {
      let list = [];
      this.payInfos.feeList.forEach((c) => {
        c.list.map((b) => {
          list.push(b);
        });
      });
      let result = await checkBillList({
        areaId: this.payInfos.areaId,

        houseId: this.payInfos.houseId,
        list,
      });
      if (result.code === "000000") {
        return true;
      }
    },
    getTime(list, type) {
      let str = "";
      let minStart = list[0].feeStartDate;
      let maxEnd = list[0].feeDeadLineDate;
      let total = 0;
      //   console.log(list[0],minStart,maxEnd)
      if (type === "fee") {
        list.forEach((item) => {
          if (item.feeStartDate < minStart) {
            minStart = item.feeStartDate;
          }
          if (item.feeDeadLineDate > maxEnd) {
            maxEnd = item.feeDeadLineDate;
          }
        });
      }

      str =
        $moment(minStart).format("YYYY/MM/DD") +
        "-" +
        $moment(maxEnd).format("YYYY/MM/DD");

      return str;
    },
    getDiff(list) {
      if (list && list.length > 0) {
        // const lastReadingList = list.map(o => o.lastReading || 0).sort();
        // const currentReadingList = list.map(o => o.currentReading || 0).sort();
        // const start = lastReadingList[0] || 0;
        // const end = currentReadingList[currentReadingList.length - 1] || 0;
        // const count = Math.abs(end - start);
        const start = list[0].lastReading || 0;
        const end = list[list.length - 1].currentReading || 0;
        const count = Math.abs(sub(end, start));
        if (count) {
          return `表底：${start.toFixed(2)}/${end.toFixed(2)} 用量：${count.toFixed(2)}`;
        }
      }
      return null;
    },
    async wxPay() {
      if (this.client === "vx") {
        const code = getUrlParam("code");
        if (code) {
          let openid = window.localStorage.getItem("openid");
          try {
            if (!openid) {
              openid = await _getWxOpenid({ appid: wxAppId, code });
              if (openid) {
                window.localStorage.setItem("openid", openid);
              }
            }
            if (openid) {
              _h5Pay({ uuid: this.$route.query.uuid, openid }).then(payInfo => {
                _callWxPay(payInfo).then(res => {
                  this.disabled = false;
                  // 支付失败或取消支付会进入这里，因未开通点金计划支付成功后会直接关闭页面
                  // if (res) {
                  //   window.location.href = `${location.origin}/pay/success`;
                  // }
                }).catch(e => {
                  this.disabled = false;
                  vant.Dialog.alert({ message: "3：" + e.message, confirmButtonText: "我知道了" });
                });
              }).catch(e => {
                this.disabled = false;
                vant.Dialog.alert({ message: "2：" + e.message, confirmButtonText: "我知道了" });
              });
            } else {
              this.disabled = false;
              vant.Dialog.alert({ message: "openid不能为空", confirmButtonText: "我知道了" });
            }
          } catch (e) {
            this.disabled = false;
            vant.Dialog.alert({ message: "1：" + e.message, confirmButtonText: "我知道了" });
          }
        } else {
          this.disabled = false;
          vant.Dialog.alert({ message: "code不能为空", confirmButtonText: "我知道了" });
        }
      } else {
        this.disabled = false;
        getWxLink(`uuid=${this.$route.query.uuid}`).then(res => window.open(res.data, "_self"));
      }
    },
    aliPay() {
      this.disabled = false;
      // 支付宝APPID
      const uuid = this.$route.query.uuid;
      const redirectUrl = encodeURIComponent(`${location.origin}${location.pathname.replace("payWay", "aliPay")}?uuid=${uuid}`);
      const url = encodeURIComponent(`https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=${aliAppId}&scope=auth_user&redirect_uri=${redirectUrl}`);

      window.open(`alipays://platformapi/startapp?appId=20000067&url=${url}`);
    }
  },
};
</script>

<style lang="less" scoped>
.pay_wrapper {
  position: relative;
  background: #f6f6f6;
  padding: 6px 10px 10px 6px;
  box-sizing: border-box;
  min-height: 100%;

  .tips_wrapper,
  .payInfo_wrapper,
  .payMode_wrapper {
    padding: 15px 8px 0 20px;
    width: 350;
    box-sizing: border-box;
    min-height: 183px;
    margin-bottom: 15px;
    margin: auto;
    background: #f6efe6;
    border-radius: 6px 0px 0px 6px 6px 6px 0px;
    // box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.19);

    .tips_title {
      width: 113px;
      height: 26px;
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      color: #55320f;
      line-height: 22px;
      letter-spacing: 0.45px;
    }

    .tips_content {
      margin-top: 4px;
      height: 120px;
      font-size: 15px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #55320f;
      line-height: 23px;
      letter-spacing: 0.5px;
    }
  }

  .tips_wrapper {
    width: 363px;

    min-height: 240px;
    padding-left: 6px;
    padding-right: 6px;
    box-sizing: border-box;
    position: relative;
    top: 0;
    // z-index: 10;
    overflow: hidden;
    background: transparent;
    margin-bottom: 15px;

    .border_wrapper {
      width: 350px;
      position: absolute;
      padding: 8px 6px 0 8px;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 12;
      box-sizing: border-box;
      height: 100%;
      background: #f6efe6;

      .content_wrapper {
        padding: 11px 8px 0 12px;

        border: 1px solid #cebea9;
        background: #f6efe6;
        border-bottom: 0;
      }
    }
  }

  .pay_tips {
    display: flex;
    align-items: center;
    padding: 0;
    flex-direction: column;
    min-height: 135px !important;

    img {
      margin-top: 45px;
      width: 30px;
      height: 26px;
      margin-bottom: 12px;
    }

    p {
      font-size: 14px;
      color: #666;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #666666;
      line-height: 22px;
      letter-spacing: 0.21px;
    }
  }

  .payInfo_wrapper,
  .payMode_wrapper {
    min-height: 120px;
    background: #ffffff;
    border-radius: 3px;
    overflow: hidden;
    margin-bottom: 15px;
    box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.19);

    .payInfo_title {
      min-height: 71px;
      padding-right: 4px;
      box-sizing: border-box;
      font-size: 15px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #333333;
      line-height: 25px;
      letter-spacing: 1px;
      margin-bottom: 15px;

      .title_item {
        display: flex;
        min-height: 25px;

        .title_label {
          color: #666;
          width: 86px;
        }

        .title_value {
          flex: 1;
        }
      }
    }

    .payInfo_list {
      padding-top: 18px;
      box-sizing: border-box;

      .pay_items {
        margin-bottom: 20px;
        display: flex;
        align-items: center;

        .pay_items_left {
          flex: 1;
        }

        .pay_items_right {
          padding-right: 4px;
          text-align: right;
        }

        .pay_items_title {
          font-size: 15px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          color: #333333;
          line-height: 18px;
          letter-spacing: 0.42px;
        }

        .pay_items_amount {
          font-size: 15px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          color: #333333;
          line-height: 18px;
          letter-spacing: 0.42px;
        }

        .pay_items_subAmount {
          font-size: 13px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: normal;
          color: #ED5B53;
          line-height: 18px;
          letter-spacing: 0.42px;
        }

        .pay_items_content {
          display: flex;
          justify-content: space-between;
          height: 18px;
          font-size: 15px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #333333;
          line-height: 18px;
          letter-spacing: 0.42px;

          .pay_items_amount {
            margin-right: 4px;
          }
        }

        .pay_items_times {
          margin-top: 6px;
          height: 15px;
          font-size: 14px;
          font-family: CenturyGothic, CenturyGothic-Regular;
          font-weight: 400;
          text-align: left;
          color: #999999;
          line-height: 15px;
          letter-spacing: 0.39px;
        }

        .pay_items_extra {
          font-size: 13px;
          font-family: CenturyGothic, CenturyGothic-Regular;
          font-weight: 400;
          text-align: left;
          color: #aaa;
          line-height: 21px;
          letter-spacing: 0.39px;
        }
      }
    }

    .pay_total {
      margin-top: 18px;
      float: right;
      display: flex;
      height: 18px;
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      color: #333333;
      line-height: 18px;
      letter-spacing: 0.45px;
      align-self: flex-end;
      margin-bottom: 27px;
      margin-right: 4px;

      .label {
        font-size: 14px;
      }

      .value {
        color: #ed5b53;
      }
    }
  }

  .payMode_wrapper {
    .payMode_title {
      height: 26px;
      font-size: 15px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      color: #333333;
      line-height: 26px;
      letter-spacing: 0.42px;
      margin-bottom: 16px;
    }

    .payMode_content {
      padding: 0 0 25px 0;
      box-sizing: border-box;

      /deep/ .cube-radio {
        padding: 0;
        height: 42px;
        font-size: 15px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #333333;
        line-height: 42px;
        letter-spacing: 0.42px;
      }

      /deep/ .cube-radio-input {
        padding-left: 25px;
        box-sizing: border-box;
      }

      .imgs {
        width: 16px;
        height: 16px;
        vertical-align: middle;
        margin-right: 13px;
      }

      .payMode_items {
        display: flex;
      }
    }
  }

  .vx {
    background: url("https://public.img.oss.shequbanjing.com/property/application/9a64809c-2cb8-4c36-ab16-3f8446d3cefd.png") no-repeat center;
  }

  .alipay {
    background: url("https://public.img.oss.shequbanjing.com/property/application/065f84c2-c59d-4d26-ac32-aaacf8e66b64.png") no-repeat center;
  }

  .phone {
    background: url("https://public.img.oss.shequbanjing.com/property/application/a874e28e-fe08-4ef1-8c75-a24727087c2a.png") no-repeat center;
  }

  .driver {
    width: 100%;
    height: 1px;
    background: #c6cbd4;
    transform: scaleY(0.2);
  }

  /deep/ .border-right-1px:after,
  .border-top-1px:before {
    transform: scale(0);
  }

  /deep/.border-bottom-1px:after {
    transform: scale(0);
  }

  .bg_color {
    position: absolute;
    top: 0;
    width: 100%;
    height: 158px;
    left: 0;
    background: #eab287;
    z-index: 1;
    border-radius: 0 0 28px 28px;
  }

  .tips_little {
    margin-bottom: 21px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #333333;
    line-height: 20px;
    letter-spacing: 0.39px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 14px;
      margin-left: 7px;
      height: 14px;
    }
  }

  .opatype {
    width: 100%;
    // display: block;
    height: 45px;
    background: #347aeb;
    border-radius: 4px;
    box-shadow: 0px 2px 8px 3px rgba(0, 0, 0, 0.04);
    text-align: center;
    line-height: 45px;
    color: #fff;
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;

    &.disabled {
      background: #c6cbd4;
      color: #aaa;
    }
  }

  /deep/ .cube-radio-hollow.cube-radio_selected .cube-radio-ui:before {
    color: #347aeb;
  }

  /deep/ .cube-radio-hollow.cube-radio_selected .cube-radio-ui i:before {
    background-color: #347aeb;
  }

  .little_bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 111;
    height: 79.2px;
    background: url("https://public.img.oss.shequbanjing.com/property/application/b1751b63-5358-4261-b4c8-7e0d1a744593.png") no-repeat center;
    background-size: cover;
    // transform: scaleX(1.1);
  }

  .little_gift {
    position: absolute;
    right: -3px;
    top: -3px;
    width: 54px;
    height: 52px;
    background: url("https://public.img.oss.shequbanjing.com/property/application/d1d3b7dd-9b05-40a2-a732-14651abbffcf.png") no-repeat center;
    z-index: 1112;
    background-size: cover;
  }
}
</style>