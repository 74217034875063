import Vue from "vue";
import Router from "vue-router";
import Home from "./pages/home";
import Classify from "./pages/business/classify";
import GoodsList from "./pages/business/goods-list";
import Goods from "./pages/business/goods";
import Merchant from "./pages/business/merchant";
import Hehong from "./pages/html/rent-hehong";
import WXLowVersion from './pages/html/wx-low-version';
import Partner from "./pages/html/partner-protocol";
import Alipay from "./pages/authorize/alipay";
import AMapProject from "./pages/amap/project";
import AMapCountryOld from "./pages/amap/country-old";
import AMapCountry from "./pages/amap/country";
import Charge from "./pages/fts/charge";
import WorkOrder from "./pages/workOrder/report-repair";
import Complaint from "./pages/workOrder/complaint";
import MyReport from "./pages/workOrder/myReport";
import WarnStatus from "./pages/workOrder/warnStatus"
import Advice from "./pages/workOrder/advice";
import PayWay from "./pages/pay/index";
import AliPays from "./pages/pay/aliPay";
import HairyCrab from "./pages/HairyCrab";
import CommunityQrcode from "./pages/pay/communityQrcode.vue";
import PayAuthorize from "./pages/pay/authorize.vue";
import PaySuccess from "./pages/pay/success.vue";
Vue.use(Router);

export default new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
        { path: "/", component: Home },
        { path: "/business/classify/:communityId", component: Classify },
        { path: "/business/goods-list/:classifyId/:communityId", component: GoodsList },
        { path: "/business/goods/:id/:communityId", component: Goods },
        { path: "/business/merchant/:id/:communityId", component: Merchant },
        { path: "/rent/hehong", component: Hehong },
        { path: '/wx/low-version', component: WXLowVersion },
        { path: '/protocol/partner', component: Partner },
        { path: '/authorize/alipay', component: Alipay },
        { path: '/amap/project', component: AMapProject },
        { path: '/amap/country', component: AMapCountry },
        { path: '/amap/country-old', component: AMapCountryOld },
        { path: "/fts/charge", component: Charge },
        { path: "/wx/work-order", component: WorkOrder },
        { path: "/wx/complaint", component: Complaint },
        { path: "/wx/my-report", component: MyReport },
        { path: "/wx/warn-status", component: WarnStatus },
        { path: "/wx/advice", component: Advice },
        { path: "/pay/payWay", component: PayWay },
        { path: "/pay/aliPay", component: AliPays },
        { path: "/HairyCrab/index", component: HairyCrab },
        { path: "/pay/communityQrcode", component: CommunityQrcode },
        { path: "/pay/authorize", component: PayAuthorize },
        { path: "/pay/success", component: PaySuccess },
    ]
});